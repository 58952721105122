import { Component, Input, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ApiService } from 'src/services/apiservice.service';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { createResponse } from 'src/models/checklist';
import { DomSanitizer } from '@angular/platform-browser';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'src/environments/environment';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { customizing } from '../../models/customizing';
import { DashboardComponent } from 'src/usermodule/dashboard/dashboard.component';
import { MatOption } from '@angular/material/core';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
@Component({
  selector: 'app-checklist-modal',
  templateUrl: './checklist-modal.component.html',
  styleUrls: ['./checklist-modal.component.scss'],
  providers: [NgbModal]
})
export class ChecklistModalComponent implements OnInit {
  @ViewChild('content') private content: TemplateRef<Object>;
  @ViewChild('instructions') public instructionspopup: TemplateRef<Object>;
  @ViewChild('extrainputmodal') public extrainputpopup: TemplateRef<Object>;
  @Input() checkListFormGroup: any;
  @Input() checkListItems: any;
  //@Input() public goodsReciptFlag: any;
  selectedOption: string;
  instructionsItems: any;
  payload: { processStep: string; orderID: string; userID: string; };
  @Input() orderID: string;
  @Input() attachments: any;
  customizing: any;
  deviceInfo: import('ngx-device-detector').DeviceInfo;
  @Input() public goodsRecipt: any;
  src: any;
  productForm: any;
  currentuser: any;
  modalref: NgbModalRef;
  showChecklist: any;
  stepFlag: string;
  extraInputForm: any;
  showInstructions: boolean;
  isLoading: boolean = true;
  // deviceInfo: import('ngx-device-detector').DeviceInfo;
  isKeyboard: boolean = false;
  allSelected = false;

  currentlang: any;
  errorMsg = false;
  constructor(
    private deviceService: DeviceDetectorService,
    private sanitizer: DomSanitizer,
    private DashboardComponent: DashboardComponent,
    public fb: UntypedFormBuilder,

    private toastrService: ToastrService,
    private apiservice: ApiService,
    private modalService: NgbModal) {
    this.currentuser = localStorage.getItem('current_user');
    (this.currentuser) ? this.currentuser = JSON.parse(this.currentuser) : '';
    this.activities();
    this.productForm = this.fb.group({
      quantity: [1, [Validators.required]],
    });
    this.extraInputForm = this.fb.group({
      quantity: [1, [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    if (this.deviceInfo.deviceType === 'mobile') {
      this.isKeyboard = true
    }
  }

  loadingPDF() {
    this.isLoading = false;
  }

  valueChange(item: any, prop: any) {
    this.checkListFormGroup.patchValue({
      [prop.fieldName]: item
    });
    this.checkListItems.find((element: any) => {
      if (element.fieldName == prop.fieldName) {
        element.value = item;
      }
    });
  }

  resetForm() {
    this.modalService.dismissAll();
    this.checkListFormGroup.reset({});
  }

  addChecklist() {
    var option: any = {};
    let checkingTextfield = false;
    let checkfield;
    for (let i = 0; i < this.checkListItems?.length; i++) {
      if (this.checkListFormGroup.value[this.checkListItems[i]?.fieldName] != undefined) {
        let splittedDate;
        if (this.checkListItems[i].fieldType == 'date') {
          let date = this.checkListFormGroup.value[this.checkListItems[i]?.fieldName];
          let year = new Date(date).getFullYear();
          let month = new Date(date).getMonth() + 1;
          let day = new Date(date).getDate();
          splittedDate = day + '-' + month + '-' + year;
          this.checkListItems[i].value = splittedDate;
        }
        else if (this.checkListItems[i].fieldType == 'checkbox') {
          let temp = (this.checkListFormGroup.value[this.checkListItems[i]?.fieldName] == true || this.checkListFormGroup.value[this.checkListItems[i]?.fieldName] == '1' || this.checkListFormGroup.value[this.checkListItems[i]?.fieldName] == 'true') ? 'true' : 'false';
          this.checkListItems[i].value = temp;
        }
        else if (this.checkListItems[i].fieldType === 'text') {
          checkingTextfield = true
          let temp = this.checkListFormGroup.value[this.checkListItems[i]?.fieldName];
          checkfield = this.checkListItems[i].allowedValues.filter((v: any) => v === temp)
          this.checkListItems[i].value = temp;

        }
        else if (this.checkListItems[i].fieldType === 'select') {

          let temp = this.checkListFormGroup.value[this.checkListItems[i]?.fieldName];
          this.checkListItems[i].value = temp;
          // let tempp=this.checkListItems[i]['multi-select']?temp:[temp]
          //  let kl:any=[]
          // this.checkListItems[i].options.forEach((v:any,l:any)=>{
          //   tempp.forEach((key:any)=>{
          //     if(v.id === key){
          //       kl.push(v)
          //     }
          //     else{
          //     }
          //   })
          //  })
          //  option[i]={
          //   options:kl
          //  }
        }
        else {
          this.checkListItems[i].value = this.checkListFormGroup.value[this.checkListItems[i]?.fieldName]
        }
      }
    }
    if (checkingTextfield) {
      if (checkfield.length > 0) {

        let item = this.checkListFormGroup.value.details;
        let payload = {
          ...item,
          "values": this.checkListItems
        };
        this.checkListFormGroup.reset({});
        this.apiservice.createCheckList(payload).subscribe(
          (res: createResponse) => {
          },
          (error: any) => {
            console.log(error)
          }
        );
        this.DashboardComponent.openCloseInstructionModal('2', '', '');
        setTimeout(() => {
          this.postPrintDetails('1');
        }, 200);
      }
      else {

        let currentlang = JSON.parse(localStorage.getItem('cuurenLanguage') || '{}');
        (Object.keys(currentlang).length) ? this.currentlang = currentlang.name : this.currentlang = 'English'
        let errormsg;
        this.errorMsg = true;
        if (this.currentlang === 'Dutch') {
          errormsg = 'Opgegeven waarde niet toegestaan'
        }
        else if (this.currentlang === 'English') {
          errormsg = 'Value provided is not allowed'
        }
        else {
        }
        this.toastrService.error(errormsg, 'Error!');
      }
    } else {
      this.modalService.dismissAll();
      let item = this.checkListFormGroup.value.details;
      let payload = {
        ...item,
        "values": this.checkListItems
      };
      this.checkListFormGroup.reset({});
      this.apiservice.createCheckList(payload).subscribe(
        (res: createResponse) => {
        },
        (error: any) => {
          console.log(error)
        }
      );
      this.DashboardComponent.openCloseInstructionModal('2', '', '');
      setTimeout(() => {
        this.postPrintDetails('1');
      }, 200);
    }
  }

  setOption(flag: string, data: any) {
    this.selectedOption = flag;
    if (flag === 'instructions') {
      this.apiservice.getInstructions(this.orderID).subscribe(
        (res: any) => {
          if (res?.response == 'success' && res.fields) {

            this.instructionsItems = res?.fields;
          }
        }, (error: any) => {
          console.log(error);
        })
    } else if (flag != 'instructions' && flag != 'checklist') {
      this.deviceInfo = this.deviceService.getDeviceInfo();
      this.isLoading = true;
      if (this.deviceInfo.deviceType != 'mobile') {
        this.src = this.sanitizer.bypassSecurityTrustResourceUrl(
          environment.fileurl +
          data.filename +
          '#toolbar=0&navpanes=0&scrollbar=0'
        );
      } else {
        this.src = this.sanitizer.bypassSecurityTrustResourceUrl(
          'https://docs.google.com/gview?url=' +
          environment.fileurl +
          data.filename +
          '&embedded=true'
        );
      }
    }
  }

  async localHosthandler(item: any) {
    return new Promise((resolve) => {
      setTimeout(() => {
        this.apiservice.localhostPost(item).toPromise();
        resolve('success');
      }, 200);
    });
  }

  async postPrintDetails(flag: string) {
    let askQuantity;
    let processStep;
    let order: any = localStorage.getItem("active_order");
    (order) ? order = JSON.parse(order) : "";
    if (this.customizing?.triggerPrint != null) {
      this.customizing?.triggerPrint?.forEach((element: any) => {
        if (element.processStep == 'afterChecklistComplete') {
          processStep = element.processStep;
          askQuantity = element.askQuantity;
        }
      });
      if (flag == '1' || (flag == '0' && askQuantity != true)) {
        let payload = {
          "orderID": order.orderID,
          "quantity": (!this.modalref) ? null : this.productForm.value?.quantity?.toString(),
          "userID": this.currentuser?.userID,
          "processStep": processStep
        };
        (this.modalref) ? this.modalref.close() : '';
        this.apiservice.addQuantity(payload).subscribe(
          async (res: any) => {
            if (res?.response == 'success') {
              if (res?.printbody != null) {
                for (let i in res.printbody) {
                  await this.localHosthandler(res.printbody[i]);
                }
              }
              this.productForm.reset({});
              this.modalref;
            }
          },
          (error: any) => {
            console.log(error)
          }
        );
      } else {
        if (askQuantity == true) {
          this.modalref = this.modalService.open(this.content, { modalDialogClass: 'orderline' });
        }
      }
    }
  }

  openExtraInputModal(modal: any) {
    this.modalService.open(modal, { size: 'md' });
  }

  activities() {
    this.apiservice.getgreenactivities().subscribe(
      (res: customizing) => {
        if (res.response) {
          this.customizing = res.response;
        } else {
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  openInstructionList(instructions: any, payload: any, checklistFlag: any, stepFlag: string, attachmentFiles: any, dialogType: string) {
    this.stepFlag = stepFlag;
    this.showChecklist = false;
    this.showInstructions = false;
    if (payload && payload?.processStep != '') {
      if (checklistFlag == true) {
        this.showChecklist = checklistFlag;
        this.selectedOption = 'checklist';
      } else if (this.customizing?.showInstructions == stepFlag) {
        this.showInstructions = true;
        this.setOption('instructions', '');
      } else {
        this.showChecklist = true;
        this.selectedOption = 'checklist';
        // this.setOption('checklist', '');
      }
    } else {
      if (this.customizing.showInstructions != null) {
        this.showInstructions = true;
        this.setOption('instructions', '');
      } else {
        (attachmentFiles?.length > 0) ?
          this.setOption(attachmentFiles[0]?.document_type, attachmentFiles[0]) : '';
      }
    }

    if (dialogType === 'small') {
      let closeResult
      this.modalService.open(this.extrainputpopup, { size: 'md' }).result.then((result) => {
        closeResult = `Closed with: ${result}`;
        this.errorMsg = false
      }, (reason) => {
        this.errorMsg = false
        closeResult = `Dismissed ${this.getDismissReason(reason)

          }`;
      });;
      setTimeout(() => {
        let item;
        (this.checkListItems?.length > 0) ? item = this.checkListItems[0] : item = '';
        const projectInput: any = document.querySelector(`input[name='${item.fieldName}'` + ']');
        projectInput.focus();
      }, 500);
    } else {
      this.modalService.open(instructions, { modalDialogClass: 'instructions' });
      const modal: any = document.querySelector("ngb-modal-window");
      modal.style.overflow = "hidden";
    }
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


}
