<div class="row mb-4 blue titleBar">
    <div class="col-md-3 text-start"><a (click)="navigate()" (touchend)="navigate()"><i class="fa fa-arrow-left"></i>
            &nbsp;&nbsp;{{'HOME.BACKBUTTON' | translate}}
        </a></div>
    <div class="col-md-9 text-end" *ngIf="categories_flag">{{'HOME.BLUEQUES' | translate}}</div>
    <div class="col-md-9 text-end" *ngIf="alert_flag">{{'HOME.SENDNOTIFICATION' | translate}}</div>
</div>

<div class="row" id="prob_class" *ngIf="categories_flag">
    <div class="col-md-6 mx-2" *ngFor="let items of categories">
        <div class="row mb-2  actionButton " (click)="setFormFields(items); $event.stopPropagation();"
            (touchend)="setFormFields(items); $event.stopPropagation();">
            <div class="col my-auto text-start">
                <div class="row">
                    <div>
                        <span class="button-text-small" *ngIf="items.languageKey == null || items.languageKey == ''">
                            {{items.text}}
                        </span>
                        <span class="button-text-small" *ngIf="items.languageKey != null && items.languageKey != ''">{{
                            "HOME."+items.languageKey | translate }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row recenteMeldingen" *ngIf="!alert_flag">
    <strong class="text-start" *ngIf="notes && notes.length != 0 ">{{'HOME.RECENTNOTES' | translate}}</strong>
    <div class="col-md-8">
        <ul class="text-start">
            <li *ngFor="let itemss of notes">Op {{itemss.day}} om {{itemss.timing}} door {{itemss.displayName}}:
                {{itemss.fkEventDetail}}</li>
        </ul>
    </div>
</div>
<div class="row" id="prob_class" *ngIf="alert_flag">
    <div class="col-md-6 mx-2" *ngFor="let item of reports">
        <div class="row mb-2  actionButton" (click)="create_alert(item)"
            (touchend)="create_alert(item); $event.stopPropagation();">
            <div class="col my-auto text-start">
                <div class="row">
                    <div><span class="button-text-small">
                            {{item.text}}
                        </span></div>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #content let-modal>
    <div class="modal-header">
        <h2 class="modal-title" id="modal-basic-title">{{'HOME.REQUESTHELP' | translate}}</h2>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="navigate()">{{'HOME.CANCELBUTTON' |
            translate}}</button>
        <button type="button" class="btn btn-primary" (click)="submit();modal.close('Save click')"
            (touchend)="submit()">{{'HOME.CONFIRMBUTTON' | translate}}</button>
    </div>
</ng-template>

<ng-template #report let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="modal-basic-title">{{'HOME.REQUESTHELP' | translate}}</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click');">{{'HOME.CANCELBUTTON' |
            translate}}</button>
        <button type="button" class="btn btn-primary" (click)="submit()" (touchend)="submit()">{{'HOME.CONFIRMBUTTON' |
            translate}}</button>
    </div>
</ng-template>


<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title" id="exampleModalLabel">Additional Information</h2>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form>
                    <div class="mb-3">
                        <input type="text" class="form-control" placeholder="Items">
                    </div>
                </form>

            </div>
            <div class="modal-footer d-flex justify-content-end">

                <button type="button" class="btn btn-outline-dark" data-bs-dismiss="modal">Cancel</button>
                <button type="button" class="btn btn-primary">Confirm</button>

            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="example_Modal" tabindex="-1" aria-labelledby="ModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-conteqnt">
            <div class="modal-header">
                <h2 class="modal-title" id="ModalLabel">Additional Information</h2>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form>
                    <div class="mb-3">
                        <label>Would you like to send a message for a direct request for help?</label>
                        <input type="text" class="form-control">
                    </div>
                </form>

            </div>
            <div class="modal-footer d-flex justify-content-end">
                <button type="button" class="btn btn-outline-dark" data-bs-dismiss="modal">New</button>
                <button type="button" class="btn btn-primary">And</button>

            </div>
        </div>
    </div>
</div>

<ng-template #popupformmodal let-modal>
    <div class="modal-header">
        <h2 class="modal-title" id="modal-basic-title"> {{'HOME.ADDITIONALINFOTITTLE' | translate}} </h2>
        <button type="button" *ngIf="SelectedCategory.preventSkipExtraLevel != '1'" class="btn-close" aria-label="Close"
            (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <form [formGroup]="popupform" (keyup.enter)="submitdropdown()" novalidate>
            <div *ngFor="let a of placeholder;let i=index">
                <div class="mb-3">
                    <div class="form-group">
                        <input type="text" class="form-control" [formControlName]="a" placeholder="{{a}}">
                    </div>
                </div>
            </div>
            <div class="mb-3" *ngIf="dropdown_values.length > 0">
                <select class="form-select form-select-lg" formControlName="mail" [(ngModel)]="dropdown_values[0]"
                    aria-label=".form-select-sm example">
                    <option *ngFor="let item of dropdown_values" value={{item}}>{{item}}</option>
                </select>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="submitdropdown()"
            (touchend)="submitdropdown()">{{'HOME.CONFIRMBUTTON' | translate}}</button>
        <button type="button" *ngIf="SelectedCategory.preventSkipExtraLevel != '1'" class="btn btn-success reset"
            (click)="modal.close('Save click')">{{'HOME.CANCELBUTTON' | translate}}</button>
    </div>
</ng-template>