import {
  Component,
  ContentChild,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { NavigationStart, Router, ActivatedRoute } from '@angular/router';
import { base_routes } from 'src/app/constant';
import { DataService } from 'src/services/dataservice.service';
import { LandingPageComponent } from 'src/basemodule/landing-page/landing-page.component';
import { timer, Subscription, map } from 'rxjs';
import { CdTimerComponent } from 'angular-cd-timer';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/services/apiservice.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxUiLoaderService, NgxUiLoaderConfig } from 'ngx-ui-loader';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Activeuser, Userlist } from 'src/models/userlist';
import { Order, orderevendetails } from '../../models/order';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import 'moment/locale/ja';
import 'moment/locale/fr';
import 'moment/locale/de';
import moment from 'moment';
import { ChecklistModalComponent } from '../../basemodule/checklist-modal/checklist-modal.component';
import { AvailableUsers, checklistItem, createResponse } from '../../models/checklist';
import { customizing, responseCustomizing } from '../../models/customizing';
import { categorie, categories } from 'src/models/categories';
import { notes } from 'src/models/notes';
import { LoggedInUser } from 'src/models/loggedinuser';
import { DatePipe, Location } from '@angular/common'
import { subscribe, isSupported } from 'on-screen-keyboard-detector';
import { ReportsComponent } from 'src/basemodule/reports/reports.component';
import { UnplannedDownTimeComponent } from 'src/basemodule/unplanned-down-time/unplanned-down-time.component';
import { DownTimeComponent } from 'src/basemodule/down-time/down-time.component';
import { Language, Productdetails, currentScreen } from 'src/models/interface';
import { Workstation } from 'src/models/workstation';
import { ProjectListComponent } from 'src/basemodule/project-list/project-list.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'DD MM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'DD MM YYYY',
  },
};
declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [DatePipe, LandingPageComponent, {
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
  },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }],
  host: {
    '[class.has-sidebar]': '!user_screen && !OrderIntakeScreen',
  },
})
export class DashboardComponent implements OnInit {
  @ViewChild('instructions') public modalComponent !: ChecklistModalComponent;
  @ViewChild('unplannedDownTime') public unplannedDownTimeComponent: UnplannedDownTimeComponent;
  @ViewChild('downTimeComponent') public downTimeComponent: DownTimeComponent;
  @ViewChild('attachment') private contentRef: TemplateRef<Object>;
  @ViewChild('logoutt') private logoutRef: TemplateRef<Object>;
  @ViewChild('validate', { static: false }) vrt!: ElementRef<any>;
  @ViewChild("popupformmodal") private popupmodal: TemplateRef<Object>;
  timerSubscription: Subscription;
  @ViewChild('basicTimer') basicTimer: CdTimerComponent;
  @ViewChild('projectlist', { static: false }) projectListComponent: ProjectListComponent
  is_blurred: boolean;
  timer_flag = false;
  order_numbers: any;
  screen_status: boolean = false;
  downtime_flag: boolean = false;
  workinghours_flag: boolean = false;
  unplanned_flag: boolean = false;
  reports_flag: boolean = false;
  reportsonly_flag: boolean = false;
  requestforhelp_flag: boolean = false;
  project_flag: boolean = false;
  OrderIntakeScreen: boolean = false;
  checkListItems: checklistItem[];
  checklist_flag: any;
  checkListFormGroup: FormGroup<any>;
  Overallpage: boolean;
  green_screen: boolean;
  greencat_flag: boolean = false;
  data: any;
  yellow_screen: boolean;
  red_screen: boolean;
  user_screen: boolean = false;
  status_screen: boolean = false;
  currentuser: any;
  user_data: any;
  name: string | null;
  orders: any = [];
  modal_item: any;
  interval: any;
  display: string;
  timing: any;
  seconds: number = 0;
  minutes: number = 0;
  hours: number = 0;
  statusflag: string | null;
  counter: number;
  starttime: string;
  active_order: any;
  order_flag: boolean;
  workstationid: string | null;
  active_users: Activeuser[];
  categories: categorie[];
  reportstatus_flag: boolean = false;
  report_event: any;
  // available_users: Userlist[] = [];
  available_users: any;
  userIds: any;
  green_eventdata: any;
  closeResult: string;
  activityForm: any;
  workstation_orders: Workstation[];
  userflag: any;
  src: any;
  disablegreen: boolean = false;
  customerID: string | null;
  redcategories: categorie[];
  pre_orders: any;
  bluecategories: categorie[];
  report_blue: categorie[];
  orderss: any;
  person: string | null;
  users: any;
  adduserflag: boolean = false;
  attachments: any;
  green_categories: any;
  allow_greensubcat: boolean = false;
  attachment_flag: boolean;
  temp_orders: any;
  control: boolean = false;
  browserLang: any;

  lang_array: Language[];

  currentlang: string;
  current_temp_order: any;
  clicks: boolean = true;
  isShow: boolean;
  public ordercounter: any;
  public intervall: any;
  order_viewdisable: boolean = false;
  config: NgxUiLoaderConfig;
  disable_ordertabs: boolean;
  deviceInfo: import('ngx-device-detector').DeviceInfo;
  timer_Interval: any;
  timer_format: string;
  timer_id: any;
  countuptimer: NodeJS.Timer;
  t: string;
  workingon_flag: boolean;
  showorangetimer: boolean;
  dropdown: Productdetails[];
  SyncButtonFocus: boolean;
  ZoekenButtonFocus: boolean;
  ResetButtonFocus: boolean;
  customizing: responseCustomizing;
  goodsReciptFlag: any;
  YellowEventID: any;
  GreenEventID: number;
  dialogType: string;
  role: string | null;
  payload: { orderID: any; quantity: null; userID: any; processStep: any; };
  formGroupIndex: number;
  formgroups: any = [];
  checkListFormGroupTemp: FormGroup<any>;
  checkListItemsTemp: any;
  checkListItemsList: any = [];
  payloadd: { processStep: string; orderID: string; userID: string; };
  attachmentInfo: any;
  loadingReference: any;
  orderId: string;
  loadallactiveorders: any;

  isKeyboardEnabled: boolean = false;
  previousViewportHeight: number;
  currentEventtype: string;
  currentOrderColor: string;
  obj: any;
  ordersList: any;
  constructor(
    public location: Location,
    public activatedRoute: ActivatedRoute,
    private DataService: DataService,
    private deviceService: DeviceDetectorService,
    private ngxService: NgxUiLoaderService,
    private sanitizer: DomSanitizer,
    private translate: TranslateService,
    private modalService: NgbModal,
    public fb: UntypedFormBuilder,
    private toastrService: ToastrService,
    private apiService: ApiService,
    private router: Router,
    private viewContainerRef: ViewContainerRef
  ) {
    this.previousViewportHeight = window.innerHeight;
    this.activities();
    this.workstationid = localStorage.getItem('workstationID');
    this.customerID = localStorage.getItem('customerID');
    this.role = localStorage.getItem("role");
    router.events.forEach((event) => {
      let token = localStorage.getItem("smartbox_token");
      if (event instanceof NavigationStart) {
        if (event.url === '/login' && token) {
          (this.role === 'office') ?
            this.router.navigate(['/home/landingpage']) : this.router.navigate(['/home/dashboard']);
        }
      }
    });
    this.config = this.ngxService.getDefaultConfig();
    if (this.currentuser)
      this.temp_orders = localStorage.getItem(this.currentuser.userID);
    this.activityForm = this.fb.group({
      event: ['', [Validators.required]],
    });
    this.lang_array = [
      { name: 'Dutch', value: 'du' },
      { name: 'English', value: 'en' },
      { name: 'Spanish', value: 'es' },
    ];
    this.dropdown = [{
      ID: '1',
      Name: "Product",
      Descp: "Details",
    }, {
      ID: '2',
      Name: "Product",
      Descp: "Details"
    }, {
      ID: '3',
      Name: "Product",
      Descp: "Details"
    }]
    this.name = localStorage.getItem('smartbox_name');
    this.getUsers().then(r => {
    });
    this.getAvailableUsers();
    this.popupform = this.fb.group({
      mail: ['', []],
    })
  }
  public getBlurValue(value: any) {
    this.is_blurred = value
  }
  timer(countDownDate: any) {
    (this.countuptimer) ? clearInterval(this.countuptimer) : '';
    this.countuptimer = setInterval(() => {
      var now = new Date().getTime();
      var distance = now - countDownDate.getTime();
      var days: any = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours: any = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes: any = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds: any = Math.floor((distance % (1000 * 60)) / 1000);
      (hours < 10) ? hours = "0" + hours : hours = hours;
      (minutes < 10) ? minutes = "0" + minutes : minutes = minutes;
      (seconds < 10) ? seconds = "0" + seconds : seconds = seconds;
      if (days > 0) {
        this.t = days + "d " + hours + ":" + minutes + ":" + seconds;
      } else {
        this.t = hours + ":" + minutes + ":" + seconds;
      }
      (!this.t) ? this.t = "00:00:00" : '';
    }, 1000);
  }

  toggleDowntime(flag: boolean) {
    this.requestforhelp_flag = flag
  }

  setLang(lang: any) {
    if (lang.name != this.currentlang) {
      let item: any;
      if (lang.value === 'du') {
        item = 'du';
      } else if (lang.value === 'en') {
        item = 'en';
      } else {
        item = 'es';
      }
      this.DataService.changeMessage(item);
      this.DataService.updateData(item);
      this.currentlang = lang.name;
      this.translate.use(lang.value);
      let data = {
        userID: this.currentuser.userID,
        language: lang.value,
        firstName: this.currentuser.displayName,
      };
      this.apiService.changeLanguage(data).subscribe(
        (res: createResponse) => {
          if (res.response === 'success') {
            this.currentuser.language = item;
            localStorage.setItem("current_user", JSON.stringify(this.currentuser))
            this.getUsers();
          }
        },
        (error: any) => {
          console.log(error)
        }
      );
    }

  }

  activities() {
    this.apiService.getgreenactivities().subscribe(
      (res: customizing) => {
        if (res.response) {
          localStorage.setItem("customizing", JSON.stringify(res.response));
          this.customizing = res.response;
          this.attachment_flag = res.response.allow_attachments;
          if (
            res.response.numberOfGreenActivities != '1'
          ) {
            this.allow_greensubcat = true;
          } else {
            this.allow_greensubcat = false;
          }
          if (res.response.showAppTimer == '1') {
            this.timer_flag = true;
          } else {
            this.timer_flag = false;
          }
          if (res.response?.setTimerForOrange == '1') {
            this.showorangetimer = true;
            localStorage.setItem("showorange", 'true');
          } else {
            this.showorangetimer = false;
            localStorage.setItem("showorange", 'false');
          }
          if (res.response?.showChecklist == null) {
            localStorage.setItem("showchecklist", 'false');
          } else {
            this.checklist_flag = res.response?.showChecklist;
            localStorage.setItem("showchecklist", JSON.stringify(res.response.showChecklist));
          }
          (res.response.ordernumber_mask) ? localStorage.setItem("input_mask", res.response.ordernumber_mask) : '';
          (res.response.ordernumber_mask_description) ? localStorage.setItem('mask_descp', res.response.ordernumber_mask_description) : '';
          this.getCategories(this.customerID);
        }
      },
      (error: any) => {
        console.log(error)
      }
    );
  }

  getNotes() {
    this.apiService.getnotes(this.active_order.orderID).subscribe(
      (res: notes[]) => {
        if (res) {
          clearInterval(this.intervall);
          this.currentOrderColor = ''
          this.isShow = false;
          res.forEach((element: any) => {
            let d = parseInt(element.endTime) * 1000;
            var date = new Date(d);
            let datee =
              date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
            let month =
              date.getMonth() + 1 < 10
                ? '0' + (date.getMonth() + 1)
                : date.getMonth() + 1;
            element.day = datee + '-' + month;
            let hour =
              date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
            let min =
              date.getMinutes() < 10
                ? '0' + date.getMinutes()
                : date.getMinutes();
            element.timing = hour + ':' + min;
          });
          localStorage.setItem('notes', JSON.stringify(res));
        } else {
          localStorage.setItem('notes', JSON.stringify(null));
        }
      },
      (error: any) => {
        console.log(error)
      }
    );
  }

  lastUserFocus() {
    if (this.active_users.length > 0) {
      this.active_users[this.active_users.length - 1].focus = true;
      this.currentuser = this.active_users[this.active_users.length - 1];
      this.translate.use(this.currentuser.language);
      if (this.currentuser.language === 'du') {
        this.currentlang = 'Dutch';
      } else if (this.currentuser.language === 'en') {
        this.currentlang = 'English';
      } else {
        this.currentlang = 'Spanish';
      }
      this.DataService.updateData(this.currentuser.language);
      // localStorage.setItem("language",this.currentlang);
      // this.currentuser.language == 'du'
      //   ? (this.currentlang = 'Dutch')
      //   : (this.currentlang = 'English');
      localStorage.setItem('current_user', JSON.stringify(this.currentuser));
      this.getOrders(this.currentuser.userID, this.workstationid, '', true, '')
    }
  }

  lastOrderFocus() {
    this.orders[this.orders.length - 1].focus = true;
    this.active_order = this.orders[this.orders.length - 1];
    localStorage.setItem('active_order', JSON.stringify(this.active_order));
    localStorage.setItem(
      this.currentuser.userID + 'focus_order',
      JSON.stringify(this.active_order)
    );
  }

  openLg(content: any) {
    this.modalService.open(content, { windowClass: 'myCustomModalClass' });
  }

  ngOnDestroy() {
    (this.timerSubscription) ? this.timerSubscription.unsubscribe() : '';
  }

  getAvailableUsers() {
    if (this.active_users && this.active_users.length > 0) {
      let array: any = [];
      this.active_users.forEach((element: any) => {
        array.push(element.userID);
      });
      this.userIds = array.toString();
    } else {
      this.userIds = [];
    }
    this.apiService
      .getAvailableUsersList(this.userIds, this.workstationid)
      .subscribe(
        (res: Userlist) => {
          if (res.res) {
            this.available_users = res.res;
          } else {
            this.available_users = [];
          }
        },
        (error: any) => {
          console.log(error)
        }
      );
  }

  async getOrders(userid: any, workstationID: any, selecteddata: any, projectflag: boolean, redEvent: any) {
    console.log(selecteddata, 'selectedDatae')
    this.current_temp_order = selecteddata ? selecteddata.orderID : this.current_temp_order;
    this.apiService.getorder(userid, workstationID).subscribe(

      async (res: any) => {
        if (res) {
          let loadlist = JSON.parse(localStorage.getItem('orders') || '[]');

          loadlist.length > 0 ? this.loadallactiveorders = loadlist : this.loadallactiveorders;
          res.forEach((e: any, i: any) => {
            if (e.eventID == redEvent.eventID && e.orderID == redEvent.orderID && e.eventType == 'green') {
              e['mapping_field'] = redEvent.mapping_field
              e['isDelete'] = false
            }
            else if (e.eventID == redEvent.eventID && e.orderID == redEvent.orderID) {
              e['mapping_field'] = redEvent.mapping_field
            }
            // console.log(this.ordersList, 'ordersList')
            this.ordersList.forEach((v: any) => {
              console.log(v, 'v')
              // if (v.eventID == redEvent.eventID && v.orderID == redEvent.orderID) {
              //   v['mapping_field'] = redEvent.mapping_field
              // }

              if (v.orderID == e.orderID && e.eventID == v.eventID && e.eventType == 'green' && v.eventType == 'green') {
                if (v.uniqorder) {

                  e['uniqorder'] = v.uniqorder
                }
                if (v.customerRef) {
                  e['zaaglijstID'] = v.customerRef

                }
              }
            })

            // console.log(e, 'e')
            this.loadallactiveorders?.forEach((z: any) => {
              if (e.orderID === z.orderID && e.eventID == z.eventID && z.mapping_field) {
                e['mapping_field'] = z.mapping_field
              }
              if (e.orderID === z.orderID && e.eventID == z.eventID && z.eventType != 'green' && e.eventType != 'green'
              ) {
                e['zaaglijstID'] = z.zaaglijstID
              }
            })
            if (e.eventType == 'green' && selecteddata && e.orderID === selecteddata.orderID) {
              e['zaaglijstID'] = selecteddata.zaaglijstNo
            }
            // console.log(e.eventType != 'green', selecteddata.eventType == 'green')
            if (selecteddata.eventType == 'green' && selecteddata) {
              e['zaaglijstID'] = selecteddata.orderID
            }
            if ((selecteddata.eventType != 'green' && selecteddata.eventType && selecteddata) && (e.orderID && selecteddata.orderID)) {
              e['zaaglijstID'] = selecteddata.zaaglijstID
            }
            // if(selecteddata && (e.orderID===selecteddata.orderID) && e.eventType !=='green'){
            //   console.log('t67')            
            //   e['zaaglijstID']=selecteddata.zaaglijstID
            // }
          })
          this.pre_orders = localStorage.getItem('orders');
          this.pre_orders = JSON.parse(this.pre_orders)
          // console.log(JSON.parse(this.pre_orders),res,this.pre_orders == JSON.stringify(res),'allowwww')
          // var allow_flag = false;
          // var counter = 0 ;
          //  const checkCounter = async() => {
          var allow_flag = false;
          var counter = 0;
          var blue = 0;
          if (res?.length >= 1) {
            const checkCounter = async () => {
              for (let i = 0; i < res.length; i++) {
                if (this.pre_orders) {
                  for (let j = 0; j < this.pre_orders.length; j++) {
                    if (this.pre_orders[j].orderID == res[i].orderID && this.pre_orders[j].eventType == res[i].eventType) {
                      counter = counter + 1;
                    }
                    if (res[i].eventType == 'blue') {
                      blue = blue + 1;
                    }
                  }
                }
              }
            }
            await checkCounter();
            if (counter == res.length && blue == 0) {
              allow_flag = true;
              this.orders = res;
            } else {
              allow_flag = false;
              localStorage.setItem('orders', JSON.stringify(res));
            }
          } else {
            allow_flag = false;
            localStorage.setItem('orders', JSON.stringify(res));
          }
          if (allow_flag == true) {
          } else {
            this.orderss = res;
            let count = 0;
            this.orderss.forEach((element: any) => {
              if (element.eventType == 'blue') {
                count = count + 1;
              }
            });
            if (this.orders.length > 0) {
              this.orders.forEach((element: any, index: any, object: any) => {
                element.focus = false;
                element.matched = false;
                for (let item in this.orderss) {
                  if (element.orderID == this.orderss[item].orderID) {
                    element.matched = true;
                    element.eventType = this.orderss[item].eventType;
                    element.startTime = this.orderss[item].startTime;
                    element.endTime = this.orderss[item].endTime;
                    element.eventID = this.orderss[item].eventID;
                    element.monitorStartTime = this.orderss[item].monitorStartTime;
                    element.monitorEndTime = this.orderss[item].monitorEndTime;
                    element.fkWorkstationID = this.orderss[item].fkWorkstationID;
                    element.userID = this.orderss[item].userID;
                    element.eventDescription = this.orderss[item].eventDescription;
                    element.eventDetail = this.orderss[item].eventDetail;
                    element.focus = false;
                  }
                  if (this.orderss.length - 1 === parseInt(item) && element.matched == false && element.eventType != '') {
                    object.splice(index, 1);
                  }
                }
              });
            } else {
            }
            this.temp_orders = localStorage.getItem(this.currentuser.userID);
            // this.temp_orders = JSON.parse(this.temp_orders);
            (this.temp_orders && this.temp_orders != undefined) ? this.temp_orders = JSON.parse(this.temp_orders) : ''
            this.orders = [];
            if (this.orders.length == 0 && (res.length == 0 || this.orderss.length == count)) {
              if (this.currentuser) {
                if (this.temp_orders && this.temp_orders.length > 0) {
                  this.temp_orders.forEach((element: any) => {
                    if (element.eventType == '') {
                      this.orders.push(element)
                    }
                  });
                  if (this.orders.length == 0) {
                    localStorage.removeItem(this.currentuser.userID)
                    this.addOrder('s', true, '', '');
                    this.active_order = this.orders[0];
                  }
                  if (projectflag === true) {
                    this.screen_status = true;
                    this.downtime_flag = false;
                    this.workinghours_flag = false;
                    this.unplanned_flag = false;
                    this.reports_flag = false;
                    this.project_flag = false;
                  }
                } else {
                  this.orders = [];
                  (this.countuptimer) ? clearInterval(this.countuptimer) : '';
                  this.t = "00:00:00"
                  this.addOrder('s', true, '', '');
                  this.active_order = this.orders[0];
                  if (projectflag === true) {
                    this.screen_status = false;
                    this.downtime_flag = false;
                    this.workinghours_flag = false;
                    this.unplanned_flag = false;
                    this.reports_flag = false;
                    this.reportsonly_flag = false;
                    this.project_flag = true;
                  }
                }
              }
              (this.active_order) ?
                this.erpattachment(this.active_order) : '';
            } else {
              if (this.currentuser) {
                if (projectflag === true) {
                  this.screen_status = true;
                  this.downtime_flag = false;
                  this.workinghours_flag = false;
                  this.unplanned_flag = false;
                  this.reports_flag = false;
                  this.reportsonly_flag = false
                  this.project_flag = false;
                }
                let arrayy = [];
                let bluearrayy = [];
                for (let k = 0; k <= this.orderss.length - 1; k++) {
                  this.orderss[k].focus = false;
                  if (this.orderss[k].eventType != 'blue') {
                    arrayy.push(this.orderss[k]);
                  } else {
                    bluearrayy.push(this.orderss[k]);
                  }
                }
                if (bluearrayy && bluearrayy.length > 0) {
                  for (let i = 0; i < bluearrayy.length; i++) {
                    // if (bluearrayy[i].eventDescription.includes('Signaal')) {
                    this.reportstatus_flag = true;
                    this.person = localStorage.getItem('blueperson');
                    this.report_event = bluearrayy[i];
                    // }
                  }
                } else {
                  this.reportstatus_flag = false;
                }
                if (this.orders?.length == 0) {
                  this.orders = arrayy;
                } else {
                  this.orders = [...arrayy];
                }
                this.temp_orders = localStorage.getItem(this.currentuser.userID);
                if (this.temp_orders) {
                  (typeof (this.temp_orders) == 'string') ? this.temp_orders = JSON.parse(this.temp_orders) : '';
                  this.temp_orders.forEach((element: any) => {
                    for (let item in this.orders) {
                      if (element.eventType == '' && element.orderID != this.orders[item].orderID) {
                        if (this.orders.length - 1 == parseInt(item)) {
                          this.orders.push(element)
                        }
                      } else if (element.orderID == this.orders[item].orderID) {
                        break;
                      } else {
                      }
                    }
                  });
                }
                var collator = new Intl.Collator(undefined, {
                  numeric: true,
                  sensitivity: 'base',
                });
                let args = 'orderID';
                this.orders.sort((a: any, b: any) =>
                  collator.compare(a[args], b[args])
                );
                localStorage.setItem(
                  this.currentuser.userID,
                  JSON.stringify(this.orders)
                );
                if (this.current_temp_order) {
                  this.orders.forEach((element: any) => {
                    if (element.orderID == this.current_temp_order) {
                      localStorage.setItem(
                        this.currentuser.userID + 'focus_order',
                        JSON.stringify(element)
                      );
                    }
                  });
                }
                let check = localStorage.getItem(
                  this.currentuser.userID + 'focus_order'
                );
                check = JSON.parse(check as any);
                if (!check || (check as any).orderID == '') {
                  this.lastOrderFocus();
                } else {
                  let order_flag = false;
                  this.orders.forEach((element: any) => {
                    element.focus = false;
                    if (element.orderID == (check as any).orderID) {
                      order_flag = true;
                      element.focus = true;
                      this.active_order = element;
                      localStorage.setItem(
                        'active_order',
                        JSON.stringify(this.active_order)
                      );
                    }
                  });
                  order_flag == false ? this.lastOrderFocus() : '';
                }
                this.start(this.active_order, this.active_order.eventType);
                this.erpattachment(this.active_order);
                let array = [];
                array[0] = this.currentuser;
                this.getusericon(this.active_users);
                this.getNotes();
              }
            }
          }
        } else {
          this.toastrService.error('No orders for this user!', 'Error!');
        }
      },
      (error: any) => {
        this.toastrService.error('Something went wrong!', 'Error!');
      }
    );
  }

  async getUsers() {
    if (this.role != 'office') {
      let res: any = await this.apiService.getuserlist(this.workstationid).toPromise();

      if (res?.response == 'success') {
        if (res?.users?.length == 0) {
          this.timer_Interval = undefined;
          this.active_users = [];
          this.userList();
          if (this.timer_Interval == undefined) {
            this.timer_Interval = setInterval(() =>
              window.location.reload(), 900000);
          }
          this.orders = [];
          this.t = "00:00:00";
          this.active_order = undefined;
          this.report_event = undefined;
          this.currentlang = 'Dutch';
          this.translate.use('du');
          this.DataService.updateData('du');
          // localStorage.setItem('language','Dutch');
          localStorage.removeItem('users');
        } else {
          if (this.customerID == '9' && this.OrderIntakeScreen) {
            this.Overallpage = false;
            this.OrderIntakeScreen = true;
          } else {
            this.Overallpage = true;
            this.status_screen = true;
          }

          this.timer_Interval != undefined ? clearInterval(this.timer_Interval) : '';
          this.users = localStorage.getItem('users');
          this.users = JSON.parse(this.users);
          if (this.adduserflag && this.users != null) {
            let unmatched = res.users.filter(
              (item: { userID: any }) =>
                !this.users.some(
                  (_item: { userID: any }) => _item.userID == item.userID
                )
            );
            if (unmatched?.length > 0) {
              this.active_users.push(unmatched[0]);
              localStorage.setItem(
                'users',
                JSON.stringify(this.active_users)
              );
              this.lastUserFocus();
            } else {
              this.active_users = this.users;
              localStorage.setItem('users', JSON.stringify(this.users));
            }
          } else {
            this.active_users = res.users;
            localStorage.setItem('users', JSON.stringify(res.users));
          }

          this.active_users.forEach((element: any) => {
            element.focus = false;
          });
          let check = localStorage.getItem('current_user');
          if (!check) {
            this.currentuser =
              this.active_users[this.active_users.length - 1];
            localStorage.setItem(
              'current_user',
              JSON.stringify(this.currentuser)
            );
            this.lastUserFocus();
          }
          this.check_screen_play();
          //this.getorders(this.currentuser.userID, this.workstationid);
          this.getAvailableUsers();
          this.getusericon(this.active_users);
        }
      } else if (res.response === 'error: missing auth') {
        this.toastrService.error('Missing auth', 'Error!');
        localStorage.clear();
        this.router.navigate([base_routes.LOGIN]);
      } else {
        this.toastrService.error('No user found!', 'Error!');
      }
    }
  }

  check_screen_play() {
    let flag = localStorage.getItem(this.currentuser?.userID + 'screen_play');
    (flag == 'true') ? (this.control = true) : (this.control = false);
  }

  getusericon(user: any) {
    this.workstation_orders = [];
    if (user && user.length > 0) {
      user.forEach((element: any) => {
        this.apiService.getorder(element.userID, this.workstationid).subscribe(
          (res: any) => {
            if (res) {
              element.icon = '';
              if (res.length == 0) {
                element.icon = 'pause';
              } else {
                res.forEach((item: any) => {
                  this.workstation_orders.push(item);
                });
                for (let j = 0; j <= res.length - 1; j++) {
                  if (res[j].eventType == 'green') {
                    element.icon = 'play';
                    break;
                  }
                  if (j == res.length - 1 && element.icon == '') {
                    element.icon = 'pause';
                  }
                }
              }
            } else {
              this.toastrService.error('Something went wrong!', 'Error!');
            }
          },
          (error: any) => {
            this.toastrService.error(
              `Something went wrong! ${error}`,
              'Error!'
            );
          }
        );
      });
    }
  }

  async stop(flag: any, color: any, onlydelete: any) {
    (this.checkListFormGroup) ? this.checkListFormGroup.reset({}) : '';
    (this.checkListFormGroupTemp) ? this.checkListFormGroupTemp.reset({}) : '';
    this.formgroups = [];
    this.checkListItems = [];
    this.checkListItemsList = [];
    this.orderId = ''
    if (flag?.eventType == '') {
    } else {
      let datas = {
        eventID: flag.eventID,
        orderkey: flag.mapping_field ? flag.mapping_field : '',
        deleteFromERP: false,
        userID: this.currentuser ? this.currentuser.userID : flag.userID,
        workstationID: this.workstationid,
      };
      if (color == 'green' && onlydelete == true && flag.isDelete) {
        datas.deleteFromERP = true;
      }

      else if ((color != 'green' || this.timer_flag == false) && onlydelete == true) {
        datas.deleteFromERP = false;
      } else {
      }
      let order = flag;
      let res: any = await this.apiService.stopevent(datas).toPromise();
      if (res) {
        if (res.response === 'success') {
          let temp = '';
          let checklist_array: any = [];
          var checklist: any;
          if (this.customizing?.showChecklist != null) {
            await this.customizing?.showChecklist.forEach((element: any) => {
              if (element == 'afterGreenClosed') {
                checklist_array.push(element);
                checklist = true;
              }
            });
          } else {
            checklist = false;
          }
          if (((checklist == true || this.customizing.showInstructions == 'afterGreenClosed') && color == 'green') && onlydelete == true) {
            const go = async () => {
              for await (let item of checklist_array) {
                await this.erpattachment(this.active_order);
                this.goodsReciptFlag = 'afterGreen';
                await this.getChecklistItems(this.currentuser?.userID, this.active_order?.orderID, item, checklist, 'afterGreenClosed');
              }
              ;
            }
            await go();
            this.checkListFormGroup = this.formgroups[0];
            this.checkListItems = this.checkListItemsList[0];
            this.formGroupIndex = 0;
            this.openCloseInstructionModal('1', checklist, 'afterGreen');
          } else {
            if (this.customizing.showInstructions == 'afterGreenClosed' && onlydelete == true) {
              await this.erpattachment(this.active_order), this.goodsReciptFlag = 'afterGreenClosed';
              await this.getChecklistItems(this.currentuser?.userID, this.active_order?.orderID, temp, checklist, 'afterGreenClosed');
              this.openCloseInstructionModal('3', checklist, 'afterGreenClosed');
            }
          }
          if (color === 'blue') {
            this.reportstatus_flag = false;
          } else if (this.currentuser == undefined) {
          } else {
            if (onlydelete == true) {
              if (this.orders?.length == 1) {
                (this.orders[0].orderID == order.orderID) ? (this.orders = [], localStorage.removeItem(this.currentuser.userID)) : '';
              } else {
                this.orders.filter((element: any) => {
                  return element.orderID !== order.orderID
                });
              }
              localStorage.removeItem('active_order');
              await this.getOrders(this.currentuser.userID, this.workstationid, '', true, '');
            }
            localStorage.removeItem('orders');
            localStorage.removeItem(this.currentuser.userID + 'focus_order');
          }
        } else {
          this.toastrService.error('Event is not stopped!', 'Error!');
        }
      }
    }
  }

  start(flag: any, color: any) {
    if (flag?.eventType == 'grey' || flag?.eventType == '') {
      (this.countuptimer) ? clearInterval(this.countuptimer) : '';
      this.t = "00:00:00";
    } else {
      //this.timer_flag = false;
      const unixTimestamp = flag?.monitorStartTime;
      const milliseconds = unixTimestamp * 1000;
      const dateObject = new Date(milliseconds);
      var m_date =
        dateObject.getFullYear() +
        '-' +
        (dateObject.getMonth() + 1) +
        '-' +
        dateObject.getDate();
      var m_time =
        dateObject.getHours() +
        ':' +
        dateObject.getMinutes() +
        ':' +
        dateObject.getSeconds();
      var m_dateTime = new Date(m_date + ' ' + m_time);
      this.timer(m_dateTime);
      this.disablegreen = false;
    }
  }

  async delete_order() {

    this.loadallactiveorders = JSON.parse(localStorage.getItem('orders') || '[]');
    if (this.reportstatus_flag == true) {
      localStorage.removeItem('active_order');
      localStorage.removeItem('orders');
      localStorage.removeItem(this.currentuser.userID + 'focus_order');
      await this.stop(this.report_event, 'blue', true);
      await this.stop(this.active_order, this.active_order.eventType, true);
    } else if (
      this.reportstatus_flag == false &&
      this.active_order != undefined &&
      this.active_order.eventType != ''
    ) {
      localStorage.removeItem('active_order');
      localStorage.removeItem('orders');
      localStorage.removeItem(this.currentuser.userID + 'focus_order');
      await this.stop(this.active_order, this.active_order.eventType, true);
    } else if (
      this.active_order.eventType == '' &&
      this.active_order.focus == true
    ) {
      let id = this.active_order.orderID;
      let t = this.orders.filter((item: any) => {
        return item.orderID != id;
      });
      this.temp_orders = localStorage.getItem(this.currentuser.userID);
      this.temp_orders = JSON.parse(this.temp_orders);
      let k = this.temp_orders.filter((item: any) => {
        return item.orderID != id;
      });
      localStorage.setItem(this.currentuser.userID, JSON.stringify(k));
      this.temp_orders = localStorage.getItem(this.currentuser.userID);
      this.orders = t;
      localStorage.removeItem('active_order');
      localStorage.removeItem('orders');
      localStorage.removeItem(this.currentuser.userID + 'focus_order');
    } else {
      this.toastrService.warning('Please select an order to close', 'Warning');
    }
    this.orders.length > 0 ? this.lastOrderFocus() : this.addOrder('s', true, '', '');
  }

  renderBlurClass() {
    if (this.customizing?.showInstructions === null) {
      if (this.customizing?.showChecklist === null) {
        if (!this.attachments) {
          return "blur_button";
        } else {
          return "";
        }
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  async erpattachment(data: any) {
    this.apiService.getattachment(data, this.workstationid).subscribe(
      (res: any) => {
        this.attachmentInfo = res.result;
        if (res.result && res.result?.files?.length > 0) {
          this.attachments = res.result.files;
          this.deviceInfo = this.deviceService.getDeviceInfo();
          if (this.deviceInfo.deviceType == 'desktop') {
            this.src = this.sanitizer.bypassSecurityTrustResourceUrl(
              environment.fileurl +
              res.result.files[0].filename +
              '#toolbar=0&navpanes=0&scrollbar=0'
            );
          } else {
            this.src = this.sanitizer.bypassSecurityTrustResourceUrl(
              'https://docs.google.com/gview?url=' +
              environment.fileurl +
              res.result.files[0].filename +
              '#embedded=true'
            );
          }
        } else {
          this.attachments = undefined;
        }
      },
      (error: any) => {
        console.log(error)
      }
    );
  }

  open_modal(data: any) {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    if (this.deviceInfo.deviceType == 'desktop') {
      this.src = this.sanitizer.bypassSecurityTrustResourceUrl(
        environment.fileurl +
        data.filename +
        '#toolbar=0&navpanes=0&scrollbar=0'
      );
    } else {
      this.src = this.sanitizer.bypassSecurityTrustResourceUrl(
        'https://docs.google.com/gview?url=' +
        environment.fileurl +
        data.filename +
        '&embedded=true'
      );
    }
  }

  openDialogLoading(content: any) {

    this.loadingReference = this.modalService.open(content, {
      backdrop: 'static',
      centered: true,
      windowClass: 'modalClass',
      keyboard: false
    });

    setTimeout(() => {
      this.loadingReference.close();
      this.loadingReference = null
    }, 5000)
  }

  closeDialogLoading() {
    this.loadingReference.close()
    this.loadingReference = null
  }

  async addOrder(data: any, navigatioFlag: boolean, zaaglijstID: any, selectedData: any) {
    await this.getUsers();
    let flag;
    this.active_users.forEach((element: any) => {
      if (element.userID == this.currentuser.userID) {
        flag = true;
      }
    });
    if (flag) {
      if (data != 's') {

        let errorFlag;
        for (let k = 0; k <= this.orders.length - 1; k++) {
          if (this.orders[k].orderID == data) {
            errorFlag = true;
            break;
          }
        }
        if (errorFlag == true && navigatioFlag) {
          this.projectListComponent.downtime_flag = false;
          this.projectListComponent.screen_status = true;
          this.projectListComponent.reports_flag = false;
          this.projectListComponent.unplanned_flag = false;
          this.projectListComponent.workinghours_flag = false;
          this.toastrService.error('Order already exist', 'error');
        } else {
          let temp: any[] = [];
          this.orders.forEach((element: any) => {
            if (element.focus == true) {
              temp.push(element);
              element.orderID = data;
              element.mapping_field = selectedData.mapping_field;
              element.zaaglijstNo = selectedData.zaaglijstNo;
              element.zaaglijstID = element.zaaglijstID ? element.zaaglijstID : zaaglijstID;
              this.active_order = element;
              localStorage.setItem(
                'active_order',
                JSON.stringify(this.active_order)
              );
              localStorage.setItem(
                this.currentuser.userID + 'focus_order',
                JSON.stringify(this.active_order)
              );
            }
          });
          this.temp_orders = localStorage.getItem(this.currentuser.userID);
          if (this.temp_orders) {
            (typeof (this.temp_orders == 'string')) ? this.temp_orders = JSON.parse(this.temp_orders) : '';
            this.temp_orders.forEach((element: any) => {
              temp.push(element);
            });
          }
          localStorage.setItem(this.currentuser.userID, JSON.stringify(temp));

          if (navigatioFlag === true) {

            this.screen_status = true;
            this.workinghours_flag = false;
            this.downtime_flag = false;
            this.unplanned_flag = false;
            this.reports_flag = false;
            this.reportsonly_flag = false;
            this.workingon_flag = false;
            this.project_flag = false;

          }
          const collator = new Intl.Collator(undefined, {
            numeric: true,
            sensitivity: 'base',
          });
          let args = 'orderID';
          this.orders.sort((a: any, b: any) =>
            collator.compare(a[args], b[args])
          );
        }





        let auto_yellow_order = this.categories?.filter((v: any) => v.value === this.customizing.startEventAfterOrderCreation)
        let auto_red_order = this.redcategories?.filter((v: any) => v.value === this.customizing.startEventAfterOrderCreation)
        let auto_blue_order = this.bluecategories?.filter((v: any) => v.value === this.customizing.startEventAfterOrderCreation)
        if (this.customizing.autoStartGreenAfterOrderCreation === '1' && navigatioFlag === true) {
          await this.set_flag('green', this.contentRef,)
        }
        else {
          if (auto_yellow_order.length > 0) {
            if (auto_yellow_order[0].extraLevel) {
              await this.set_flag('yellow', this.contentRef);
              await this.setFormFields(auto_yellow_order[0], 'yellow')
            } else {

              await this.createOrder('yellow', auto_yellow_order[0], this.active_order);
            }
          }
          else if (auto_red_order.length > 0) {
            if (auto_red_order[0].extraLevel) {
              await this.set_flag('red', this.contentRef);
              await this.setFormFields(auto_red_order[0], 'red')
            } else {
              await this.createOrder('red', auto_red_order[0], this.active_order);

            }
          }
          else if (auto_blue_order.length > 0) {
            await this.createOrder('blue', auto_blue_order[0], this.active_order);
          }
        }

      }
      else {
        data = (data == 's') ? '' : data;
        if (this.orders.length > 0) {
          this.orders.forEach((element: any) => {
            element.focus = false;
          });
        }
        this.orders.push({ orderID: data, eventType: '', focus: true });
        ((this.countuptimer)) ? (clearInterval(this.countuptimer), this.t = "00:00:00") : '';
        this.orders.forEach((element: any) => {
          if (element.focus == true) {
            element.orderID = data;
            this.active_order = element;
            localStorage.setItem(
              'active_order',
              JSON.stringify(this.active_order)
            );
          }
        });
        if (navigatioFlag === true) {
          this.screen_status = false;
          this.workinghours_flag = false;
          this.downtime_flag = false;
          this.unplanned_flag = false;
          this.reports_flag = false;
          this.workingon_flag = false;
          this.project_flag = true;
        }

      }
    } else {
      localStorage.removeItem('active_order');
      this.orders = [];
      this.t = "00:00:00";
      localStorage.removeItem(this.currentuser.userID + 'focus_order');
      localStorage.removeItem(this.currentuser.userID);
      this.lastUserFocus();
      await this.addOrder('s', true, '', '');
      await this.getOrders(this.currentuser.userID, this.workstationid, '', true, '');
    }
    // this.closeDialogLoading()

  }

  async removeUser(data: any) {
    this.modalService.dismissAll();
    this.orders.forEach(async (element: any) => {
      if (element.eventType == 'green') {
        await this.stop(element, 'green', false);
        this.active_order = element;
        let data = {
          eventDescription: '16',
          value: '16',
          userID: element.userID,
        };
        await this.createOrder('grey', data, this.active_order);
      }
    });
    let datas = { userID: data.userID };
    this.apiService.deleteuser(datas).subscribe(
      (res: any) => {
        if (res.response == 'success') {
          this.adduserflag = false;
          localStorage.removeItem(this.currentuser.userID);
          localStorage.removeItem('current_user');
          localStorage.removeItem('active_order');
          this.currentuser = undefined;
          this.active_order = undefined;
          this.getUsers();
          // this.getavailableusers();
        } else {
          this.toastrService.error('User is not unsubscribed!', 'Error!');
        }
      },
      (error: any) => {
        this.toastrService.error('Something went wrong!', 'Error!');
      }
    );
  }

  modalValues(item: any) {
    this.modal_item = item;
  }

  addUser(data: any) {
    let datas = { userID: data.userID };
    this.apiService.adduser(datas).subscribe(
      (res: any) => {
        if (res.response == 'success') {
          if (this.customerID === '9') {

          }
          this.adduserflag = true;
          this.user_screen = false;
          this.status_screen = true;
          localStorage.removeItem('active_order');
          this.getUsers();
        } else {
          this.toastrService.error('User is not added!', 'Error!');
        }
      },
      (error: any) => {
        this.toastrService.error('Something went wrong!', 'Error!');
      }
    );
    this.lastUserFocus();
  }

  realTimeOrder() {
    this.timerSubscription = timer(0, 1000000)
      .pipe(
        map(() => {
          if (this.currentuser) {
            this.getOrders(this.currentuser.userID, this.workstationid, '', true, '');
          }
        })
      ).subscribe();
  }

  async currentUser(item: any) {

    localStorage.removeItem('active_order');
    localStorage.removeItem('orders');
    const temp = (window.event as any).srcElement.className;

    if (temp.includes('icon-close-user ms-auto icon-close')) {
      this.open(this.logoutRef);
    } else {
      if (item?.displayName != this.currentuser?.displayName || this.user_screen) {
        this.orders = [];
        this.Overallpage = true;
        this.user_screen = false;
        this.status_screen = true;
        this.reportstatus_flag = false;
        this.currentuser = item;
        localStorage.setItem('current_user', JSON.stringify(this.currentuser));
        // this.currentuser.language == 'du' ? (this.currentlang = 'Dutch') : (this.currentlang = 'English');
        if (this.currentuser.language === 'du') {
          (this.currentlang = 'Dutch')
        } else if (this.currentuser.language === 'en') {
          this.currentlang = 'English';
        } else {
          this.currentlang = 'Spanish';
        }
        this.DataService.updateData(this.currentuser.language);
        this.translate.use(this.currentuser.language);
        await this.getOrders(this.currentuser.userID, this.workstationid, '', true, '');
        this.check_screen_play();
      }
    }
    // this.closeDialogLoading()
  }

  focusButton(item: any) {
    this.orders.forEach((element: any) => {
      element.focus = false;
    });
    item.focus = true;
    this.active_order = item;
    this.current_temp_order = undefined;
    this.erpattachment(this.active_order);
    this.screen_status = true;
    this.workinghours_flag = false;
    this.downtime_flag = false;
    this.unplanned_flag = false;
    this.reports_flag = false;
    this.reportsonly_flag = false;
    this.project_flag = false;
    (item.eventType == '' && (this.countuptimer)) ? (clearInterval(this.countuptimer), this.t = "00:00:00") : '';
    if (item.eventType) {
      (this.countuptimer) ? clearInterval(this.countuptimer) : '';
      this.t = "00:00:00";
      localStorage.setItem('active_order', JSON.stringify(this.active_order));
      this.start(item, this.active_order.eventType);
    }
    localStorage.setItem(
      this.currentuser.userID + 'focus_order',
      JSON.stringify(this.active_order)
    );

    if (item.orderID == '') {
      this.screen_status = false;
      this.workinghours_flag = false;
      this.downtime_flag = false;
      this.unplanned_flag = false;
      this.reports_flag = false;
      this.project_flag = true;
    }
    this.getNotes();
  }

  userList() {
    this.getAvailableUsers();
    this.user_screen = true;
    this.status_screen = false;
    this.control = false;
    this.OrderIntakeScreen = false;
    this.Overallpage = true;
    // this.closeDialogLoading()

  }

  async openAttachment() {
    this.checkListItemsList = [];
    this.formgroups = [];
    await this.erpattachment(this.active_order);
    let checkListItems: any = [];
    if (this.attachmentInfo?.hasOwnProperty('checklists')) {
      checkListItems = this.attachmentInfo.checklists;
    }
    const go = async () => {
      for await (let item of checkListItems) {
        await this.erpattachment(this.active_order);
        this.goodsReciptFlag = 'checklist';
        await this.getChecklistItems(this.currentuser?.userID, this.active_order?.orderID, item, true, '');
      }
    }
    await go();
    if (checkListItems.length) {
      this.checkListFormGroup = this.formgroups[0];
      this.checkListItems = this.checkListItemsList[0];
      this.formGroupIndex = 0;
      this.openCloseInstructionModal('1', true, 'edit');
    } else {
      this.dialogType = 'full'
      this.checkListItems = [];
      this.goodsReciptFlag = '';
      this.openCloseInstructionModal('3', '', 'document');
    }
  }

  setTimer() {
    setInterval(() => {
      let dt = new Date();
      let hours = dt.getHours();
      let AmOrPm = hours >= 12 ? 'PM' : 'AM';
      hours = (hours % 12) || 12;
      let minutes = dt.getMinutes();
      let getSecunds = dt.getSeconds();
      if (hours == 12 && minutes == 0 && getSecunds == 0 && AmOrPm == 'AM') {
        location.reload();
      }
    }, 1000);
  }

  ngOnInit(): void {

    this.loadallactiveorders = JSON.parse(localStorage.getItem('orders') || '[]');
    this.ordersList = JSON.parse(localStorage.getItem('orders') || '[]');
    this.activatedRoute.queryParamMap.subscribe((params) => {
      var token: any = params.get("autologin");
      if (token) {
        let payload = { token };
        this.apiService.validateAutoLoginToken(payload).subscribe(
          (res: LoggedInUser) => {
            if (res.login === true) {
              localStorage.setItem("smartbox_token", res.cookie);
              localStorage.setItem("workstationID", res.workstationID);
              localStorage.setItem("smartbox_name", res.workstationName);
              localStorage.setItem("customerID", res.customerID);
              localStorage.setItem("smartbox_Authenticated", "true");
              if (res.template == '1') {
                localStorage.setItem("role", "office");
                this.router.navigate([base_routes.HOME + '/' + base_routes.LANDING_PAGE], { queryParams: {} });
              } else {
                localStorage.setItem("role", "shopfloor");
                this.router.navigate([base_routes.USER + '/' + base_routes.DASHBOARD], { queryParams: {} });
              }
            }
            this.location.replaceState(this.location.path().split('?')[0], '');
            window.location.reload();
          }, (error) => {
            console.log(error)
          })
      }
    });

    this.deviceInfo = this.deviceService.getDeviceInfo();
    if (this.deviceInfo.deviceType !== 'desktop') {
      $(document).ready(function (ex: any) {
        $('#menuLoginButton').on("touchstart", function (e: any) {
          $('#example').modal('show');
        });
        $('.nav-pills').on("touchstart", function (event: any) {
          event.preventDefault();
          // Toggle dropdown if not already visible:
          if ($('.dropdown').find('.dropdown-menu').is(":hidden")) {
            $('.dropdown-toggle').dropdown('toggle');
          } else {
            $('.dropdown-toggle').prev().dropdown("toggle");
          }
        });

        // $(document).on("touchstart", function (e:any) {
        //   $(".attention").css({opacity: 1})
        //   $('.attention').css('left',e.targetTouches[0].pageX-24);
        //   $('.attention').css('top',e.targetTouches[0].pageY-24);
        //   $('.attention').fadeIn(100).fadeOut(100);
        // });
        $(document).on("click", function (e: any) {
          $(".attention").css({ opacity: 1 });
          $('.attention').css('left', e.pageX - 24);
          $('.attention').css('top', e.pageY - 24);
          $('.attention').fadeIn(30).fadeOut(30);
        });
      });
    }
    this.setTimer();
    localStorage.removeItem('orders');
    let user = localStorage.getItem('current_user');
    (user) ? (this.currentuser = JSON.parse(user)) : '';
    (user) ? this.translate.use(this.currentuser.language) : this.translate.use('du');
    //(this.currentuser) ? (this.currentuser.language == 'du') ? (this.currentlang = 'Dutch') : (this.currentlang = 'English') : '';

    if (this.currentuser) {
      if (this.currentuser.language === 'du') {
        (this.currentlang = 'Dutch')
      } else if (this.currentuser.language === 'en') {
        this.currentlang = 'English';
      } else {
        this.currentlang = 'Spanish';
      }
      this.DataService.updateData(this.currentuser.language);
    } else {
      this.DataService.updateData('du');
    }

    let order = localStorage.getItem('active_order');
    (order) ? (this.active_order = JSON.parse(order)) : '';
    if (order && this.active_order.eventType == 'yellow') {
      this.toggleDowntime(true)
    } else {
      this.toggleDowntime(false)
    }
    (user) ? this.realTimeOrder() : '';
  }

  ngDoCheck() {
    try {
      document.getElementById('validate')?.focus();
    } catch (errr) {
      console.log(errr)
    }
  }


  async getChecklistItems(userID: string, orderID: string, flag: string, checklistFlag: any, stepflag: string): Promise<void> {
    let payload = {
      "processStep": flag,
      "orderID": orderID,
      "userID": userID,
    };
    this.payloadd = payload;
    if (flag != '') {
      await this.apiService.getChecklist(userID, flag, orderID).toPromise().then(
        async (response) => {
          let res: any = response;
          if (res.response == 'success') {
            if (res.fields.length > 0) {

              res?.fields.forEach((v: any) => {
                if (v['multi-select'] && !(typeof v.value == 'object')) {
                  v['value'] = [v.value]

                }
              })
              this.dialogType = res.dialogType;
              this.checkListItemsTemp = res?.fields;
              const formGroup: any = {};
              for (let prop of Object.keys(this.checkListItemsTemp)) {
                let key = this.checkListItemsTemp[prop].fieldName;
                let value;
                let date;
                if (this.checkListItemsTemp[prop].fieldType == 'date') {
                  if (this.checkListItemsTemp[prop].value != '') {
                    let temp = this.checkListItemsTemp[prop].value.split('-');
                    date = { date: parseInt(temp[0]), month: parseInt(temp[1]) - 1, year: parseInt(temp[2]) };
                    value = moment(date);
                  } else {
                    value = '';
                  }
                } else {
                  if (this.checkListItemsTemp[prop].fieldType == 'checkbox') {
                    if (this.checkListItemsTemp[prop].value != 'true' && this.checkListItemsTemp[prop].value != true) {
                      value = '';
                    } else {
                      value = this.checkListItemsTemp[prop].value;
                    }
                  } else {
                    value = this.checkListItemsTemp[prop].value;
                  }
                }
                formGroup[key] = new FormControl(value);
                (this.checkListItemsTemp[prop]?.protected == true) ? formGroup[key].disable() : '';
              }
              // this.checkListFormGroupTemp = new FormGroup(formGroup);
              formGroup['details'] = new FormControl(payload);
              this.checkListFormGroupTemp = new FormGroup(formGroup);
              this.checkListItemsList.push(this.checkListItemsTemp)
              this.formgroups.push(this.checkListFormGroupTemp);
              return this.checkListFormGroupTemp;
            }
          }
        });
    }
    // setTimeout(() => {
    //   this.modalComponent.openInstructionList(this.modalComponent.instructionspopup,payload,checklistFlag,stepflag,this.attachments,this.dialogType);
    // }, 1300);

  }

  async createOrder(flag: any, eventDescription: any, item: any) {
    // console.log(flag, item);
    (this.checkListFormGroup) ? this.checkListFormGroup.reset({}) : '';
    (this.checkListFormGroupTemp) ? this.checkListFormGroupTemp.reset({}) : '';
    this.formgroups = [];
    this.checkListItems = [];
    this.checkListItemsList = [];
    let user_flag = true;
    if (user_flag) {
      if (this.active_order?.orderID != '' || item.orderID != '') {
        this.order_viewdisable = true;
        this.current_temp_order = this.active_order.orderID;
        var unixtimestamp = Math.floor(new Date().getTime() / 1000);
        let datas = {
          orderNr: (flag === 'green' && item.zaaglijstID) ? item.zaaglijstID : (flag !== 'green' && item.customerRef != '' && item.customerRef) ? item.customerRef : item.orderID,
          customerRef: (flag !== 'green' && item.eventType == '') ? item.orderID : item.customerRef ? item.customerRef : '',
          orderkey: (flag == 'yellow' || flag == 'red') ? '' : item.mapping_field ? item.mapping_field : '',
          userID:
            this.currentuser != undefined
              ? this.currentuser.userID
              : eventDescription.userID,
          actionType: flag,
          eventDescription: (item.eventType == 'yellow' || item.eventType == 'red') && (flag == 'green') ? "" : eventDescription.value
            ? eventDescription.eventDescription
            : eventDescription,
          actionDetail: eventDescription.value
            ? eventDescription.value
            : eventDescription,
          workstationID: this.workstationid,
          StartTime: unixtimestamp,
        };
        (datas.userID != undefined) ? '' : (datas.userID = eventDescription.userID);
        this.green_eventdata ? (datas.orderNr = this.green_eventdata.orderID) : '';
        this.green_eventdata = undefined;
        (flag == 'green' && !this.green_categories) ? (datas.actionDetail = null) : '';
        this.apiService.addorder(datas).subscribe(
          async (res: Order) => {
            if (res.response == 'success') {
              if (flag != 'red') {
                clearInterval(this.intervall);
                this.currentOrderColor = ''
                this.isShow = false;
              }



              if (flag == 'yellow') {
                this.YellowEventID = res.eventID;
              } else if (flag == 'green') {
                this.GreenEventID = res.eventID;
              } else {
              }
              let temp: any;
              let checklist: any;
              let checklist_array: any = [];
              if (this.customizing?.showChecklist != null) {
                await this.customizing?.showChecklist.some((element: any) => {
                  if (element == 'afterGreen' || element == 'afterGreenGoodsReceipt') {
                    checklist = true;
                    checklist_array.push(element);
                    temp = element;
                    // return true;
                  } else if (element == 'dependingOnOrderResponse') {
                    if (res?.triggerChecklist) {
                      checklist = true;
                      temp = res?.triggerChecklist;
                      checklist_array.push(...temp)
                    } else {
                      checklist = false;
                    }
                  } else if (element == 'preSSOP') {
                    checklist_array.push(element);
                    checklist = true;
                    temp = element;
                  } else if (element == 'afterGreenClosed') {
                    if (!checklist) {
                      checklist = false;
                    }
                  } else if (element == 'oncePerDayAfterGreenStart') {
                    let greendate = localStorage.getItem("onegreenperday");
                    let tempdate = new Date();
                    let year = tempdate.getFullYear();
                    let month = tempdate.getMonth() + 1;
                    let day = tempdate.getDate();
                    let date = day + '-' + month + '-' + year;
                    if (greendate && greendate == date.toString()) {
                      checklist = false;
                    } else {
                      temp = element;
                      localStorage.setItem("onegreenperday", date);
                      checklist = true;
                    }
                  } else {
                    checklist = false;
                  }
                });
              } else {
                temp = '';
                checklist = false;
              }
              if (this.customizing?.triggerPrint != null) {
                this.customizing?.triggerPrint?.forEach((element: any) => {
                  if (element.processStep == 'dependingOnOrderResponse') {
                    if (res?.triggerPrint != null) {
                      this.postPrintFlags(res.triggerPrint);
                    }
                  }
                });
              }
              (temp == undefined) ? temp = '' : '';
              let loadFlag = localStorage.getItem(this.currentuser.userID + 'screen_play');
              if ((loadFlag === 'false' || !loadFlag) && flag === 'green') {
                if (((checklist == true || this.customizing.showInstructions != null) && flag === 'green')) {
                  const go = async () => {
                    for await (let item of checklist_array) {
                      await this.erpattachment(this.active_order);
                      this.goodsReciptFlag = 'afterGreen';
                      await this.getChecklistItems(this.currentuser?.userID, this.active_order?.orderID, item, checklist, 'afterGreen');
                    }
                    ;
                  }
                  await go();
                  this.checkListFormGroup = this.formgroups[0];
                  this.checkListItems = this.checkListItemsList[0];
                  this.formGroupIndex = 0;
                  this.openCloseInstructionModal('1', checklist, 'afterGreen');
                } else {
                  if (this.customizing.showInstructions == 'afterGreen') {
                    await this.erpattachment(this.active_order), this.goodsReciptFlag = 'afterGreen';
                    await this.getChecklistItems(this.currentuser?.userID, this.active_order?.orderID, temp, checklist, 'afterGreen');
                    this.openCloseInstructionModal('3', checklist, 'afterGreen');
                  }
                }
              }
              if (flag == 'yellow' && this.showorangetimer == true) {
                let datas = {
                  "ordernr": res?.orderID,
                  "eventID": res?.eventID,
                }
                localStorage.setItem("DateForOrange", JSON.stringify(datas))
              }
              if (flag == 'blue') {
              } else if (this.currentuser == undefined) {
              } else {

                this.current_temp_order == this.active_order?.orderID
                  ? (this.current_temp_order = res.orderID)
                  : (this.current_temp_order = undefined);
                if (this.active_order?.eventType == '') {
                  this.current_temp_order = this.active_order.orderID;
                  let id = this.active_order.orderID;
                  this.temp_orders = localStorage.getItem(this.currentuser.userID);
                  this.temp_orders = JSON.parse(this.temp_orders);
                  let k = this.temp_orders?.filter((item: any) => {
                    return item.orderID != id
                  });

                  (k && k != undefined) ? localStorage.setItem(this.currentuser.userID, JSON.stringify(k)) : '';
                }

                if (item.orderID != this.active_order?.orderID) {
                  this.orders.forEach((element: any) => {
                    if (element.orderID == item.orderID) {
                      element.eventType = flag;
                    }
                  });
                } else {
                  this.active_order.eventType = flag;
                  this.setOpenOrders(res.openOrders);
                  // console.log(item, 'hjs')
                  this.getOrders(this.currentuser.userID, this.workstationid, item, true, '')

                }
                if (this.active_order.orderID == '' && item.orderID != '') {
                  // console.log(item, 'hjs')
                  this.getOrders(this.currentuser.userID, this.workstationid, item, true, '')
                }
              }
            } else {
              this.toastrService.error(`${res.errorMessage}`, 'Error!');
            }
          },
          (error: any) => {
            this.toastrService.error('Something went wrong!', 'Error!');
          }
        );
        this.order_viewdisable = false;
      }
    } else {
      localStorage.removeItem('active_order');
      this.orders = [];
      this.t = "00:00:00";
      localStorage.removeItem(this.currentuser.userID + 'focus_order');
      localStorage.removeItem(this.currentuser.userID);
      this.lastUserFocus();
      this.addOrder('s', true, '', '')
      // console.log('hjs')
      this.getOrders(this.currentuser.userID, this.workstationid, '', true, '');
    }
  }

  openCloseInstructionModal(flag: string, checklistFlag: any, stepflag: any) {
    if (flag == '1') {
      setTimeout(() => {
        this.modalComponent.openInstructionList(this.modalComponent.instructionspopup, this.payloadd, checklistFlag, stepflag, this.attachments, this.dialogType);
      }, 500);
    } else if (flag == '2') {
      this.formGroupIndex = this.formGroupIndex + 1;
      this.checkListFormGroup.reset({});
      this.modalService.dismissAll();
      if (this.formgroups[this.formGroupIndex]) {
        this.checkListItems = this.checkListItemsList[this.formGroupIndex];
        this.checkListFormGroup = this.formgroups[this.formGroupIndex];
        this.openCloseInstructionModal('1', true, 'afterGreen');
      }
    } else {
      setTimeout(() => {
        this.modalComponent.openInstructionList(this.modalComponent.instructionspopup, this.payload, false, stepflag, this.attachments, this.dialogType);
      }, 500);
    }
  }

  async localHosthandler(item: any) {
    return new Promise((resolve) => {
      setTimeout(() => {
        this.apiService.localhostPost(item).toPromise();
        resolve('success');
      }, 200);
    });
  }

  postPrintFlags(flags: any) {
    flags?.forEach((element: any) => {
      setTimeout(() => {
        this.payload = {
          "orderID": this.active_order.orderID,
          "quantity": null,
          "userID": this.currentuser.userID,
          "processStep": element
        };
        this.apiService.addQuantity(this.payload).subscribe(
          async (res: any) => {
            if (res?.response == 'success') {
              if (res?.printbody != null) {
                for (let i in res.printbody) {
                  await this.localHosthandler(res.printbody[i]);
                }
              }
            }
          },
          (error: any) => {
            console.log(error)
          }
        );
      }, 200);
    });
  }

  setOpenOrders(openOrders: any) {
    if (openOrders.length > 0) {
      openOrders.forEach((element: any) => {
        if (this.active_order?.orderID == element.orderID) {
          this.active_order.matched = true;
          this.active_order.eventType = element.eventType;
          this.active_order.startTime = element.startTime;
          this.active_order.endTime = element.endTime;
          this.active_order.eventID = element.eventID;
          this.active_order.monitorStartTime = element.monitorStartTime;
          this.active_order.monitorEndTime = element.monitorEndTime;
          this.active_order.fkWorkstationID = element.fkWorkstationID;
          this.active_order.userID = element.userID;
          this.active_order.eventDescription = element.eventDescription;
          this.active_order.languageKey = element.languageKey;
          this.active_order.eventDetail = element.eventDetail;
          this.active_order.focus = true;
          localStorage.setItem("active_order", JSON.stringify(this.active_order))
        }
        this.orders.forEach((item: any) => {
          if (item.orderID == element.orderID && item.eventID == element.eventID) {
            item.matched = true;
            item.eventType = element.eventType;
            item.startTime = element.startTime;
            item.endTime = element.endTime;
            item.eventID = element.eventID;
            item.monitorStartTime = element.monitorStartTime;
            item.monitorEndTime = element.monitorEndTime;
            item.fkWorkstationID = element.fkWorkstationID;
            item.userID = element.userID;
            item.eventDescription = element.eventDescription;
            item.eventDetail = element.eventDetail;
            item.languageKey = element.languageKey;
          }
        });

      });
      localStorage.setItem("orders", JSON.stringify(this.orders));
      this.start(this.active_order, this.active_order?.eventType);
    }
  }

  clearGreenData() {
    localStorage.removeItem('green_eventdata');
    this.green_eventdata = undefined;
  }

  async onSubmit_event() {
    this.modalService.dismissAll();
    if (this.activityForm.value.event == 'close') {
      await this.stop(this.active_order, 'close', false);
      await this.stop(this.green_eventdata, 'green', true);
      this.green_eventdata = undefined;
      let eventDescp: any = localStorage.getItem("green_subcat");
      eventDescp = JSON.parse(eventDescp);
      (eventDescp) ? eventDescp : eventDescp = "working on the order";
      await this.createOrder('green', eventDescp, this.active_order);
      this.green_screen = true;
      if (environment.Deactivate_timer == true) {
        this.start(this.active_order, 'green');
      }
      localStorage.removeItem("green_subcat");
      localStorage.removeItem("green_eventdata");
    } else if (this.activityForm.value.event == 'yellow') {
      this.screen_status = false;
      this.downtime_flag = true;
      this.workinghours_flag = false;
      this.unplanned_flag = false;
      this.reports_flag = false;
      this.project_flag = false;
    } else {
      this.screen_status = false;
      this.downtime_flag = false;
      this.workinghours_flag = false;
      this.unplanned_flag = true;
      this.reports_flag = false;
      this.project_flag = false;
    }
    this.activityForm.reset({});
  }

  open(content: any) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  openInstructionList(instructions: any) {
    this.modalService.open(instructions, { modalDialogClass: 'instructions' });
    const modal: any = document.querySelector("ngb-modal-window");
    modal.style.overflow = "hidden";
  }

  openVerticallyCentered(content: any) {
    this.modalService.open(content, { centered: true });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  getCategories(userid: any) {
    this.apiService.getcategories(userid).subscribe(
      (res: categories) => {
        if (res) {
          this.categories = res.yellow;
          this.redcategories = res.red;
          this.bluecategories = res.blue;
          this.report_blue = res.level2.blue;
          let greenCategories: any = res.green;
          this.green_categories = greenCategories?.filter((item: any) => {
            return (item.hidden != '1' && item.text != null)
          });
          localStorage.setItem('categories', JSON.stringify(this.categories));
          localStorage.setItem(
            'redcategories',
            JSON.stringify(this.redcategories)
          );
          localStorage.setItem(
            'bluecategories',
            JSON.stringify(this.bluecategories)
          );
          (this.report_blue?.length > 0 && this.report_blue != undefined) ? localStorage.setItem('reportblue', JSON.stringify(this.report_blue)) : '';
          if (this.allow_greensubcat) {
            // this.green_categories = res.green;
            let flag;
            this.green_categories?.forEach((element: any) => {
              if (element.extraLevel == null) {
                flag = true;
              }
            });
            flag
              ? localStorage.setItem(
                'greencategories',
                JSON.stringify(this.green_categories)
              )
              : '';
          }
        } else {
          localStorage.setItem('categories', JSON.stringify(this.categories));
          localStorage.setItem(
            'redcategories',
            JSON.stringify(this.redcategories)
          );
          localStorage.setItem(
            'bluecategories',
            JSON.stringify(this.bluecategories)
          );
          this.toastrService.error('No categories!', 'Error!');
        }
      },
      (error: any) => {
        this.toastrService.error('Something went wrong!', 'Error!');
      }
    );
  }
  setIntervalCommmon() {
    let seconds = 0;
    this.intervall = setInterval(() => {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;
      let sec = remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds
      let min = minutes < 10 ? '0' + minutes : minutes
      let hour = hours < 10 ? '0' + hours : hours
      this.ordercounter = hour + ':' + min + ':' + sec
      seconds++;
    }, 1000);
  }

  async set_flag(status: any, content: any) {
    this.obj = undefined;
    this.clicks = false;
    this.obj = undefined;
    localStorage.setItem('active_order', JSON.stringify(this.active_order));
    if (status == 'yellow') {
      this.screen_status = false;
      this.downtime_flag = true;
      this.workinghours_flag = false;
      this.unplanned_flag = false;
      this.reportsonly_flag = false;
      this.reports_flag = false;
      this.project_flag = false;
      this.workingon_flag = false;
    } else if (status == 'red') {
      this.downtime_flag = false;
      this.screen_status = false;
      this.workinghours_flag = false;
      this.unplanned_flag = true;
      this.reports_flag = false;
      this.reportsonly_flag = false;
      this.project_flag = false;
      this.workingon_flag = false;
    } else if (status == 'screen') {
      this.downtime_flag = false;
      this.screen_status = true;
      this.workinghours_flag = false;
      this.unplanned_flag = false;
      this.reportsonly_flag = false;
      this.reports_flag = false;

      this.project_flag = false;
      this.greencat_flag = false;
      this.workingon_flag = false;
    } else if (status == 'blue') {
      this.screen_status = false;
      this.downtime_flag = false;
      this.workinghours_flag = false;
      this.unplanned_flag = false;
      this.reports_flag = false;
      this.reportsonly_flag = true;
      this.project_flag = false;
      this.workingon_flag = false;
    } else if (status == 'white') {
      this.screen_status = false;
      this.downtime_flag = false;
      this.workinghours_flag = false;
      this.unplanned_flag = false;
      this.reports_flag = true;
      this.reportsonly_flag = false;
      this.project_flag = false;
      this.workingon_flag = false;
    } else if (status == 'workinghours_flag') {
      this.screen_status = false;
      this.reportsonly_flag = false;
      this.downtime_flag = false;
      this.workinghours_flag = true;
      this.unplanned_flag = false;
      this.reports_flag = false;
      this.project_flag = false;
      this.workingon_flag = false;
      this.order_numbers.forEach((element: any) => {
        element.focus = 'false';
      });
    } else if (status == 'green') {

      this.ordercounter = '00:00:00';
      this.isShow = true;
      this.currentOrderColor = status;
      this.setIntervalCommmon()
      if (this.green_categories?.length > 0) {
        this.workingon_flag = true;
        this.screen_status = false;
        this.reportsonly_flag = false;
        this.downtime_flag = false;
        this.workinghours_flag = false;
        this.unplanned_flag = false;
        this.reports_flag = false;
        this.project_flag = false;
      } else {
        this.workingon_flag = false;
        this.green_eventdata;
        for (let j = 0; j <= this.orders.length - 1; j++) {
          let flag;
          if (
            this.orders[j].eventType == 'green' &&
            this.orders.length > 1 &&
            this.active_order.orderID != this.orders[j].orderID
          ) {
            this.green_eventdata = this.orders[j];
            flag = true;
            break;
          } else {
            flag = false;
          }
        }
        if (this.green_eventdata) {
          localStorage.setItem(
            'green_eventdata',
            JSON.stringify(this.green_eventdata)
          );
          this.open(content);
        } else {
          this.disablegreen = true;
          if (this.active_order.eventType !== '') {
            await this.stop(this.active_order, 'green', false);
          }
          await this.createOrder(status, 'working on the order', this.active_order);
          this.green_screen = true;
          // this.reports_flag = true;
          // this.screen_status = false;
        }
      }
    } else {
      this.workingon_flag = false;
      this.screen_status = false;
      this.downtime_flag = false;
      this.workinghours_flag = false;
      this.unplanned_flag = false;
      this.reports_flag = false;
      this.reportsonly_flag = false;
      this.project_flag = true;
    }
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  async logout() {
    let lastlogin: any = localStorage.getItem("LastLogin");
    let greenperday: any = localStorage.getItem("onegreenperday");
    localStorage.clear();
    this.currentuser = undefined;
    await this.workstation_orders?.forEach(async (element: any) => {
      if (element.eventType == 'green') {
        await this.stop(element, 'green', false);
        this.active_order = element;
        const data = {
          eventDescription: '16',
          value: '16',
          userID: element.userID,
        };
        await this.createOrder('grey', data, this.active_order);
      }
    });
    localStorage.setItem("LastLogin", lastlogin);
    (greenperday) ? localStorage.setItem("onegreenperday", greenperday) : '';
    this.workstation_orders = [];
    this.router.navigate([base_routes.LOGIN]);
  }

  async controlStatus() {
    if (!this.user_screen) {
      this.control = !this.control;
      if (this.control) {
        localStorage.setItem(this.currentuser.userID + 'screen_play', 'true');
        for (const element of this.orders) {
          if (element.eventType == 'green') {
            this.active_order = element;
            await this.stop(element, 'green', false);
            await this.createOrder('yellow', '6', this.active_order);
          }
        }
      } else {
        localStorage.setItem(this.currentuser.userID + 'screen_play', 'false');
        for (const element of this.orders) {
          if (element.eventType == 'yellow' && element.eventDetail == '6') {
            this.active_order = element;
            await this.stop(element, 'yellow', false);
            await this.createOrder('green', 'working on the order', this.active_order);
          }
        }
      }
      this.getusericon(this.active_users);
    }
  }

  Onnaviage() {
    this.screen_status = false;
    this.project_flag = true;

  }

  setnavigation() {
    if (this.reports_flag == false) {
      this.obj = {
        'screen_status': this.screen_status,
        'downtime_flag': this.downtime_flag,
        'workinghours_flag': this.workinghours_flag,
        'unplanned_flag': this.unplanned_flag,
        'reports_flag': this.reports_flag,
        'project_flag': this.project_flag
      }
    }

    this.screen_status = false;
    this.downtime_flag = false;
    this.workinghours_flag = false;
    this.unplanned_flag = false;
    this.reports_flag = true;
    this.reportsonly_flag = false;
    this.project_flag = false;
  }
  previousScreen() {
    this.screen_status = this.obj?.screen_status
    this.downtime_flag = this.obj.downtime_flag;
    this.workinghours_flag = this.obj.workinghours_flag;
    this.unplanned_flag = this.obj.unplanned_flag;
    this.reports_flag = this.obj.reports_flag;
    this.project_flag = this.obj.project_flag;
  }
  SelectedCategory: any;
  dropdown_values: any;
  placeholder: any;
  values_b: any;
  splitted_items: any = [];

  popupform: any;
  async setFormFields(item: any, eventType: any) {
    this.currentEventtype = eventType;
    this.SelectedCategory = item;

    this.dropdown_values = [];
    this.placeholder = [];
    this.values_b = [];

    if (item.extraLevel != null && item.extraLevel != "") {
      if (item.extraLevel.includes('mailto') && item.extraLevel.includes('|')) {
        let mail_flag;
        this.splitted_items = item.extraLevel.split('|');
        var format = /[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g;
        if (this.splitted_items?.length > 0) {
          this.splitted_items.forEach((element: any) => {
            if (!format.test(element)) {
              this.placeholder.push(element)
              const fields = this.fb.group({
                [element]: []
              })
            } else {
              mail_flag = true;
            }
          });
        }
        if (mail_flag == true) {
          this.splitted_items.forEach((element: any) => {
            var arr_Str = element.split(/[<>]/);
            arr_Str.forEach((z: any) => {
              this.values_b.push(z);
            });
            this.values_b.forEach((y: any) => {
              var regex = /^[A-Za-z0-9&; ]+$/
              var isValid = regex.test(y);
              if (isValid && y != '' && y != ';') {
                let t = y.substring(0, y.indexOf(" &"));
                t = (t == '') ? y : t;
                let flag;
                flag = new RegExp("\\b" + "Stuur" + "\\b").test(t);
                (flag == true) ? this.dropdown_values.push(t) : '';
              }
            });
          });
        }
      } else if (!item.extraLevel.includes('mailto') && item.extraLevel.includes(';') || item.extraLevel.includes('|')) {
        let temp = item.extraLevel.split('|');
        temp.forEach((element: any, index: any, object: any) => {
          if (element.includes(";")) {
            this.dropdown_values = element.split(";");
          }
          if (!element.includes(";")) {
            this.splitted_items = element.split(" ");
          }
        });
        if (this.splitted_items?.length > 0) {
          this.splitted_items.forEach((element: any) => {
            this.placeholder.push(element)
            const fields = this.fb.group({
              [element]: []
            })
          });
        }
      } else if (!item.extraLevel.includes('mailto') && !item.extraLevel.includes(';') && item.extraLevel.includes('|')) {
        this.splitted_items = item.extraLevel.split('|');
        if (this.splitted_items?.length > 0) {
          this.splitted_items.forEach((element: any) => {
            this.placeholder.push(element);
            const fields = this.fb.group({
              [element]: []
            })
          });
        }
      } else {
        if (!item.extraLevel.includes('mailto') && !item.extraLevel.includes(';') && !item.extraLevel.includes('|')) {
          this.placeholder.push(item.extraLevel);
          const fields = this.fb.group({
            [item.extraLevel]: []
          })
        }
      }

      for (let item in this.placeholder) {
        this.popupform.addControl(this.placeholder[item], new FormControl('', Validators.required));
      }
      this.open(this.popupmodal);
    }


  }
  submitdropdown() {
    if (this.currentEventtype == 'red') {
      this.unplannedDownTimeComponent.SelectedCategory = this.SelectedCategory;
      this.unplannedDownTimeComponent.placeholder = this.placeholder;
      this.unplannedDownTimeComponent.splitted_items = this.splitted_items;
      this.unplannedDownTimeComponent.values_b = this.values_b;
      this.unplannedDownTimeComponent.popupform = this.popupform;

      this.unplannedDownTimeComponent.submitdropdown()
    }
    else if (this.currentEventtype == 'yellow') {
      this.downTimeComponent.SelectedCategory = this.SelectedCategory;
      this.downTimeComponent.placeholder = this.placeholder;
      this.downTimeComponent.splitted_items = this.splitted_items;
      this.downTimeComponent.values_b = this.values_b;
      this.downTimeComponent.popupform = this.popupform;
      this.downTimeComponent.submitdropdown();
    }

  }

}
