import { Component, ElementRef, Inject, OnInit, TemplateRef, ViewChild,NgZone  } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/services/apiservice.service';
import { Createorder, ERPCustomers, ERPProducts } from 'src/models/erpcustomers';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Customers, Products } from 'src/models/customerlist';
import { DataService } from 'src/services/dataservice.service';
import { DashboardComponent } from 'src/usermodule/dashboard/dashboard.component';
import moment from 'moment';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { deLocale } from 'ngx-bootstrap/locale';
defineLocale('de', deLocale);
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import 'moment/locale/ja';
import 'moment/locale/fr';
import 'moment/locale/de';
import  Hammer from 'hammerjs';
declare var $: any;
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'DD MM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'DD MM YYYY',
  },
};


@Component({
  selector: 'app-orderintake-flow',
  templateUrl: './orderintake-flow.component.html',
  styleUrls: ['./orderintake-flow.component.scss'],
  providers: [
  //  {provide: MAT_DATE_LOCALE, useValue: 'nl-nl'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class OrderintakeFlowComponent implements OnInit {
  @ViewChild('content') private content: TemplateRef<Object>;
  @ViewChild('success') private successPopup: TemplateRef<Object>;
  @ViewChild('elem') myInput: ElementRef<HTMLInputElement>;
  @ViewChild('reference') referenceInput: ElementRef<HTMLInputElement>;
  @ViewChild('dpInput') dpInput: ElementRef<HTMLInputElement>;
  @ViewChild('readyInput') readyInput: ElementRef<HTMLInputElement>;
  CustomerScreen: boolean = false;
  ProductionScreen: boolean = false;
  EditProductionScreen: boolean = false;
  CustomerList: Customers[];
  SearchText: string;
  customerId: string = '';
  selectedCustomer: Customers;
  productForm: any;
  productList: any;
  enableNext: boolean = false;
  isClickedOnce:boolean = false;
  orderLineNo: number = 1;
  orderLineList: { item: any, productId: string, quantity: any, description: string, pallets: any, batch_codes: any }[] = [];
  // orderLineList: { item: any, productId: string, quantity: any, description: string,pallets:any,batch_codes:any }[] =
  //   [{ item: 10, productId: '', quantity: 1, description: '',pallets:1,batch_codes:'' }];
  itemNumber: number;
  productionOrderForm: any;
  createdOrder: Createorder;
  orderData: any;
  editOption: any;
  splitForm: any;
  currentQuantity: string;
  index: any;
  customizing: any;
  statusFlag: string;
  openedId: string = '';
  constructor(
    private ngZone: NgZone,
    private DashboardComponent: DashboardComponent,
    private modalService: NgbModal,
    private apiservice: ApiService,
    public fb: UntypedFormBuilder,
    private DataService: DataService,
    private _adapter: DateAdapter<any>,
    @Inject(MAT_DATE_LOCALE) private _locale: string,
  ) {
    this.customizing = localStorage.getItem("customizing");
   
    (this.customizing) ? this.customizing = JSON.parse(this.customizing) : '';
    this.getCustomers();
    this.productForm = this.fb.group({
      item: [10],
      productId: ['', [Validators.required]],
      measurement: [''],
      quantity: [1, [Validators.required]],
      description: [''],
      pallets: ['']
    });
    this.productionOrderForm = this.fb.group({
      pallets: ['', [Validators.required]],
      deliveryDate: ['', [Validators.required]],
      readyDate: ['', [Validators.required]],
      customerRef:['']
    });
    this.splitForm = this.fb.group({
      index: [],
      quantity: [''],
      split: ['', [Validators.required]],
    });
  }
  
  setLocaleDatepicker(item:string) : void
  {
   if(item === 'du')
   {
     this._locale = 'nl-nl';
   }else{
     this._locale = item;
   }
   this._adapter.setLocale(this._locale);
  }

  openAccordion(event : any){
    this.openedId = event.target.innerText.split(' ')[0];
    this.enableNext = true ;
    this.customerId =  this.openedId ?? '';
    this.getProductsByCustomerId();
   }
  
  inputFocused(item:any){
    this.myInput.nativeElement.focus();
  }
  handleTouch(event: TouchEvent,flag:string) {
    event.preventDefault();
    this.ngZone.run(() => {
      setTimeout(() => {
        if(flag === '1')
        {
          this.myInput.nativeElement.focus();
        }else if(flag === '2')
        {
          this.dpInput.nativeElement.focus();
        }
        else if(flag === '3')
        {
          this.readyInput.nativeElement.focus();
        }
        else{
          this.referenceInput.nativeElement.focus();
        }
       }, 500);
    });
  }

  async ngOnInit() {
    let user : any = localStorage.getItem('current_user');
    (user) ? (user = JSON.parse(user)) : '';
    if(user.language)
    {
      this.setLocaleDatepicker(user.language)
    }
    $(document).ready( () => {
      var referenceId : any = document.getElementById('#referenceId');
      var hammerreferenceId = new Hammer(myInput);
      var myInput : any = document.getElementById('#palletId');
      var hammer = new Hammer(myInput);
      hammerreferenceId.on('touchend tap touchstart click', function() {
        setTimeout(() => {
          referenceId.focus();
        }, 1000);
      });
      hammer.on('touchend tap touchstart click', function() {
        setTimeout(() => {
          myInput.focus();
        }, 1000);
      });
        $('#palletId').on('touchend', () => {
          setTimeout(() => {
            $(this).focus();
          }, 1000);
        });
      $('.next-button').on("click tap touchend", ((e:any) => {
        this.openOrderLinePopup(this.content,null)
      }));
  });
    // this.DataService.currentMessage.subscribe((item:any) =>
    // {
    // this.setLocaleDatepicker(item);
    // });
    this.DataService.language.subscribe((data:string) => {
      this.setLocaleDatepicker(data);
    });
    this.orderData = await this.DataService.getOrder();
    await this.DataService.resetOrder();
    if (this.orderData && this.orderData?.customerID) {
      this.customerId = this.orderData.customerID;
      await this.getProductsByCustomerId();
      this.orderLineList = [];
      for (let i = 0; i <= this.orderData?.orderlines.length - 1; i++) {
        let filterValue = this.productList?.filter((e: Products) => e.ProductID === this.orderData.orderlines[i].productID);
        this.orderLineList.push({
          item: this.orderData.orderlines[i].lineID,
          productId: this.orderData.orderlines[i].productID,
          quantity: this.orderData.orderlines[i].quantity,
          description: (filterValue[0]?.Description) ? (filterValue[0].Description) : "",
          pallets: (this.orderData.orderlines[i].pallets) ? this.orderData.orderlines[i].pallets : "",
          batch_codes: (this.orderData.orderlines[i].batch_codes) ? this.orderData.orderlines[i].batch_codes : "",
        });
      }
      let data = {
        Klantnaam: this.orderData.customerName,
        CustomerID: this.orderData.customerID,
        Streetname: "",
        Zipcode: "",
        batch_on_orderline: this.orderData?.batch_on_orderline,
        batch_prefix: "",
        Country: ""
      };
      let temp = this.orderData.dueDate.split('-');
      let t = { date: parseInt(temp[0]), month: parseInt(temp[1]) - 1, year: parseInt(temp[2]) };
      let readyDate = this.orderData?.readyDate?.split('-');
      let m = { date: parseInt(readyDate[0]), month: parseInt(readyDate[1]) - 1, year: parseInt(readyDate[2]) }
      this.selectedCustomer = data;
      this.productionOrderForm.patchValue({
        pallets: this.orderData.numberOfPallets,
        customerRef: this.orderData.customerReference,
        deliveryDate: moment(t),
        readyDate: moment(m)
      });
      this.ProductionScreen = true;
      this.CustomerScreen = !this.ProductionScreen;
    } else {
      this.orderData = undefined;
      this.CustomerScreen = true;
      this.ProductionScreen = !this.CustomerScreen;
    }
  }

  private getCustomers(): void {
    this.apiservice.getcustomers().subscribe(async (res: ERPCustomers) => {
      if (res.response === 'success') {
        this.CustomerList = res?.result;
      }
    });
  }

  openModal() {
    localStorage.setItem("openorderpopup", "true");
    if (this.orderData?.orderID) {
      this.orderData = undefined;
    }
    this.DashboardComponent.OrderIntakeScreen = false;
    this.DashboardComponent.status_screen = true;
  }

  openOrderLinePopup(content: any, index: any) {
    this.editOption = index;
    if (index != null) {
      this.itemNumber = this.orderLineList[index].item;
      this.productForm.patchValue(this.orderLineList[index]);
      if (this.productForm.value?.productId != '' && this.productForm.value?.productId) {
        this.selectProduct();
      } else {
        this.productForm.patchValue({ productId: this.productList[0]?.ProductID });
        this.selectProduct();
      }
    } else {
      if (!this.orderLineList || this.orderLineList?.length == 0) {
        this.itemNumber = 10;
        this.productForm.patchValue({ quantity: 1, productId: this.productList[0]?.ProductID });
        this.selectProduct();
      } else {
        this.itemNumber = parseInt(this.orderLineList[this.orderLineList.length - 1].item) + 10;
        this.productForm.patchValue({ quantity: 1, productId: this.productList[0]?.ProductID });
        this.selectProduct();
      }
    }
    setTimeout(() =>{
      this.modalService.open(content, { modalDialogClass: 'orderline' });
    },500)
  }
  
  submitOrder(sucess: any) {
    if (this.productionOrderForm.valid) {
      const products: any[] = [];
      if (this.orderLineList.length > 0) {
        this.orderLineList.forEach((element: any) => {
          (element.productId) ? products.push({ "lineID": element.item, "productID": element.productId, "quantity": element.quantity, "pallets": element.pallets }) : '';
        });
      }
      let year = new Date(this.productionOrderForm.value?.deliveryDate).getFullYear();
      let month = new Date(this.productionOrderForm.value?.deliveryDate).getMonth() + 1;
      let day = new Date(this.productionOrderForm.value?.deliveryDate).getDate();
      let date = day + '-' + month + '-' + year;

      let readyDateyear = new Date(this.productionOrderForm.value?.readyDate).getFullYear();
      let readyDatemonth = new Date(this.productionOrderForm.value?.readyDate).getMonth() + 1;
      let readyDateday = new Date(this.productionOrderForm.value?.readyDate).getDate();
      let readyDate = readyDateday + '-' + readyDatemonth + '-' + readyDateyear;
      let payload: any = {
        "customerID": this.selectedCustomer.CustomerID,
        "numberOfPallets": this.productionOrderForm.value.pallets,
        "customerReference": this.productionOrderForm.value.customerRef,
        "deliveryDate": date,
        "readyDate": readyDate
      };
      (products.length > 0) ? (payload.orderlines = [], payload.orderlines = (products)) : '';
      if (this.orderData && this.orderData?.customerID) {
        payload.orderID = this.orderData.orderID;
      }
      this.apiservice.createERPOorder(payload).subscribe(
        (res: any) => {
          if (res.response === 'success') {
            if (this.statusFlag != 'split') {
              this.createdOrder = res;
              this.productionOrderForm.reset({});
              this.productForm.reset({});
              this.modalService.open(sucess, { centered: true });
            } else {
              this.statusFlag = '';
              this.splitForm.reset({});
            }
          }
        },
        (error: any) => {
          console.log(error)
        });
    }
  }

  openDeleteOrderModal(deleteModal: any) {
    this.modalService.open(deleteModal, { modalDialogClass: 'orderline' },);
  }

  delete(orderClosed: any) {
    this.modalService.dismissAll();
    this.apiservice.deleteERPOorder({ orderID: this.orderData.orderID }).subscribe((res: any) => {
      if (res.response === 'success') {
        this.modalService.open(orderClosed, { centered: true });
      }
    }, (error) => {
      console.log(error);
    });
  }

  BackNavigate() {
    if (this.ProductionScreen) {
      this.CustomerScreen = true;
      this.enableNext = false;
      this.EditProductionScreen = !this.ProductionScreen;
      this.ProductionScreen = !this.ProductionScreen;
      this.resetOrderList();
    }
  }

  navigateTo(): void {
    if (this.CustomerScreen && this.customerId !== '') {
      this.selectedCustomer = this.filterCustomerById()[0];
      this.ProductionScreen = true;
      this.EditProductionScreen = false;
      this.CustomerScreen = !this.CustomerScreen;
    }
  }

  syncERP(): void {
    this.apiservice.syncingOrder().subscribe((res: ERPCustomers) => {
      if (res.response === 'success') {
        this.CustomerList = res?.result;
      }
    }, (error) => {
      console.log(error);
    });
  }

  customerInfo(event: any): void {
    this.enableNext = event.nextState ? true : false;
    this.customerId = event.nextState ? event.panelId : '';
    this.getProductsByCustomerId();
  }

  async selectProduct() {
    const filterValue = this.productList?.filter((e: Products) => e.ProductID === this.productForm.value.productId);
    if (filterValue?.length === 1) {
      this.productForm.patchValue({ description: filterValue[0]?.Description,measurement:filterValue[0]?.UOM });
    }
  }

  saveProductQuantity(): void {
    if (this.productForm.value.quantity > 0) {
      this.modalService.dismissAll();
      if (this.editOption == null) {
        this.productForm.value.item = this.itemNumber;
        if (this.orderLineList?.length === 1) {
          if (this.orderData?.customerID) {
            this.orderLineList.push(this.productForm.value);
          } else {
            if (this.orderLineList[0]?.productId === '') {
              this.orderLineList[0] = this.productForm.value;
            } else {
              this.orderLineList.push(this.productForm.value);
            }
          }
        } else {
          if (this.orderLineList?.length > 1) {
            this.orderLineList.push(this.productForm.value);
          } else {
            this.orderLineList[0] = this.productForm.value;
          }
        }
      } else {
        this.orderLineList[this.editOption] = this.productForm.value;
      }
      this.orderLineNo += 1;
    }
  }

  private resetOrderList(): void {
    this.orderLineList = [];
    this.orderLineNo = 1;
    this.orderLineList[this.orderLineNo - 1] = { item: 10, productId: '', quantity: 0, description: '', pallets: '', batch_codes: '' };
  }

  private filterCustomerById(): Customers[] {
    return this.CustomerList.filter((e: Customers) => e.CustomerID === this.customerId);
  }

  async getProductsByCustomerId() {
    const payload = { customer: this.customerId };
    let response = await this.apiservice.getERPProductsByCustomerId(payload).toPromise();
    this.productList = (response as any)?.result;
  }
  openChecklist(checklist: any) {
    this.modalService.open(checklist, { centered: true });
  }
  openInstructionList(instructions: any) {
    this.modalService.open(instructions, { modalDialogClass: 'instructions' });
  }

  openEditOrder(editorder: any) {
    this.modalService.open(editorder, { modalDialogClass: 'orderline' },);
  }
  openSplitOrder(splitorder: any, index: any) {
    this.index = index;
    this.splitForm.patchValue({
      index: index
    })
    this.itemNumber = this.orderLineList[index].item;
    this.currentQuantity = this.orderLineList[index].quantity;
    this.modalService.open(splitorder, { modalDialogClass: 'orderline' },);
  }

  async splitOrder() {
    if (this.splitForm.valid && parseInt(this.splitForm.value.split) > 0) {
      let item = (parseInt(this.currentQuantity)) - (parseInt(this.splitForm.value.split));
      if (item > 0) {
        this.statusFlag = 'split';
        await this.deleteInline();
        let items = parseInt(this.orderLineList[this.orderLineList.length - 1].item);
        this.orderLineList.push({ "item": (items + 10), "productId": this.orderLineList[this.index].productId, "quantity": item, pallets: this.orderLineList[this.index].pallets, description: this.orderLineList[this.index].description, batch_codes: this.orderLineList[this.index].batch_codes });
        this.orderLineList.push({ "item": (items + 20), "productId": this.orderLineList[this.index].productId, "quantity": this.splitForm.value.split, pallets: this.orderLineList[this.index].pallets, description: this.orderLineList[this.index].description, batch_codes: this.orderLineList[this.index].batch_codes });
        this.orderLineList.splice(this.index, 1);
        this.submitOrder(this.successPopup);
      }
    }
  }

  async deleteInline() {
    if (this.orderData?.orderID) {
      const products: any[] = [];
      products.push({ "lineID": this.orderLineList[this.index].item, "productID": this.orderLineList[this.index].productId, "quantity": this.orderLineList[this.index].quantity, pallets: this.orderLineList[this.index].pallets });
      let data = {
        "orderID": this.orderData?.orderID,
        "orderlines": products
      }
      let res: any = await this.apiservice.deleteERPOorder(data).toPromise();
      if (res.response === 'success' && this.statusFlag != 'split') {
        this.orderLineList.splice(this.index, 1);
      }
    } else {
      this.orderLineList.splice(this.index, 1);
    }
    this.modalService.dismissAll();
  }

  openDeleteOrder(deletorder: any, index: any) {
    this.itemNumber = this.orderLineList[index].item;
    this.index = index;
    this.modalService.open(deletorder, { modalDialogClass: 'orderline' },);
  }
}