import { Component, OnInit, TemplateRef, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, Validators, FormArray, FormControl } from "@angular/forms";
import { ApiService } from 'src/services/apiservice.service';
import { ToastrService } from 'ngx-toastr';
import { LandingPageComponent } from '../landing-page/landing-page.component';
import { DashboardComponent } from 'src/usermodule/dashboard/dashboard.component';
import { DataService } from 'src/services/dataservice.service';
import { NgbModal, ModalDismissReasons, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})

export class ReportsComponent implements OnInit {
  @ViewChild("textboxmodal") private contentRef: TemplateRef<Object>;
  @ViewChild("content") private assistanceRef: TemplateRef<Object>;
  @ViewChild("popupformmodal") private popupmodal: TemplateRef<Object>;
  @Input() reportOnly: boolean = false;
  requestForHelp: boolean
  downtimeform: any;
  currentuser: any;
  categories: any;
  closeResult: string;
  dropdown_values: any = [];
  categories_flag: boolean = false;
  reports: any;
  alert_flag: boolean = false;
  workstationid: any;
  customerID: any;
  notes: any;
  placeholder: any = [];
  dropdownform: any;
  role: any;
  dropdown_items: any = [];
  popupform: any;
  splitted_items: any;
  values_b: any = [];
  BlueEventID: any;
  SelectedCategory: any;
  OpenFromGreen: boolean;
  unsubscribe: any;
  constructor(private DataService: DataService, private config: NgbModalConfig, private ngxService: NgxUiLoaderService, private modalService: NgbModal, private myService: DataService, private toastrService: ToastrService, private apiservice: ApiService, private router: Router, private fb: UntypedFormBuilder, public LandingPageComponent: LandingPageComponent, public DashboardComponent: DashboardComponent) {
    this.currentuser = localStorage.getItem('current_user');
    this.workstationid = localStorage.getItem("workstationID");
    this.customerID = localStorage.getItem('customerID');
    this.currentuser = JSON.parse(this.currentuser);
    this.downtimeform = this.fb.group({
      info: ['', [Validators.required]],
    })
    this.requestForHelp = DashboardComponent.requestforhelp_flag
    this.categories = localStorage.getItem("bluecategories");
    this.notes = localStorage.getItem("notes");
    this.notes = JSON.parse(this.notes);
    this.reports = localStorage.getItem("reportblue");
    this.categories = JSON.parse(this.categories);
    this.dropdownform = this.fb.group({
      additional_info: ['', [Validators.required]],
    });
    this.config.backdrop = 'static';
    this.config.keyboard = false;
    this.popupform = this.fb.group({
      mail: ['', []],
    })
    this.role = localStorage.getItem("role");
    if (this.categories) {
      this.categories_flag = true;
      (this.reports) ? this.reports = JSON.parse(this.reports) : '';
    }
    if (this.DashboardComponent.requestforhelp_flag) {
      this.submit()
    }
    this.unsubscribe = this.myService.castValue.subscribe((value: boolean) => {
      if (value) {
        this.OpenFromGreen = value;
        (this.categories.length > 0) ? this.setFormFields(this.categories[0]) : '';
      }
    })
  }

  ngOnDestroy() {
    this.unsubscribe.unsubscribe();
  }

  async submitdropdown() {
    if (this.popupform.valid) {
      this.createorder('blue', this.SelectedCategory)

      // this.modalService.dismissAll();
    }
    else {
      this.toastrService.error('Please select any value!', 'Error!');
    }
  }

  ngOnInit() {
    let active_order: any = JSON.parse(localStorage.getItem('active_order') || '{}')
    if ((active_order.eventType == 'green' || active_order.eventType == 'grey' || active_order.eventType == '') && this.reportOnly) {
      this.categories_flag = true;
      this.alert_flag = false;
    }
    else if (!this.reportOnly) {
      this.categories_flag = true;
      this.alert_flag = false;
    }
    else {
      this.categories_flag = false;
      this.alert_flag = true;
    }
  }

  setFormFields(item: any) {
    this.SelectedCategory = item;
    this.DataService.sendValue(false);
    if (this.SelectedCategory.preventSkipExtraLevel == '1') {
      this.config.backdrop = 'static';
      this.config.keyboard = false;
    } else {
      this.config.backdrop = true;
      this.config.keyboard = true;
    }
    this.dropdown_values = [];
    this.placeholder = [];
    this.values_b = [];
    if (item.extraLevel != null && item.extraLevel != "") {
      if (item.extraLevel.includes('mailto') && item.extraLevel.includes('|')) {
        let mail_flag;
        this.splitted_items = item.extraLevel.split('|');
        var format = /[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g;
        if (this.splitted_items?.length > 0) {
          this.splitted_items.forEach((element: any) => {
            if (!format.test(element)) {
              this.placeholder.push(element)
            } else {
              mail_flag = true;
            }
          });
        }
        if (mail_flag == true) {
          this.splitted_items.forEach((element: any) => {
            var arr_Str = element.split(/[<>]/);
            arr_Str.forEach((z: any) => {
              this.values_b.push(z);
            });
            this.values_b.forEach((y: any) => {
              var regex = /^[A-Za-z0-9&; ]+$/
              var isValid = regex.test(y);
              if (isValid && y != '' && y != ';') {
                let t = y.substring(0, y.indexOf(" &"));
                t = (t == '') ? y : t;
                let flag;
                flag = new RegExp("\\b" + "Stuur per mail" + "\\b").test(t);
                (flag == true) ? this.dropdown_values.push(t) : '';
              }
            });
          });
        }
      } else if (!item.extraLevel.includes('mailto') && item.extraLevel.includes(';') || item.extraLevel.includes('|')) {
        let temp = item.extraLevel.split('|');
        temp.forEach((element: any, index: any, object: any) => {
          if (element.includes(";")) {
            this.dropdown_values = element.split(";");
          }
          if (!element.includes(";")) {
            this.splitted_items = element.split(" ");
          }
        });
        if (this.splitted_items?.length > 0) {
          this.splitted_items.forEach((element: any) => {
            this.placeholder.push(element)
          });
        }
      } else if (!item.extraLevel.includes('mailto') && !item.extraLevel.includes(';') && item.extraLevel.includes('|')) {

        this.splitted_items = item.extraLevel.split('|');
        if (this.splitted_items?.length > 0) {
          this.splitted_items.forEach((element: any) => {
            this.placeholder.push(element);
          });
        }
      } else {
        if (!item.extraLevel.includes('mailto') && !item.extraLevel.includes(';') && !item.extraLevel.includes('|')) {
          this.placeholder.push(item.extraLevel);
        }
      }
      for (let item in this.placeholder) {
        this.popupform.addControl(this.placeholder[item], new FormControl('', Validators.required));
      }
      (this.OpenFromGreen) ? (setTimeout(() => {
        this.openmd(this.popupmodal);
      }, 1000)) : this.openmd(this.popupmodal);

    }
    if (item.extraLevel == null || item.extraLevel == '') {
      this.createorder('blue', this.SelectedCategory);
      if (this.reports?.length > 0) {

        let active_order: any = JSON.parse(localStorage.getItem('active_order') || '{}')
        if (this.reportOnly && active_order.eventType === 'green' || active_order.eventType === 'grey') {
          this.submit();
        }
        else {
          this.navigate();
        }
      }
    }
  }

  submit() {
    this.categories_flag = false;
    this.alert_flag = true;
    // this.modalService.dismissAll();
  }

  open(content: any, report: any, item: any) {
    this.dropdown_values = [];
    if (item.extraLevel == null) {
      this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    } else if (!item.extraLevel.includes(";")) {
      this.openmd(this.contentRef);
      this.placeholder.push(item.extraLevel);
    } else {
      if (this.reports) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      } else {
        this.navigate();
      }
    }
    this.createorder('blue', item);
  }

  openmd(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      for (let item in this.placeholder) {
        this.popupform.removeControl(this.placeholder[item], new FormControl('', Validators.required));
      }
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      for (let item in this.placeholder) {
        this.popupform.removeControl(this.placeholder[item], new FormControl('', Validators.required));
      }
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  async createorder(flag: any, item: any) {
    var unixtimestamp = Math.floor((new Date).getTime() / 1000);
    let datas = {
      orderNr: (this.DashboardComponent?.active_order) ? this.DashboardComponent.active_order.orderID : this.LandingPageComponent.active_order.orderID,
      customerRef: "",
      orderkey: "",
      userID: this.currentuser.userID,
      actionType: flag,
      eventDescription: item.text,
      actionDetail: item.value,
      workstationID: this.workstationid,
      monitorStartTime: unixtimestamp
    };
    this.apiservice.addorder(datas).subscribe(
      (res: any) => {
        this.BlueEventID = res.eventID;
        // (this.role === 'office') ? (this.LandingPageComponent.getorders(this.LandingPageComponent.currentuser.userID, this.workstationid, false), this.LandingPageComponent.getnotes()) :
        // (this.DashboardComponent.getOrders(this.DashboardComponent.currentuser.userID, this.workstationid),
        //   this.DashboardComponent.getNotes());
        (this.SelectedCategory?.extraLevel != null && this.SelectedCategory?.extraLevel != '') ? this.PostFormFields() : '';
        if (this.reports?.length > 0 && this.SelectedCategory?.extraLevel != null && this.SelectedCategory?.extraLevel != '') {
          let active_order: any = JSON.parse(localStorage.getItem('active_order') || '{}')
          if (this.reportOnly && active_order.eventType === 'green' || active_order.eventType === 'grey') {

            this.submit();
          }
          else {
            this.navigate();
          }
        }
      }, (error: any) => {
        console.log(error);
      })
  }

  PostFormFields() {
    let data: any = {
      eventID: this.BlueEventID,
      updateFields: []
    };

    for (const [key, value] of Object.entries(this.popupform.value)) {
      if ((value as any).trim() != '') {
        data.updateFields.push({ [key]: value })
      }
    }
    // if (this.popupform.value.mail) {
    //   if (this.popupform.value.mail.includes('mail')) {
    //     (this.popupform.value.mail) ? data.updateFields.push({ mail: this.popupform.value.mail }) : "";
    //   } else if (!this.popupform.value.mail.includes('mail')) {
    //     let key = `blue-${this.SelectedCategory.value}`;
    //     data.updateFields.push({ [key]: this.popupform.value.mail })
    //   } else {
    //   }
    // }

    this.apiservice.addformfield(data).subscribe(
      (res: any) => {
        this.modalService.dismissAll();
        this.ngxService.stop();
        this.downtimeform.reset({});
        if (res.response == 'success') {
        } else {
          this.toastrService.error('Not added!', 'Error!');
        }
      }, (error) => {
      });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  async create_alert(data: any) {
    this.SelectedCategory = undefined;
    this.createorder('blue', data)
    let order = localStorage.getItem("active_order")
    order = JSON.parse(order as any);
    let temp = {
      userID: this.currentuser.userID,
      workstationID: this.workstationid,
      orderNr: (order as any).orderID,
      role: data.value
    };
    this.apiservice.createalert(temp).subscribe(
      (res: any) => {
        this.downtimeform.reset({});
        if (res) {
          localStorage.setItem("blueperson", res);
          this.navigate();
          if (this.role === 'office') {
            this.LandingPageComponent.getnotes();
            this.LandingPageComponent.getorders(this.LandingPageComponent.currentuser.userID, this.workstationid, false)
          } else {
            this.DashboardComponent.getNotes();
            this.DashboardComponent.getOrders(this.DashboardComponent.currentuser.userID, this.workstationid, '', true, '')
          }
        } else {
          this.toastrService.error('Alert is not created!', 'Error!');
        }
      }, (error: any) => {
        console.log(error)
      });
  }

  navigate() {
    this.modalService.dismissAll();
    (this.role === 'office') ? this.LandingPageComponent.obj == undefined ? this.LandingPageComponent.set_flag('screen', '') : this.LandingPageComponent.previousScreen() : this.DashboardComponent.obj == undefined ? this.DashboardComponent.set_flag('screen', '') : this.DashboardComponent.previousScreen();
  }
}
