export interface Language{
    name:string,
    value:string
}
export interface Productdetails{
    ID: string,
    Name: string,
    Descp: string,
}
export interface AvailableUsers {
    null: any,
    active: string,
    displayName: string,
    emailAddress: string,
    enable2FA: string,
    firstName: string;
    fkCustomerID: string;
    fkWorkstationID: string;
    language: string;
    lastName: string;
    normHours: any;
    notificationMedium: any;
    password: any;
    phoneNumber: any;
    role: any;
    timeScheme: string;
    userID: string;
    userType: string;
  }

  export interface Workstation{
    customerID:string,
   customerRef:  string,
   datapointIDs:string | null,
   endTime:string | null
   eventDescription:string | null
   eventDetail:string,
   eventID:string
   eventType:string
   fkWorkstationID:string
   languageKey:string | null,
   monitorEndTime:string | null,
   monitorStartTime:string | null
   orderID:string | null,
   reminderDate:string | null,
   startTime:string | null
   userID:string | null
   }
  
export interface SelectedData
    {
        product_group: string | null,
        allowExecute: string,
        mapping_field:string,
        orderID: string | null,
        materialDescription: string | null,
        Lengte: string,
        Aantal: string,
        zaaglijstID: string,
        ParkedOrder: null,
        Model: null,
        DueDate: string,
        DueDateText: string,
        FGMaterial:string,
        customerName: string,
        NewUnit: string,
        voorraadlocatie: string,
        Sequence: string,
        Motortype: null,
        Bedieningskant: string,
        aantalFG: string,
        breedte_deel: null,
        speciale_uitvoer: null,
        ralnummer: null,
        spoed: null,
        shipmentcode: null,
        KrachtMotor: string,
        zaaglijstNo: string,
        zaaglijstViaEvents: null,
        sortfield:string
    }

    export interface Zaaglist{
            id: string
            zaaglijstNo: string,
            materialDescription:string,
            materialCode: string,
            materialLocation: string,
            materialStock: string,
            errorLog: null,
            fkWorkstationID: string,
            status: string,
            date_loaded: string,
            aantalNieuweLengtes: string,
            spoed: null,
            kardexUitvoer: null,
            zaaglijstInStagnatieUserID: null,
            zaaglijstInUseUserID: null,
            zaaglijstInStagnatieUserName: null,
            zaaglijstInUseUserName: null,
            showOnWorkstation46: null,
            suncircle_showkardexbuttons: boolean,
            suncircle_kardex_button1: number,
            suncircle_kardex_button2: number
    }
  
export interface currentScreen{

        screen_status:boolean
        downtime_flag:boolean
        workinghours_flag:boolean
        unplanned_flag:boolean
        reports_flag:boolean
        project_flag:boolean
}
