import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { DataService } from 'src/services/dataservice.service'
import { LandingPageComponent } from '../landing-page/landing-page.component'
import { DashboardComponent } from 'src/usermodule/dashboard/dashboard.component';
import { ApiService } from 'src/services/apiservice.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModalConfig, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { environment } from '../../environments/environment';
import { ChecklistModalComponent } from '../checklist-modal/checklist-modal.component';
import { Checklist } from '../../models/checklist';
import moment from 'moment';

@Component({
  selector: 'app-unplanned-down-time',
  templateUrl: './unplanned-down-time.component.html',
  styleUrls: ['./unplanned-down-time.component.scss']
})

export class UnplannedDownTimeComponent {
  @ViewChild('instructions') public modalComponent !: ChecklistModalComponent;
  @ViewChild("textboxmodal") private contentRef: TemplateRef<Object>;
  @ViewChild("popupformmodal") private popupmodal: TemplateRef<Object>;
  downtimeform: any;
  currentuser: any;
  categories: any;
  closeResult: string;
  dropdown_values: any = [];
  dropdownform: any;
  values: any = [];
  values_b: any = [];
  array: any = [];
  add_info_modal = false;
  eventDesc: any;
  customerID: any;
  placeholder: any = [];
  hidebutton: boolean;
  role: any;
  popupform: any;
  SelectedCategory: any;
  splitted_items: any = [];
  workstationid: string | null;
  RedEventID: any;
  checklist_flag: any;
  checkListItems: any;
  checkListFormGroup: any;
  active_order: any;
  firstIndexvalue: string;
  public autopopup = false;
  url: string = ''
  @Output() valueChange = new EventEmitter();

  constructor(private ChecklistModalComponent: ChecklistModalComponent,
    private config: NgbModalConfig, private ngxService: NgxUiLoaderService, private modalService: NgbModal, private toastrService: ToastrService, private apiservice: ApiService, public DashboardComponent: DashboardComponent, private router: Router, public fb: UntypedFormBuilder, private myService: DataService, public LandingPageComponent: LandingPageComponent) {
    this.currentuser = localStorage.getItem('current_user');
    this.currentuser = JSON.parse(this.currentuser);
    this.workstationid = localStorage.getItem("workstationID");
    this.customerID = localStorage.getItem('customerID');
    this.categories = localStorage.getItem("redcategories");
    this.categories = JSON.parse(this.categories);
    this.checklist_flag = localStorage.getItem("showchecklist");
    this.config.backdrop = 'static';
    this.config.keyboard = false;
    this.role = localStorage.getItem("role");
    let data: any = localStorage.getItem("active_order")
    data = JSON.parse(data as any);
    this.active_order = { orderID: data.orderID, mapping_field: data.mapping_field };
    this.dropdownform = this.fb.group({
      additional_info: ['', [Validators.required]],
    })
    this.downtimeform = this.fb.group({
      info: ['', [Validators.required]],
    });
    this.popupform = this.fb.group({
      mail: ['', []],
    })
  }


  async setFormFields(item: any) {
    this.SelectedCategory = item;
    this.DashboardComponent.ordercounter = '00:00:00';
    this.DashboardComponent.currentOrderColor = 'red';
    this.DashboardComponent.isShow = true;
    this.DashboardComponent.setIntervalCommmon()
    if (this.SelectedCategory.preventSkipExtraLevel == '1') {
      this.config.backdrop = 'static';
      this.config.keyboard = false;
    } else {
      this.config.backdrop = true;
      this.config.keyboard = true;
    }
    this.dropdown_values = [];
    this.placeholder = [];
    this.values_b = [];
    if (item.extraLevel) {
      this.autopopup = false;
      await this.setCommon(item);
      this.set_flag(this.autopopup);
    }
    else {
      this.autopopup = true
      this.set_flag(this.autopopup);
    }
  }

  async setAutoPopupForm(dynamicExtraLevel: any) {
    let item = this.SelectedCategory;
    item.extraLevel = dynamicExtraLevel ? dynamicExtraLevel : this.SelectedCategory.extraLevel; this.dropdown_values = [];
    this.placeholder = [];
    this.values_b = [];
    await this.setCommon(item)
  }
  setCommon(item: any) {
    if (item.extraLevel != null && item.extraLevel != "") {
      if (item.extraLevel.includes('mailto') && item.extraLevel.includes('|')) {
        let mail_flag;
        this.splitted_items = item.extraLevel.split('|');
        var format = /[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g;
        if (this.splitted_items?.length > 0) {
          this.splitted_items.forEach((element: any) => {
            if (!format.test(element)) {
              this.placeholder.push(element)
              const fields = this.fb.group({
                [element]: []
              })
            } else {
              mail_flag = true;
            }
          });
        }
        if (mail_flag == true) {
          this.splitted_items.forEach((element: any) => {
            var arr_Str = element.split(/[<>]/);
            arr_Str.forEach((z: any) => {
              this.values_b.push(z);
            });
            this.values_b.forEach((y: any) => {
              var regex = /^[A-Za-z0-9&; ]+$/
              var isValid = regex.test(y);
              if (isValid && y != '' && y != ';') {
                let t = y.substring(0, y.indexOf(" &"));
                t = (t == '') ? y : t;
                let flag;
                flag = new RegExp("\\b" + "Stuur" + "\\b").test(t);
                (flag == true) ? this.dropdown_values.push(t) : '';
              }
            });
          });
        }
      } else if (!item.extraLevel.includes('mailto') && item.extraLevel.includes(';') || item.extraLevel.includes('|') && !item.extraLevel.includes('ur')) {

        let temp = item.extraLevel.split('|');
        this.firstIndexvalue = temp
        temp.forEach((element: any, index: any, object: any) => {
          if (element.includes(";")) {
            this.dropdown_values = element.split(";");
          }
          if (!element.includes(";")) {
            this.splitted_items = element.split(" ");
          }
        });
        if (this.splitted_items?.length > 0) {
          this.splitted_items.forEach((element: any) => {
            this.placeholder.push(element)
            const fields = this.fb.group({
              [element]: []
            })
          });
        }

      }
      else if (!item.extraLevel.includes('mailto') && item.extraLevel.includes(';') || (item.extraLevel.includes('|') && item.extraLevel.includes('ur'))) {

        let mail_flag;
        this.splitted_items = item.extraLevel.split('|');
        var format = /[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g;
        if (this.splitted_items?.length > 0) {
          this.splitted_items.forEach((element: any) => {
            if (!format.test(element)) {
              this.placeholder.push(element)
              const fields = this.fb.group({
                [element]: []
              })
            } else {
              mail_flag = true;
            }
          });
        }
        if (mail_flag) {
          let url: any;
          this.splitted_items.forEach((element: any) => {
            var arr_Str = element.split(':');
            url = arr_Str[1]
          })
          this.url = atob(url);;
        }
      }

      else if (!item.extraLevel.includes('mailto') && !item.extraLevel.includes(';') && item.extraLevel.includes('|')) {
        this.splitted_items = item.extraLevel.split('|');
        if (this.splitted_items?.length > 0) {
          this.splitted_items.forEach((element: any) => {
            this.placeholder.push(element);
            const fields = this.fb.group({
              [element]: []
            })
          });
        }
      } else {
        if (!item.extraLevel.includes('mailto') && !item.extraLevel.includes(';') && !item.extraLevel.includes('|')) {
          this.placeholder.push(item.extraLevel);
          const fields = this.fb.group({
            [item.extraLevel]: []
          })
        }
      }

      for (let item in this.placeholder) {
        this.popupform.addControl(this.placeholder[item], new FormControl('', Validators.required));
      }
      this.openmd(this.popupmodal);
    }
    if (item.extraLevel == null || item.extraLevel == '') {
      (this.role === 'office') ? (this.LandingPageComponent.getorders(this.LandingPageComponent.currentuser.userID, this.workstationid, false), this.LandingPageComponent.getnotes()) :
        (this.DashboardComponent.getOrders(this.DashboardComponent.currentuser.userID, this.workstationid, '', false, ''),
          this.DashboardComponent.getNotes());
      (Object.keys(this.popupform?.value).length > 0 || this.popupform?.value.mail != '') ? this.PostFormFields() : this.navigate();
    }

  }

  async set_flag(autopopup: boolean) {
    this.navigate();
    let data: any = localStorage.getItem("active_order")
    data = JSON.parse(data as any);
    this.active_order = { orderID: data.orderID, mapping_field: data.mapping_field };
    let greendata: any = localStorage.getItem("green_eventdata");
    (greendata) ? greendata = JSON.parse(greendata as any) : greendata = undefined;
    let eventDescp: any = localStorage.getItem("green_subcat");
    eventDescp = JSON.parse(eventDescp);
    (eventDescp) ? eventDescp : eventDescp = "working on the order";
    if (this.role == 'office') {
      if (data) {
        await this.LandingPageComponent.stop(data, 'red', false);
        (greendata) ? await this.LandingPageComponent.stop(greendata, 'red', false) : '';

        (greendata) ? await this.createorder('red', this.SelectedCategory, greendata, autopopup) : '';

        (data && greendata) ? await this.LandingPageComponent.createorder('green', eventDescp, data) : '';

        (data && greendata) ? await this.createorder('red', this.SelectedCategory, data, autopopup) : '';
        (data && !greendata) ? await this.createorder("red", this.SelectedCategory, data, autopopup) : '';


      } else {
        (greendata) ? await this.LandingPageComponent.stop(greendata, 'red', false) : '';
        this.LandingPageComponent.active_order = greendata;

        await this.createorder("red", this.SelectedCategory, greendata, autopopup);


        let order: any = localStorage.getItem('darkblueorder');
        order = JSON.parse(order);
        this.apiservice.addorder(order).subscribe(async (res: any) => {
          if (res.response == 'success') {
            this.LandingPageComponent.navigate_cal();
            this.modalService.dismissAll();
            let data = {
              eventID: res.eventID,
              updateFields: [
                {
                  remark: order.remark
                }
              ]
            }
            this.apiservice.addformfield(data).subscribe(async (res: any) => {
            })
            //this.LandingPageComponent.getorders(this.currentuser.userID, this.workstationid)
          }
        });

      }
    } else {
      if (environment.Deactivate_timer == true) {
        (data) ? await this.DashboardComponent.stop(data, 'red', false) : '';
        (greendata) ? (await this.DashboardComponent.stop(greendata, 'red', false)) : '';
        (greendata) ? await this.createorder("red", this.SelectedCategory, greendata, autopopup) : '';


        (data && greendata) ? await this.DashboardComponent.createOrder("green", eventDescp, data) : '';

        (data && !greendata) ? await this.createorder("red", this.SelectedCategory, data, autopopup) : '';


        //  (this.checklist_flag == 'afterGreen') ?
        //  this.getChecklistItems(this.currentuser?.userID, data?.orderID, this.checklist_flag) : '';
      }
    }
    localStorage.removeItem("green_eventdata");
    localStorage.removeItem('darkblueorder');
  }


  async createorder(flag: any, item: any, order: any, dynamicpopup: boolean) {
    console.log(flag, 'flag')
    console.log(order, 'order')
    var unixtimestamp = Math.floor((new Date).getTime() / 1000);
    let datas = {
      orderNr: order.eventType === 'green' ? order.customerRef : (this.DashboardComponent?.active_order) ? this.DashboardComponent.active_order.orderID : this.LandingPageComponent.active_order.orderID,
      // customerRef: order.orderID ? order.orderID : order.customerRef ? order.customerRef : '',
      customerRef: (flag !== 'green' && order.eventType == '') ? order.orderID : order.customerRef ? order.customerRef : '',
      // orderkey: (this.DashboardComponent?.active_order?.mapping_field) ? this.DashboardComponent.active_order?.mapping_field : this.LandingPageComponent.active_order?.mapping_field ? this.LandingPageComponent.active_order?.mapping_field : '',
      orderkey: '',
      userID: this.currentuser.userID,
      actionType: flag,
      eventDescription: item.text,
      actionDetail: item.value,
      workstationID: this.workstationid,
      monitorStartTime: unixtimestamp
    };
    this.apiservice.addorder(datas).subscribe(
      (res: any) => {
        (this.role === 'office') ? '' : res['mapping_field'] = this.DashboardComponent?.active_order?.mapping_field;
        this.RedEventID = res.eventID;
        if (dynamicpopup) {
          this.setAutoPopupForm(res.dynamicExtraLevel);
        }
        let selecteddata = {
          orderID: this.DashboardComponent?.active_order?.orderID ? this.DashboardComponent?.active_order?.orderID : this.LandingPageComponent?.active_order?.orderID,
          zaaglijstID: this.DashboardComponent?.active_order?.zaaglijstID ? this.DashboardComponent?.active_order?.zaaglijstID : this.LandingPageComponent?.active_order?.zaaglijstID,
          customerRef: order.customerRef,
          orderId: order.orderID,
          eventType: order.eventType
        };
        console.log(selecteddata, 'selecteddata');
        (this.role === 'office') ? (this.LandingPageComponent.getorders(this.LandingPageComponent.currentuser.userID, this.workstationid, false), this.LandingPageComponent.getnotes()) :
          (this.DashboardComponent.getOrders(this.DashboardComponent.currentuser.userID, this.workstationid, selecteddata, true, res),
            this.DashboardComponent.getNotes());

        this.dashcreateOrder('red', this.SelectedCategory, order, res)
        // (this.role === 'office') ? (this.LandingPageComponent.getorders(this.LandingPageComponent.currentuser.userID, this.workstationid,false), this.LandingPageComponent.getnotes()) :
        //   (this.DashboardComponent.getOrders(this.DashboardComponent.currentuser.userID, this.workstationid),
        //     this.DashboardComponent.getNotes());
        // (Object.keys(this.popupform?.value).length > 0 || this.popupform?.value.mail != '') ? this.PostFormFields() : this.navigate();
      }, (error: any) => {
        this.toastrService.error('Something went wrong!', 'Error!');
      })
  }
  async dashcreateOrder(flag: any, eventDescription: any, item: any, res: any) {
    (this.checkListFormGroup) ? this.checkListFormGroup.reset({}) : '';
    (this.DashboardComponent.checkListFormGroupTemp) ? this.DashboardComponent.checkListFormGroupTemp.reset({}) : '';
    this.DashboardComponent.formgroups = [];
    this.DashboardComponent.checkListItems = [];
    this.DashboardComponent.checkListItemsList = [];
    let user_flag = true;
    if (user_flag) {
      if (this.active_order.orderID != '' || item.orderID != '') {
        this.DashboardComponent.order_viewdisable = true;
        this.DashboardComponent.current_temp_order = this.active_order.orderID;
        var unixtimestamp = Math.floor(new Date().getTime() / 1000);

        let datas = {
          orderNr: item.orderID,
          customerRef: '',
          orderkey: '',
          userID:
            this.currentuser != undefined
              ? this.currentuser.userID
              : eventDescription.userID,
          actionType: flag,
          eventDescription: eventDescription.value
            ? eventDescription.eventDescription
            : eventDescription,
          actionDetail: eventDescription.value
            ? eventDescription.value
            : eventDescription,
          workstationID: this.workstationid,
          StartTime: unixtimestamp,
        };
        (datas.userID != undefined) ? '' : (datas.userID = eventDescription.userID);
        this.DashboardComponent.green_eventdata ? (datas.orderNr = this.DashboardComponent.green_eventdata.orderID) : '';
        this.DashboardComponent.green_eventdata = undefined;
        (flag == 'green' && !this.DashboardComponent.green_categories) ? (datas.actionDetail = null) : '';

        if (res) {
          if (res.response == 'success') {

            let temp: any;
            let checklist: any;
            let checklist_array: any = [];
            if (this.DashboardComponent.customizing?.showChecklist != null) {
              await this.DashboardComponent.customizing?.showChecklist.some((element: any) => {
                if (element == 'afterGreen' || element == 'afterGreenGoodsReceipt') {
                  checklist = true;
                  checklist_array.push(element);
                  temp = element;
                  // return true;
                } else if (element == 'dependingOnOrderResponse') {
                  if (res?.triggerChecklist) {
                    checklist = true;
                    temp = res?.triggerChecklist;
                    checklist_array.push(...temp)
                  } else {
                    checklist = false;
                  }
                } else if (element == 'preSSOP') {
                  checklist_array.push(element);
                  checklist = true;
                  temp = element;
                } else if (element == 'afterGreenClosed') {
                  if (!checklist) {
                    checklist = false;
                  }
                } else if (element == 'oncePerDayAfterGreenStart') {
                  let greendate = localStorage.getItem("onegreenperday");
                  let tempdate = new Date();
                  let year = tempdate.getFullYear();
                  let month = tempdate.getMonth() + 1;
                  let day = tempdate.getDate();
                  let date = day + '-' + month + '-' + year;
                  if (greendate && greendate == date.toString()) {
                    checklist = false;
                  } else {
                    temp = element;
                    localStorage.setItem("onegreenperday", date);
                    checklist = true;
                  }
                } else {
                  checklist = false;
                }
              });
            } else {
              temp = '';
              checklist = false;
            }
            if (this.DashboardComponent.customizing?.triggerPrint != null) {
              this.DashboardComponent.customizing?.triggerPrint?.forEach((element: any) => {
                if (element.processStep == 'dependingOnOrderResponse') {
                  if (res?.triggerPrint != null) {
                    this.DashboardComponent.postPrintFlags(res.triggerPrint);
                  }
                }
              });
            }
            (temp == undefined) ? temp = '' : '';
            let loadFlag = localStorage.getItem(this.currentuser.userID + 'screen_play');
            if ((loadFlag === 'false' || !loadFlag) && flag === 'green') {
              if (((checklist == true || this.DashboardComponent.customizing.showInstructions != null) && flag === 'green')) {
                const go = async () => {
                  for await (let item of checklist_array) {
                    await this.DashboardComponent.erpattachment(this.active_order);
                    this.DashboardComponent.goodsReciptFlag = 'afterGreen';
                    await this.DashboardComponent.getChecklistItems(this.currentuser?.userID, this.active_order?.orderID, item, checklist, 'afterGreen');
                  }
                  ;
                }
                await go();
                this.checkListFormGroup = this.DashboardComponent.formgroups[0];
                this.checkListItems = this.DashboardComponent.checkListItemsList[0];
                this.DashboardComponent.formGroupIndex = 0;
                this.DashboardComponent.openCloseInstructionModal('1', checklist, 'afterGreen');
              } else {
                if (this.DashboardComponent.customizing.showInstructions == 'afterGreen') {
                  await this.DashboardComponent.erpattachment(this.active_order), this.DashboardComponent.goodsReciptFlag = 'afterGreen';
                  await this.DashboardComponent.getChecklistItems(this.currentuser?.userID, this.active_order?.orderID, temp, checklist, 'afterGreen');
                  this.DashboardComponent.openCloseInstructionModal('3', checklist, 'afterGreen');
                }
              }
            }
            if (flag == 'yellow' && this.DashboardComponent.showorangetimer == true) {
              let datas = {
                "ordernr": res?.orderID,
                "eventID": res?.eventID,
              }
              localStorage.setItem("DateForOrange", JSON.stringify(datas))
            }
            if (flag == 'blue') {
            } else if (this.currentuser == undefined) {
            } else {

              this.DashboardComponent.current_temp_order == this.active_order.orderID
                ? (this.DashboardComponent.current_temp_order = res.orderID)
                : (this.DashboardComponent.current_temp_order = undefined);
              if (this.DashboardComponent?.active_order?.eventType == '') {
                this.DashboardComponent.current_temp_order = this.active_order.orderID;
                let id = this.active_order?.orderID;
                this.DashboardComponent.temp_orders = localStorage.getItem(this.currentuser.userID);
                this.DashboardComponent.temp_orders = JSON.parse(this.DashboardComponent.temp_orders);
                let k = this.DashboardComponent.temp_orders?.filter((item: any) => {
                  return item.orderID != id
                });

                (k && k != undefined) ? localStorage.setItem(this.currentuser.userID, JSON.stringify(k)) : '';
              }

              if (item.orderID != this.active_order.orderID) {
                this.DashboardComponent.orders.forEach((element: any) => {
                  if (element.orderID == item.orderID) {
                    element.eventType = flag;
                  }
                });
              } else {
                if (this.DashboardComponent.active_order?.eventType) {
                  this.DashboardComponent.active_order.eventType = flag;
                }
                this.DashboardComponent?.setOpenOrders(res.openOrders);
                // this.getOrders(this.currentuser.userID, this.workstationid,'',true)

              }
              if (this.active_order.orderID == '' && item.orderID != '') {
                this.DashboardComponent.getOrders(this.currentuser.userID, this.workstationid, '', true, '')
              }
            }
          } else {
            this.toastrService.error(`${res.errorMessage}`, 'Error!');
          }
        }


        this.DashboardComponent.order_viewdisable = false;
      }
    } else {
      localStorage.removeItem('active_order');
      this.DashboardComponent.orders = [];
      this.DashboardComponent.t = "00:00:00";
      localStorage.removeItem(this.currentuser.userID + 'focus_order');
      localStorage.removeItem(this.currentuser.userID);
      this.DashboardComponent.lastUserFocus();
      this.DashboardComponent.addOrder('s', true, '', '')
      this.DashboardComponent.getOrders(this.currentuser.userID, this.workstationid, '', true, '');
    }
  }
  PostFormFields() {
    let data: any = {
      eventID: this.RedEventID,
      updateFields: [],
    };

    for (const [key, value] of Object.entries(this.popupform.value)) {
      if ((value as any).trim() != '') {
        data.updateFields.push({ [key]: value })
      }
    }
    this.apiservice.addformfield(data).subscribe(
      (res: any) => {
        // this.popupform.reset({});
        if (res.response == 'success') {
        } else {
          this.toastrService.error('Not added!', 'Error!');
        }
      }, (error) => {
      })
  }


  async submitdropdown() {

    if (this.popupform.valid) {
      this.modalService.dismissAll();

      // this.navigate();
      (this.role === 'office') ? (this.LandingPageComponent.getorders(this.LandingPageComponent.currentuser.userID, this.workstationid, false), this.LandingPageComponent.getnotes()) :
        (this.DashboardComponent.getOrders(this.DashboardComponent.currentuser.userID, this.workstationid, '', false, ''),
          this.DashboardComponent.getNotes());
      (Object.keys(this.popupform?.value).length > 0 || this.popupform?.value.mail != '') ? this.PostFormFields() : this.navigate();
    }
    else {
      this.toastrService.error('Please select any value!', 'Error!');
    }



  }



  open(content: any, item: any) {
    if (item.preventSkipExtraLevel == '1') {
      this.config.backdrop = 'static';
      this.config.keyboard = false;
      this.hidebutton = false;
    } else {
      this.config.backdrop = true;
      this.config.keyboard = true;
      this.hidebutton = true;
    }
    this.eventDesc = item;
    this.dropdown_values = [];
    if (item.extraLevel != null) {
      let temp = item.extraLevel.split('mailto');
      if (temp[0] == 'Instructie|') {
        temp.forEach((element: any) => {
          var arr_Str = element.split(/[<>]/);
          arr_Str.forEach((z: any) => {
            this.values_b.push(z);
          });
        });
        this.values_b.forEach((y: any) => {
          var regex = /^[A-Za-z0-9&; ]+$/
          var isValid = regex.test(y);
          if (isValid && y != '' && y != ';') {
            let t = y.substring(0, y.indexOf(" &"));
            t = (t == '') ? y : t
            this.values.push(t)
          }
        });
        if (this.values.length > 1) {
          for (let k = 0; k < this.values.length - 1; k++) {
            if (this.values[k] && k + 1 <= this.values.length - 1) {
              this.dropdown_values.push(this.values[k] + ' & ' + this.values[k + 1]);
              k = k + 1;
            }
          }
        } else {
          this.dropdown_values = this.values;
        }
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }
      else if (!item.extraLevel.includes(";")) {
        this.openmd(this.contentRef);
        this.placeholder = item.extraLevel;
      } else {
        let temp = item.extraLevel.split(';');

        temp.forEach((element: any) => {
          this.dropdown_values.push(element)
        });
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      }
    } else {
      this.setflag("red", this.eventDesc)
    }

  }

  openmd(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  navigate() {
    (this.role === 'office') ? this.LandingPageComponent.set_flag('screen', '') : this.DashboardComponent.set_flag('screen', '');
  }

  async setflag(datas: any, eventDesc: any) {
    if (this.role == 'office') {
      let data = localStorage.getItem("active_order")
      data = JSON.parse(data as any);
      let greendata = localStorage.getItem("green_eventdata");
      greendata = JSON.parse(greendata as any);
      if (data) {
        let eventDescp: any = localStorage.getItem("green_subcat");
        eventDescp = JSON.parse(eventDescp);
        (eventDescp) ? eventDescp : eventDescp = "working on the order";
        await this.LandingPageComponent.stop(data, 'red', false);
        (greendata) ? await this.LandingPageComponent.stop(greendata, 'red', false) : '';
        (greendata) ? await this.LandingPageComponent.createorder('red', eventDesc, greendata) : '';
        (data && greendata) ? await this.LandingPageComponent.createorder("green", eventDescp, data) : '';
        (data && !greendata) ? await this.LandingPageComponent.createorder("red", eventDesc, data) : '';
      } else {
        (greendata) ? await this.LandingPageComponent.stop(greendata, 'red', false) : '';
        this.LandingPageComponent.active_order = greendata;
        await this.LandingPageComponent.createorder("red", eventDesc, greendata);
        let order: any = localStorage.getItem('darkblueorder');
        order = JSON.parse(order);
        this.apiservice.addorder(order).subscribe(async (res: any) => {
          if (res.response == 'success') {
            this.LandingPageComponent.navigate_cal();
            this.modalService.dismissAll();
            let data = {
              eventID: res.eventID,
              updateFields: [
                {
                  remark: order.remark
                }
              ]
            }
            this.apiservice.addformfield(data).subscribe(async (res: any) => {
            })
            this.LandingPageComponent.getorders(this.currentuser.userID, this.workstationid, false)
          }
        });
        // localStorage.removeItem("green_eventdata");
        localStorage.removeItem('darkblueorder');
      }
    } else {
      if (environment.Deactivate_timer == true) {
        let eventDescp: any = localStorage.getItem("green_subcat");
        eventDescp = JSON.parse(eventDescp);
        (eventDescp) ? eventDescp : eventDescp = "working on the order";
        let greendata = localStorage.getItem("green_eventdata");
        greendata = JSON.parse(greendata as any);
        let data = localStorage.getItem("active_order")
        data = JSON.parse(data as any);
        (greendata) ? (await this.DashboardComponent.stop(greendata, 'red', false)) : '';
        (await this.DashboardComponent.stop(data, 'red', false));
        (greendata) ? (await this.DashboardComponent.createOrder("red", eventDesc, greendata)) : '';
        (data && greendata) ? (await this.DashboardComponent.createOrder("green", eventDescp, data)) : '';
        (data && !greendata) ? (await this.DashboardComponent.createOrder("red", eventDesc, data)) : '';
      }
    }
    localStorage.removeItem("green_eventdata");
    localStorage.removeItem("green_subcat");
    this.navigate();
  }
}
