<div class="row">
    <div class="col-md-1">
    </div>
    <!-- <div class="col-md-10 my-auto"> -->
    <!-- <div class="col-md-8 mx-auto mt-5"> -->

    <div class="col-md-10 mx-auto mt-4">
        <div *ngIf="workinghours_flag">
            <app-working-on></app-working-on>
        </div>
        <div *ngIf="downtime_flag">
            <app-down-time></app-down-time>

        </div>
        <div *ngIf="unplanned_flag">
            <app-unplanned-down-time></app-unplanned-down-time>
        </div>
        <div *ngIf="reports_flag">
            <app-reports></app-reports>
        </div>
        <form *ngIf="screen_status" [formGroup]="form" (keyup.enter)="orders()" novalidate>
            <div class="container">
                <div class="row border border-2">
                    <div class="col-md-8 p-1 d-flex project">
                        <label *ngIf="role == 'shopfloor'">
                            {{'HOME.ORDERTOPIC' | translate}}
                        </label>
                        <label *ngIf="role == 'office'">
                            {{'HOME.ORDERTOPIC_OFFICE' | translate}}

                        </label>
                    </div>
                </div>

                <div class="row border-top-0 border border-2">
                    <div class="col border-end ">
                        <div class="col-md-10 d-flex p-3 mx-4 inputField">
                            <div class="row">
                                <div class="col-md-12">

                                    <input type="text" #number_input name="order"
                                        [ngClass]="{ 'is-invalid':input_item == false ,'active':input_item != false }"
                                        id="testField" formControlName="order" *ngIf="role == 'shopfloor'"
                                        (keyup)="getinput()" class="form-control form-control-lg inputOrderNumber"
                                        placeholder="{{input_desc}}" style="display:inline-block; flex: 1;" autoFocus>
                                    <input type="text" #number_input name="order" *ngIf="role == 'office'"
                                        [ngClass]="{ 'is-invalid' :input_item == false  }" formControlName="order"
                                        id="testField" (keyup)="getinput()"
                                        class="form-control form-control-lg inputOrderNumber"
                                        placeholder="{{input_desc}}" style="display:inline-block; flex: 1;" autoFocus>


                                </div>

                                <select *ngIf="customerID === '3'" formControlName="projectExtention"
                                    class="form-control  selecttag3 " name="" id="">
                                    <option value="">PE</option>
                                    <option *ngFor="let list of projectExtentionValues" value="{{list}}">{{list}}
                                    </option>
                                </select>
                                <div class="col-md-12 p-2 mb-2 mrgtop"
                                    *ngIf="this.workstationid==38 || this.workstationid==40 || this.workstationid==41 || this.workstationid==42 ||this.workstationid==43 || this.workstationid==44 || this.workstationid==45 || this.workstationid==46">
                                    <button type="button" class=" confirmbutton btn btn-primary"
                                        (click)="orders()">{{'HOME.CONFIRMBUTTON'
                                        |
                                        translate}}</button>
                                </div>
                                <div class="col-md-12 p-2 mb-2 mrgbtm"
                                    *ngIf="!(this.workstationid==38 || this.workstationid==40 || this.workstationid==41 || this.workstationid==42 ||this.workstationid==43 || this.workstationid==44 || this.workstationid==45 || this.workstationid==46)">
                                    <button type="button" class="confirmbutton btn btn-primary"
                                        (click)="orders()">{{'HOME.CONFIRMBUTTON'
                                        |
                                        translate}}</button>
                                </div>

                            </div>

                        </div>
                        <!-- <div class="col-md-10 d-flex p-3 mx-4 inputField">

                             <input type="text" matKeyboard #number_input name="order"
                                [ngClass]="{ 'is-invalid':input_item == false ,'active':input_item != false }"
                                id="testField" formControlName="order" *ngIf="role == 'shopfloor' && isKeyboard"
                                (keyup)="getinput()" class="form-control form-control-lg inputOrderNumber"
                                placeholder="{{input_desc}}" style="display:inline-block; flex: 1;" autoFocus>
                            <input type="text" #number_input name="order"
                                [ngClass]="{ 'is-invalid':input_item == false ,'active':input_item != false }"
                                id="testField" formControlName="order" *ngIf="role == 'shopfloor' && !isKeyboard"
                                (keyup)="getinput()" class="form-control form-control-lg inputOrderNumber"
                                placeholder="{{input_desc}}" style="display:inline-block; flex: 1;" autoFocus>
                            <input type="text" matKeyboard #number_input name="order"
                                *ngIf="role == 'office' && isKeyboard"
                                [ngClass]="{ 'is-invalid' :input_item == false  }" formControlName="order"
                                id="testField" (keyup)="getinput()"
                                class="form-control form-control-lg inputOrderNumber" placeholder="{{input_desc}}"
                                style="display:inline-block; flex: 1;" autoFocus>
                            <input type="text" #number_input name="order" *ngIf="role == 'office' && !isKeyboard"
                                [ngClass]="{ 'is-invalid' :input_item == false  }" formControlName="order"
                                id="testField" (keyup)="getinput()"
                                class="form-control form-control-lg inputOrderNumber" placeholder="{{input_desc}}"
                                style="display:inline-block; flex: 1;" autoFocus>
                        </div> -->

                    </div>
                    <!-- *ngIf="customizing?.allow_createERPOrders === '1' || (customizing?.allow_createERPOrders === '0' && HideSelectButton === false)" -->
                    <div class="col"
                        *ngIf="+customizing?.allow_createERPOrders === 1 || (+customizing?.allow_createERPOrders === 0 && HideSelectButton === false)">
                        <div [ngClass]="this.workstationid==38 || this.workstationid==40 || this.workstationid==41 || this.workstationid==42 ||this.workstationid==43 || this.workstationid==44 || this.workstationid==45 || this.workstationid==46? '':'p-3'"
                            class="col-md-10 d-flex justify-content-center mt-3 mx-3">
                            <label *ngIf="role =='shopfloor'" class="lable-text">

                                <ng-container
                                    *ngIf="this.workstationid==38 || this.workstationid==40 || this.workstationid==41 || this.workstationid==42 ||this.workstationid==43 || this.workstationid==44 || this.workstationid==45 || this.workstationid==46">
                                    <label class="lable-text"> {{'HOME.ACTIVE' | translate}}

                                    </label>
                                    <label class="roundicon">

                                        <i (click)="isShow?'':openSawListPopup(sawList)"
                                            class="fa fa-pencil pencil-icon" aria-hidden="true"></i>
                                    </label>
                                    <!-- <i class="fa-solid fa-pen-circle"></i> -->
                                    <br> <label class="lable-text text-bold-color"
                                        *ngIf="zaagList?.ordernumber_list?.zaaglijst_title">{{zaagList?.ordernumber_list?.zaaglijst_title}}</label>

                                </ng-container>

                                <ng-container
                                    *ngIf="!(this.workstationid==38 || this.workstationid==40 || this.workstationid==41 || this.workstationid==42 ||this.workstationid==43 || this.workstationid==44 || this.workstationid==45 || this.workstationid==46)">
                                    <label style="color: #24597a;font-size: 18px;">Open orders
                                    </label>

                                </ng-container>
                            </label>
                            <label *ngIf="role =='office'" style="color: #24597a;font-size: 18px;">Open orders
                            </label>
                        </div>
                        <div class="col-md-11 p-2 mx-1 mt-2">
                            <ng-container *ngIf="role == 'shopfloor'">
                                <ng-container
                                    *ngIf="this.workstationid==38 || this.workstationid==40 || this.workstationid==41 || this.workstationid==42 ||this.workstationid==43 || this.workstationid==44 || this.workstationid==45 || this.workstationid==46">

                                    <button [disabled]="isShow" type="button" class="btn form-control-lg selectorder"
                                        (click)="zaagList?.ordernumber_list?.zaaglijst_title=='geen'?openSawListPopup(sawList):getselectOrder(loadList)"
                                        data-bs-target="#exampleModal"> <i class="fa fa-external-link"
                                            aria-hidden="true"></i>
                                        {{'HOME.SELECTBUTTON' | translate}} </button>
                                </ng-container>
                                <ng-container
                                    *ngIf="!(this.workstationid==38 || this.workstationid==40 || this.workstationid==41 || this.workstationid==42 ||this.workstationid==43 || this.workstationid==44 || this.workstationid==45 || this.workstationid==46)">

                                    <button [disabled]="isShow" type="button" class="btn form-control-lg selectorder"
                                        (click)="open(content)" data-bs-target="#exampleModal"> <i
                                            class="fa fa-external-link" aria-hidden="true"></i>
                                        {{'HOME.SELECTBUTTON' | translate}} </button>
                                </ng-container>
                            </ng-container>
                            <button type="button" *ngIf="role == 'office'" class="btn form-control-lg selectorder"
                                (click)="open(content)" data-bs-target="#exampleModal"> <i class="fa fa-external-link"
                                    aria-hidden="true"></i> {{'HOME.SELECTBUTTON_OFFICE' |
                                translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="container mt-4" *ngIf="customizing?.allow_StockManagement === '1'"> -->
            <div class="container mt-4" *ngIf="customizing?.allow_StockManagement === 1">
                <div class="row border border-2">
                    <div class="col-md-8 p-1 d-flex project">
                        <label>
                            {{'HOME.PRODUCT_CARRIER_TOPIC' | translate}}
                        </label>
                    </div>
                </div>
                <div class="row border-top-0 border border-2">
                    <div class="col">
                        <div class="col-md-11 p-3 d-flex justify-content-center mt-2 mx-4">
                            <button type="button" class=" btn form-control-lg selectorder mb-0 ml-2"
                                (click)="open(productModal)"> <i class="fa fa-external-link" aria-hidden="true"></i>
                                {{'HOME.PRODUCT_CARRIERS' | translate}} </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- Modal -->
<ng-template #content let-modal>
    <!-- <div class="modalProject"> -->
    <div class="modal-content modal-dialog-scrollable">
        <div class="modal-header">
            <h2 class="modal-title" id="exampleModalLabel" *ngIf="role == 'shopfloor'">Order</h2>
            <h2 class="modal-title" id="exampleModalLabel" *ngIf="role == 'office'">Project</h2>
            <button type="button" class="btn-close" (click)="modal.dismiss('Cross click');" aria-label="Close"></button>
        </div>
        <div class="modal-body projectbody">
            <div class="d-grid gap-2">
                <button class="btn create-order-btn"
                    *ngIf="customerID == '9' && +customizing?.allow_createERPOrders === 1" type="button"
                    (click)="NavigateTo('1')">
                    <i class="fa fa-plus-circle" aria-hidden="true"></i>
                    {{'HOME.CREATE_ORDER' | translate}}
                </button>
                <div class="search-bar" *ngIf="+customizing.showSearchInERPOrderModal === 1">
                    <input type="text" class="form-control" placeholder="Search" onfocus="this.placeholder = ''"
                        onblur="this.placeholder = 'Search'" [(ngModel)]="SearchText" />
                    <i class="fa fa-search" style="color: #24597A;"></i>
                </div>
                <button class="btn order-list-btn" *ngFor="let items of orderlist | filter:SearchText"
                    [ngClass]="{focus:customerID == '10'?order_to_pass?.mapping_field == items.mapping_field :order_to_pass?.orderID == items.orderID}"
                    (click)="sendorder(items)" type="button">
                    <div class="order-list-details">
                        <p class="order-id">
                            {{items.orderID}} <img *ngIf="items.allowExecute == 'false'"
                                src="../../assets/hand-rise.svg" alt="Rise hand" />
                        </p>
                        <p>{{items.dueDate}}</p>
                    </div>
                    <div class="order-list-details" *ngIf="customerID == '9'">
                        <p>{{items.customerName}}</p>
                        <p>{{items.numberOfPallets}} pallets</p>
                    </div>
                    <div class="order-list-details" *ngIf="customerID == '10'">
                        <p>{{items.customerName}}</p>
                    </div>
                    <div class="order-list-details" *ngIf="customerID == '1'">
                        <p>{{items.customerName}}</p>
                    </div>
                </button>
            </div>
        </div>
        <div class="modal-footer" *ngIf="customerID == '9'">
            <button type="button" *ngIf="role != 'shopfloor'" class="btn btn-outline-dark canceldark"
                (click)="modal.dismiss('Cross click')">{{'HOME.CANCELBUTTON' | translate}}</button>
            <button type="submit" *ngIf="role != 'shopfloor'" class="btn btn-primary"
                (click)="onSubmit();modal.dismiss('Cross click');">{{'HOME.CONFIRMBUTTON' | translate}}</button>
            <div class="row d-flex  justify-content-center flex-grow-1 " *ngIf="role == 'shopfloor'">
                <button type="button" (click)="onSubmit();modal.dismiss('Cross click');"
                    class="btn btn-success sync p-1"
                    [ngClass]="{ disable: order_to_pass?.orderID == undefined || order_to_pass?.allowExecute == 'false' }">
                    {{'HOME.CONFIRMBUTTON' | translate}}
                </button>&nbsp;&nbsp;&nbsp;
                <button type="button" class="btn btn-success edit  p-1" *ngIf="+customizing?.allow_editERPOrders === 1"
                    [ngClass]="{ disable: order_to_pass?.orderID == undefined}" (click)="NavigateTo('2')">
                    {{'HOME.EDIT_LABEL' | translate}}
                </button>&nbsp;&nbsp;&nbsp;
                <button type="button" (click)="modal.dismiss('Cross click')" class="btn btn-success reset p-1">
                    {{'HOME.CANCELBUTTON' | translate}}
                </button>&nbsp;&nbsp;&nbsp;
            </div>
        </div>
        <div class="modal-footer" *ngIf="customerID != '9'">
            <button type="submit" class="btn btn-primary "
                (click)="onSubmit();modal.dismiss('Cross click');">{{'HOME.CONFIRMBUTTON' | translate}}</button>
            <button type="button" class="btn btn-outline-dark canceldark"
                (click)="modal.dismiss('Cross click');">{{'HOME.CANCELBUTTON' | translate}}</button>
        </div>
    </div>
    <!-- </div> -->
</ng-template>

<ng-template #productModal let-modal>
    <div class="modal-header">
        <h2 class="modal-title" id="exampleModalLabel">{{'HOME.PRODUCT_CARRIER_TOPIC' | translate}}</h2>
        <button type="button" class="btn-close" (click)="modal.dismiss('Cross click');clearSelectorder();"
            aria-label="Close"></button>
    </div>
    <div class="modal-body projectbody">
        <div class="d-inline">
            <label class="col-sm-6">Product</label>
            <label class="col-sm-6">{{'HOME.STOCK_QTY' | translate}}</label>
        </div>

        <!--  START: Product Component -->
        <div class="container productContainer mx-auto mt-2" (click)="getProduct(stockBuffer)"
            *ngFor="let stockBuffer of stockBufferList"
            [ngClass]="{ 'editProductContainer': (selectedProduct != undefined) &&  selectedProduct.Productname === stockBuffer.Productname }">

            <div class="row">
                <div class="col-md-6 mt-3 f-18">
                    {{stockBuffer.Description}}
                </div>

                <div class="col-md-6 mt-3 d-flex justify-content-end inventory">
                    {{stockBuffer.inventory}}
                </div>

                <div class="d-flex justify-content-end mb-3 productInput"
                    *ngIf="(selectedProduct != undefined) && selectedProduct?.Productname === stockBuffer.Productname">
                    <div class="col-sm-3 template">
                        <form [formGroup]="productForm" novalidate>
                            <input type="text" class="form-control inputOrder" [(ngModel)]="addedQty"
                                (keyup)="sumStockBuffer(stockBuffer)" formControlName="qty">
                        </form>
                        <label class="col-sm-3"> ___________<i class="fa fa-plus d-flex mt-2 mx-2"
                                aria-hidden="true"></i>
                        </label>
                        <label class="qty">{{isSumOfQty==true? stockBuffer.sumOfQty: stockBuffer.inventory}}</label>
                        <button type="submit" class="btn btn-primary"
                            (click)="updateQuantityDatabase(stockBuffer);clearSelectorder();">{{'HOME.SAVE_BUTTON' |
                            translate}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!--  END: Product Component -->

        <!-- </form> -->
        <!-- <div class="row container editProductContainer mt-2 mx-auto">
                <div class="row">
                      <div class="col-md-6 mt-3">
                        Box 1
                      </div>
                      <div class="col-md-6 mt-3 d-flex justify-content-end">
                    245
                      </div>
                      <div class="d-flex justify-content-end mb-3 productInput">
                      <div class="col-sm-3 template">
                        <input type="text" #number_input name="order"
                            class="form-control form-control-lg inputOrder"
                            >
                            <label class="col-sm-3"> ______________<i class="fa fa-plus d-flex mt-2 mx-2" aria-hidden="true"></i>
                            </label>

                            <label>255</label>
                            <button type="submit" class="btn btn-primary"
                >{{'HOME.SAVE_BUTTON' | translate}}</button>
                    </div>
                </div>
                </div>
              </div> -->
        <!-- <button class="btn order-list-btn" *ngFor="let items of orderlist"
                    [ngClass]="{ focus: order_to_pass?.orderID == items.orderID }" (click)="sendorder(items)"
                    type="button">
                    <div class="order-list-details">
                        <p class="order-id">{{items.orderID}} <img *ngIf="items.allowExecute == 'false'"
                                src="../../assets/hand-rise.svg" alt="Rise hand" /></p>
                        <p>{{items.dueDate}}</p>
                    </div>
                    <div class="order-list-details" *ngIf="customerID == '9'">
                        <p>{{items.customerName}}</p>
                        <p>{{items.numberOfPallets}} pallets</p>
                    </div>
                </button> -->

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-success reset p-1"
            (click)="modal.dismiss('Cross click');clearSelectorder();">{{'HOME.CLOSEBUTTON' | translate}}</button>
    </div>

</ng-template>

<ng-template #sawList let-modal>
    <div class="modal-content modal-dialog-scrollable">
        <div class="modal-header">
            <h2 class="modal-title" id="exampleModalLabel" *ngIf="role == 'shopfloor'">{{'HOME.SELECTZAAGLIST' |
                translate}}</h2>
            <h2 class="modal-title" id="exampleModalLabel" *ngIf="role == 'office'">Project</h2>
            <button type="button" class="btn-close" (click)="modal.dismiss('Cross click');" aria-label="Close"></button>
        </div>


        <div class="modal-body modalheight">
            <div *ngFor="let list of zaagList?.ordernumber_list?.zaaglijsten;let i=index">
                <div class="row mt-4">
                    <div class="col-md-8 showing-cursor " (click)="getzaaglist(list)">
                        <div *ngIf="!(list?.zaaglijstInStagnatieUserName) && !(list?.zaaglijstInUseUserName)">
                            <div class="card cardwidth"
                                [ngClass]="{'sawList-background-grey':true,'activatedGreenColor':zaaglistdata?.id==list.id}">
                                <div class="card-body ">

                                    <p *ngIf="list?.materialDescription">{{list?.materialDescription}} </p>
                                    <p *ngIf="list?.zaaglijstNo">{{list?.zaaglijstNo}} ({{list?.date_loaded}})</p>
                                    <p *ngIf="list?.aantalNieuweLengtes != '' && list.aantalNieuweLengtes != null">
                                        {{list?.aantalNieuweLengtes}}
                                        {{'HOME.LENGTH' |
                                        translate}}</p>
                                    <p *ngIf="list?.materialLocation !='' && list?.materialLocation != null">
                                        {{'HOME.LOCATION' |
                                        translate}}: {{list?.materialLocation}} | {{'HOME.STOCK' |
                                        translate}}: {{list?.materialStock}}</p>
                                    <!-- <p *ngIf="zaagList?.ordernumber_list?.zaaglijst_title">
                                        {{zaagList?.ordernumber_list?.zaaglijst_title}}</p> -->
                                </div>
                            </div>
                        </div>

                        <div *ngIf="list?.zaaglijstInStagnatieUserName|| list?.zaaglijstInUseUserName">
                            <div class="card"
                                [ngClass]="{'sawList-background-pink':true,'activatedGreenColor':zaaglistdata?.id==list.id}">
                                <div class="card-body  container">
                                    <div class="pinkcontainer">
                                        <p *ngIf="list?.materialDescription">{{list?.materialDescription}}
                                        </p>
                                        <p *ngIf="list?.zaaglijstNo">{{list?.zaaglijstNo + '('+
                                            list?.date_loaded
                                            +')'}}</p>
                                        <p class="card-text"
                                            *ngIf="list?.aantalNieuweLengtes != '' && list.aantalNieuweLengtes != null">
                                            {{list?.aantalNieuweLengtes}}
                                            lengtes</p>
                                        <p class="card-text"
                                            *ngIf="list?.materialLocation !='' && list?.materialLocation != null">
                                            Locatie: {{list?.materialLocation}}</p>

                                    </div>
                                    <div class="content">
                                        <p class="card-text" *ngIf="list.zaaglijstInStagnatieUserName">
                                            {{'HOME.ORDERPARKEDSTATUS' |
                                            translate}} {{list?.zaaglijstInStagnatieUserName}}</p>
                                        <p class="card-text" *ngIf="list.zaaglijstInUseUserName">
                                            {{'HOME.ORDERINUSE' | translate}} {{list?.zaaglijstInUseUserName}}</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2 showing-cursor" *ngIf="this.workstationList[0].box1">
                        <div (click)="suncircleKardex(this.workstationList[0].box1,list)" class="card"
                            [ngClass]="[this.workstationList[0].box1 == list.kardexUitvoer?'sawList-background-blue':'sawList-background-black']">

                            <div class="card-body projecttext">

                                <p class="card-text cardtext"> Haal bak {{this.workstationList[0].box1}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2 showing-cursor" *ngIf="this.workstationList[0].box2">
                        <div class="card" (click)="suncircleKardex(this.workstationList[0].box2,list)"
                            [ngClass]="[this.workstationList[0].box2 == list.kardexUitvoer ?'sawList-background-blue':'sawList-background-black']">
                            <div class="card-body projecttext">

                                <p class="card-text cardtext">Haal bak {{this.workstationList[0].box2}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-footer  mt-2">
            <div class="loadlistbottombuttons">
                <button type="submit" class="btn btn-primary btn-primaryy btn-blocks load-list-button"
                    [disabled]="zaaglistdata==undefined"
                    (click)="getLoadList(loadList);modal.dismiss('Load list');">{{'HOME.LOADLIST' | translate}}</button>
                <button type="button" [disabled]="zaaglistdata==undefined"
                    class="btn btn-primary btn-primaryy btn-blocks remove-button" (click)="removeZaaglijst()">
                    {{'HOME.REMOVELIST' | translate}}</button>
                <button type="button" class="btn btn-primary btn-primaryy btn-blocks cancel-buttons"
                    (click)="modal.dismiss('Cross click');">
                    {{'HOME.CANCELBUTTON' | translate}}</button>
            </div>

            <!-- <div class="row mt-5 modalborder-color">
                <div class="col-md-5"></div>
                <div class="col-md-7">
                    <div class="row">
                        <div class="col-md-4">
                            <button type="submit" class="btn btn-primary btn-blocks load-list-button"
                                [disabled]="selectedData==undefined"
                                (click)="getLoadList(loadList,'');modal.dismiss('Load list');">Load
                                list</button>
                        </div>
                        <div class="col-md-4">
                            <button type="button" [disabled]="selectedData==undefined"
                                class="btn btn-primary btn-blocks remove-button" (click)="removeZaaglijst()">
                                Remove
                                list</button>
                        </div>
                        <div class="col-md-4">
                            <button type="button" class="btn btn-primary btn-blocks cancel-buttons"
                                (click)="modal.dismiss('Cross click');">
                                Cancel</button>
                        </div>
                    </div>

                </div>

            </div> -->
        </div>
    </div>
</ng-template>
<ng-template #loadList let-modal>
    <div class="modal-content modal-dialog-scrollable">
        <div class="modal-header">
            <h2 class="modal-title" id="exampleModalLabel" *ngIf="role === 'shopfloor'">Zaagorders
                ({{zaagList?.ordernumber_list?.ordernummers?.length}})</h2>
            <h2 class="modal-title" id="exampleModalLabel" *ngIf="role === 'office'">Project</h2>
            <button type="button" class="btn-close" (click)="modal.dismiss('Cross click');" aria-label="Close"></button>
        </div>


        <div class="modal-body modalheight">
            <ng-container *ngIf="zaagList?.ordernumber_list?.ordernummers.length>0 else noOrderFound">
                <div *ngFor="let list of zaagList?.ordernumber_list?.ordernummers;">
                    <div class="row mt-2">

                        <ng-container *ngIf="!list.isclose">

                            <div class="col-md-10" (click)="getZaagListItem(list)">
                                <!-- {{selectedData?.mapping_field}}== {{list.mapping_field| json}} -->
                                <div class="card showing-cursor sawList-background-grey"
                                    [ngClass]="{'acivebordercolor':selectedData?.orderID==list?.orderID,'bggreencolor':isCloseBox && selectedData?.mapping_field ==list?.mapping_field}">
                                    <!-- 'activared-green-color' -->
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-6 ">
                                                <p *ngIf="list.orderID">{{list?.orderID}}</p>
                                                <p *ngIf="list.FGMaterial">{{list?.FGMaterial}}{{list?.aantalFG}}</p>
                                                <p *ngIf="list.Aantal">{{list?.Aantal}}&nbsp; St.
                                                    &nbsp;{{list?.Lengte}}MM
                                                </p>
                                                <p *ngIf="list.materialDescription">{{list?.materialDescription}} </p>

                                            </div>
                                            <div class="col-md-6  text-right">
                                                <P *ngIf="list?.DueDateText">
                                                    {{list?.DueDateText}}</P>
                                                <P class="card-text" *ngIf="list?.NewUnit != '' && list?.NewUnit"
                                                    style="color: red;">
                                                    {{list?.NewUnit | titlecase}}
                                                </P>
                                                <P *ngIf="list?.Sequence != ''" class="card-text"> {{list?.Sequence |
                                                    titlecase}}
                                                </P>
                                                <P *ngIf="list?.Motortype != '' && list?.Motortype" class="card-text">
                                                    Type
                                                    motor:{{list?.Motortype | titlecase}}</P>
                                                <P *ngIf="list?.Bedieningskant != '' && list?.Bedieningskant != null"
                                                    class="card-text">
                                                    {{ "HOME.OPERATINGSIDE" | translate }}: {{list?.Bedieningskant}}
                                                </P>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-2">
                                <div (click)="!isShowc?createOrder('green',greencontent):''"
                                    *ngIf="selectedData?.orderID==list?.orderID && !isvisible"
                                    class="showing-cursor card card-green-color"
                                    [ngClass]="[!(isCloseBox) ?'sawList-background-grey':'activared-green-color']">

                                    <div class="card-body customcardbody">

                                        <div *ngIf="!isCloseBox">
                                            <button class="circle green mx-2 mx-md-12 mx-xl-4 mx-xxl-6"></button>
                                        </div>

                                        <div *ngIf="isCloseBox">
                                            <a href="javascript:void(0)" (click)="deleteLoadList(list)">
                                                <i class="fa fa-check-circle checkactive-button-color"
                                                    aria-hidden="true"></i><br>
                                                <label for="" class="close-button"> {{'HOME.CLOSEBUTTON' |
                                                    translate}}</label>
                                            </a>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="list.isclose">
                            <div class="col-md-10" (click)="getZaagListItem(list)">
                                <div class="card showing-cursor sawList-background-grey"
                                    [ngClass]="{'acivebordercolor':true,'bggreencolor':list.isclose }">
                                    <!-- 'activared-green-color' -->
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-6 ">
                                                <p *ngIf="list.orderID">{{list?.orderID}}</p>
                                                <p *ngIf="list.FGMaterial">{{list?.FGMaterial}}{{list?.aantalFG}}</p>
                                                <p *ngIf="list.Aantal">{{list?.Aantal}}&nbsp; St.
                                                    &nbsp;{{list?.Lengte}}MM
                                                </p>
                                                <p *ngIf="list.materialDescription">{{list?.materialDescription}} </p>

                                            </div>
                                            <div class="col-md-6  text-right">
                                                <P>
                                                    {{list?.DueDateText}}</P>
                                                <P class="card-text" *ngIf="list?.NewUnit != '' && list?.NewUnit"
                                                    style="color: red;">
                                                    {{list?.NewUnit | titlecase}}
                                                </P>
                                                <P class="card-text"> {{list?.Sequence}}
                                                </P>
                                                <P *ngIf="list?.Motortype != '' && list?.Motortype" class="card-text">
                                                    Type
                                                    motor:{{list?.Motortype | titlecase}}</P>
                                                <P *ngIf="list?.Bedieningskant != '' && list?.Bedieningskant != null"
                                                    class="card-text">
                                                    {{ "HOME.OPERATINGSIDE" | translate }}: {{list?.Bedieningskant}}
                                                </P>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="col-md-2">
                                <div (click)="deleteLoadList(list)"
                                    *ngIf="selectedData?.orderID==list?.orderID || isvisible"
                                    class="card card-green-color showing-cursor"
                                    [ngClass]="[ list.isclose?'activared-green-color':'']">

                                    <div class="card-body customcardbody">

                                        <div *ngIf="!list.isclose">
                                            <button (click)="!isShowc?createOrder('green',greencontent):''"
                                                class="circle green mx-2 mx-md-12 mx-xl-4 mx-xxl-6"></button>
                                        </div>

                                        <div *ngIf="list.isclose">
                                            <a href="javascript:void(0)">
                                                <i class="fa fa-check-circle checkactive-button-color"
                                                    aria-hidden="true"></i><br>
                                                <label for="" class="close-button">{{'HOME.CLOSEBUTTON' |
                                                    translate}}</label>
                                            </a>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
            <ng-template #noOrderFound>
                <div>
                    <h2 class="justify-content-center align-items-center "> {{ "HOME.NOORDERS" | translate }}</h2>

                </div>
            </ng-template>

        </div>
        <div class="modal-footer">

            <div class="stoppagebutton">
                <div ngbDropdown placement="top-end" class="d-inline-block">
                    <button type="button" [disabled]="!(zaagList?.ordernumber_list?.ordernummers?.length>0)"
                        class="btn btn-primary  btn-primaryy btn-blocks loadlist-submit stopagebuttons"
                        (click)="isloadSubmit=!isloadSubmit" id="dropdownBasic2" ngbDropdownToggle>
                        {{'HOME.STOPPAGEBUTTON' | translate}} <i
                            [ngClass]="[isloadSubmit?'fa fa-angle-up':'fa fa-angle-down']"></i></button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                        <div class="mt-2 customcardbody orange-h">
                            <button ngbDropdownItem (click)="setNavigate('yellow');isloadSubmit=false"
                                class="circle orange mx-2 mx-md-12 mx-xl-4 mx-xxl-6"></button>
                        </div>
                        <div class="mt-2 customcardbody red-h">
                            <button ngbDropdownItem (click)="setNavigate('red');isloadSubmit=false"
                                class="circle red mx-2 mx-md-12 mx-xl-4 mx-xxl-6"></button>
                        </div>
                        <div class="mt-2 customcardbody blue-h">
                            <button ngbDropdownItem (click)="setNavigate('blue');isloadSubmit=false"
                                class="circle blue mx-2 mx-md-12 mx-xl-4 mx-xxl-6"></button>
                        </div>
                    </div>
                </div>
                <button *ngIf="!this.print_sticker" type="submit"
                    class="btn btn-primary btn-primaryy cancel-buttons btn-blocks"
                    (click)="close();modal.dismiss('Cross click');">
                    {{'HOME.CANCELBUTTON' | translate}}</button>
                <button *ngIf="this.print_sticker" type="button" class="selectorder btn-blocks">
                    {{'HOME.PRINTSTICKERBUTTON' | translate}}</button>

            </div>

            <!-- <div class="row mt-5 modalborder-color">
                <div class="col-md-8 col-sm-6"></div>
                <div class="col-md-2 col-sm-3">
                    <div ngbDropdown placement="top-end" class="d-inline-block">
                        <button type="button" [disabled]="!(zaagList.ordernumber_list?.ordernummers.length>0)"
                            class="btn btn-primary btn-blocks loadlist-submit stopagebuttons"
                            (click)="isloadSubmit=!isloadSubmit" id="dropdownBasic2" ngbDropdownToggle>
                            Stoppage <i [ngClass]="[isloadSubmit?'fa fa-angle-up':'fa fa-angle-down']"></i></button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic2">
                            <div class="mt-2 customcardbody orange-h">
                                <button ngbDropdownItem (click)="setNavigate('yellow');isloadSubmit=false"
                                    class="circle orange mx-2 mx-md-12 mx-xl-4 mx-xxl-6"></button>
                            </div>
                            <div class="mt-2 customcardbody red-h">
                                <button ngbDropdownItem (click)="setNavigate('red');isloadSubmit=false"
                                    class="circle red mx-2 mx-md-12 mx-xl-4 mx-xxl-6"></button>
                            </div>
                            <div class="mt-2 customcardbody blue-h">
                                <button ngbDropdownItem (click)="setNavigate('blue');isloadSubmit=false"
                                    class="circle blue mx-2 mx-md-12 mx-xl-4 mx-xxl-6"></button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-2 col-sm-3">
                    <button *ngIf="!this.print_sticker" type="submit" class="btn btn-primary cancel-buttons btn-blocks"
                        (click)="close();modal.dismiss('Cross click');">
                        Cancel</button>
                    <button *ngIf="this.print_sticker" type="button" class="selectorder btn-blocks">
                        Print sticker</button>
                </div>
            </div> -->

        </div>
    </div>
</ng-template>

<ng-template #greencontent let-modal>
    <div class="modal-header">
        <h2 class="modal-title" id="modal-basic-title">
            {{ "HOME.ACTIVITYTITTLE" | translate }}
        </h2>
        <button type="button" class="btn-close" aria-label="Close"
            (click)="modal.dismiss('Cross click');clearGreenData()"></button>
    </div>
    <div class="modal-body">
        <p *ngIf="role == 'office'">
            {{ "HOME.ACTIVITYCONTENT_OFFICE" | translate }}
        </p>
        <p *ngIf="role == 'office'">
            {{ "HOME.ACTIVITY_QUES_OFFICE" | translate }} {{ green_eventdata?.orderID }}
            {{ "HOME.ON_WORKSTATION" | translate }} {{ name }} doen?
        </p>
        <p *ngIf="role != 'office'">
            {{ "HOME.ACTIVITYCONTENT" | translate }}
        </p>
        <p *ngIf="role != 'office'">
            {{ "HOME.ACTIVITY_QUES" | translate }} {{ green_eventdata?.orderID }}
            {{ "HOME.ON_WORKSTATION" | translate }} {{ name }} ?
        </p>
        <form [formGroup]="activityForm" novalidate>
            <div class="form-check">
                <input class="form-check-input" type="radio" name="event" formControlName="event" value="close"
                    id="flexRadioDefault1" />
                <label class="form-check-label" for="flexRadioDefault1">
                    {{ "HOME.CLOSEBUTTON" | translate }}
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="radio" name="event" formControlName="event" value="yellow"
                    id="flexRadioDefault2" />
                <label class="form-check-label" for="flexRadioDefault2">
                    {{ "HOME.PUT_YELLOW" | translate }}
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="radio" name="event" formControlName="event" value="red"
                    id="flexRadioDefault3" />
                <label class="form-check-label" for="flexRadioDefault3">
                    {{ "HOME.PUT_RED" | translate }}
                </label>
            </div>
        </form>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-primaryy" (click)="onSubmit_event()">
            {{ "HOME.CONFIRMBUTTON" | translate }}
        </button>
        <button type="button" class="btn btn-success reset" (click)="modal.close('Save click');clearGreenData()">
            {{ "HOME.CANCELBUTTON" | translate }}
        </button>
    </div>
</ng-template>
