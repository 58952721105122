import { Component, ElementRef, EventEmitter, OnInit, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DashboardComponent } from 'src/usermodule/dashboard/dashboard.component';
import { ApiService } from 'src/services/apiservice.service';
import { ToastrService } from 'ngx-toastr';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LandingPageComponent } from '../landing-page/landing-page.component';
import Inputmask from "inputmask";
import { DataService } from 'src/services/dataservice.service';
import { StockBuffer } from "../../models/stockBuffer";
import { categorie, categories } from '../../models/categories';
import { Openorders } from 'src/models/order';
import { ordersResponse } from 'src/models/orders';
import { SelectedData, Zaaglist } from 'src/models/interface';
declare var $: any;
import { DeviceDetectorService } from 'ngx-device-detector';
@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProjectListComponent implements OnInit {
  @ViewChild('content') private content: TemplateRef<Object>;
  @ViewChild('number_input') searchElement: ElementRef;
  @Output() blurValue: EventEmitter<boolean> = new EventEmitter<boolean>();
  // project_Form: any;
  SearchText: string;
  submitted = false;
  form: UntypedFormGroup;
  orderlist: Openorders[];
  zaagList: any;
  isloadSubmit: boolean;
  isGreenColor: boolean;
  isCloseBox = false;
  order_to_pass: any;
  workstationorders: ordersResponse[];
  src: string;
  closeResult: string;
  users: any = [];
  workstationid: any;
  current_user: any;
  role: string | null;
  input: any;
  input_item: any;
  input_mask: any;
  input_desc: string | null;
  HideSelectButton: boolean;
  customerID: string | null;
  subscription: any;
  customizing: any;
  productList: any;
  selectedProduct: any;
  sumOfQty: any;
  activityForm: any;
  addedQty: string;
  isSumOfQty: boolean;
  productForm: UntypedFormGroup;
  stockBufferList: StockBuffer[] = [];
  is_blurred: Boolean;
  selectedData: any;
  public zaaglistdata: Zaaglist | undefined;
  eventId: any;
  isShowc = false
  workstationList = [{ workstaionID: 38, box1: 1, box2: 2 }, { workstaionID: 40, box1: 7, box2: 8 }, { workstaionID: 41, box1: 3, box2: 4 }, { workstaionID: 43, box1: 8, box2: null }, { workstaionID: 44, box1: 5, box2: 6 }, { workstaionID: 45, box1: 3, box2: 4 }, { workstaionID: 46, box1: 5, box2: 6 }]
  downtime_flag: boolean = false;
  unplanned_flag: boolean = false;
  screen_status: boolean = false;
  categories: categorie[];
  isShow = false;
  allow_greensubcat: boolean = false;
  redcategories: categorie[]
  bluecategories: categorie[];
  report_blue: categorie[];
  reports_flag: boolean = false;
  green_categories: any;
  workinghours_flag: boolean = false;
  active_order: any;
  name: string | null;
  green_eventdata: any;
  print_sticker: boolean = false;
  deviceInfo: import('ngx-device-detector').DeviceInfo;
  isKeyboard: boolean = false;
  public isvisible = false;
  currentlang: string | null;
  projectExtentionValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]
  constructor(private deviceService: DeviceDetectorService, private DataService: DataService, private LandingPageComponent: LandingPageComponent, private modalService: NgbModal, private toastrService: ToastrService, private apiservice: ApiService, public DashboardComponent: DashboardComponent, private router: Router, public fb: UntypedFormBuilder) {
    this.role = localStorage.getItem("role");
    this.users = localStorage.getItem("users");
    this.current_user = localStorage.getItem("current_user");
    (this.current_user) ? this.current_user = JSON.parse(this.current_user) : this.current_user = undefined;
    (this.users) ? this.users = JSON.parse(this.users) : this.users = undefined;
    this.workstationid = localStorage.getItem("workstationID");
    this.customerID = localStorage.getItem('customerID');
    this.customizing = localStorage.getItem("customizing");
    this.currentlang = localStorage.getItem('language');
    (this.customizing) ? this.customizing = JSON.parse(this.customizing) : '';
    this.getorders();
    this.getProductsByCustomerId();
    this.form = new UntypedFormGroup({
      order: new UntypedFormControl('', Validators.required),
      projectExtention: new UntypedFormControl('')
    });
    this.productForm = new UntypedFormGroup({
      qty: new UntypedFormControl('', Validators.required),
    });
    this.selectedProduct = undefined;
    this.isSumOfQty = false;
    this.name = localStorage.getItem('smartbox_name');
    this.addedQty = '';
    this.input_mask = localStorage.getItem("input_mask");
    this.input_desc = localStorage.getItem('mask_descp');
    this.activityForm = this.fb.group({
      event: ['', [Validators.required]],
    });
  }
  ngOnInit(): void {
    this.screen_status = true;
    this.workstationList = this.workstationList.filter((e) => e.workstaionID == this.workstationid);
    this.DataService.currentMessage.subscribe((item: string) => {
      item === 'en'
        ? (this.currentlang = 'English')
        : item === 'du' ? (this.currentlang = 'Dutch') : (this.currentlang = 'Spanish');
    });
    this.deviceInfo = this.deviceService.getDeviceInfo();
    if (this.deviceInfo.deviceType != 'mobile') {
      this.isKeyboard = true
    };
    $(document).ready(function (ex: any) {
      $('.inputField').on("touchstart", function (event: any) {
        event.preventDefault();
        $('#testField').focus();
      });
    });
    setTimeout(() => {
      this.searchElement.nativeElement.focus();
      if (this.input_mask != null && this.input_mask != undefined) {
        this.input = new Inputmask(this.input_mask).mask(this.searchElement.nativeElement);
      }
    }, 200);
    let flag = localStorage.getItem("openorderpopup");
    setTimeout(() => {
      (flag == "true") ? this.open(this.content) : '';
      localStorage.removeItem("openorderpopup");
    }, 300);
    this.geterp('');
    this.getCategories(this.customerID);
  }
  getProduct(product: any) {
    if (this.selectedProduct !== undefined && product.ProductID != this.selectedProduct.ProductID) {
      this.productForm.reset()
      this.isSumOfQty = false
      this.addedQty = ''
    }
    let temp = (window.event as any).srcElement.className;
    let inputValue = !isNaN(parseInt(this.productForm.value.qty)) ? parseInt(this.productForm.value.qty) : 0;
    if (temp != 'btn btn-primary') {
      this.selectedProduct = product;
      this.sumOfQty = parseInt(product.inventory) + inputValue;
    }
  }
  sum() {
    if (this.productForm.value.qty.trim() == '' || this.productForm.value.qty == '-') {
      this.sumOfQty = this.selectedProduct.inventory;
    } else {
      this.sumOfQty = parseInt(this.selectedProduct.inventory) + parseInt(this.productForm.value.qty);
    }
  }
  sumStockBuffer(stockBuffer: StockBuffer) {
    if (this.productForm.value.qty == null || this.productForm.value.qty.trim() == '' || this.productForm.value.qty == '-') {
      this.sumOfQty = this.selectedProduct.inventory;
      this.stockBufferList[stockBuffer.index].sumOfQty = this.selectedProduct.inventory;
    } else {
      this.sumOfQty = parseInt(this.selectedProduct.inventory) + parseInt(this.productForm.value.qty);
      this.stockBufferList[stockBuffer.index].sumOfQty = this.sumOfQty
      this.isSumOfQty = true
    }
  }
  updateQuantity() {
    if (this.sumOfQty != '') {
      let payload = {
        "userID": this.current_user.userID,
        "productID": this.selectedProduct.ProductID,
        "field": "inventory",
        "value": this.sumOfQty
      };
      this.apiservice.updateERProductsQTY(payload).subscribe((res: any) => {
        this.getProductsByCustomerId();
        this.clearSelectorder();
      });
    }
  }
  updateQuantityDatabase(stockBuffer: StockBuffer) {
    this.sumStockBuffer(stockBuffer)
    if (this.sumOfQty != '') {
      let payload = {
        "userID": this.current_user.userID,
        "productID": stockBuffer.ProductID,
        "field": "inventory",
        "value": stockBuffer.sumOfQty
      };
      this.apiservice.updateERProductsQTY(payload).subscribe((res: any) => {
        this.getProductsByCustomerId();
        this.clearSelectorder();
      });
    }
  }
  getinput() {
    if (this.input_mask != null && this.input_mask != undefined) {
      this.input_item = this.input.isValid();
    }
  }
  async getorders() {
    let count = 0;
    if (!this.users) {
      this.orderlist = [];
    } else {
      for (let k = 0; k <= this.users.length - 1; k++) {
        this.apiservice.getorder(this.users[k].userID, this.workstationid).subscribe(
          async (res: any) => {
            if (res) {
              if (res.length == 0) {
                count = count + 1;
              } else {
                this.workstationorders?.push(...res);
                count = count + 1;
              }
            } else {
              this.toastrService.error('Something went wrong!', 'Error!');
            }
          }, (error: any) => {
            this.toastrService.error(`Something went wrong! ${error}`, 'Error!');
          });
      }
    }
  }
  async getProductsByCustomerId() {
    const payload = { customer: 0 };
    let response = await this.apiservice.getERPProductsByCustomerId(payload).toPromise();
    this.productList = (response as any)?.result;
    this.stockBufferList = await this.initStockLevelUpdateList()
  }
  async initStockLevelUpdateList() {
    let stockList: StockBuffer[] = []
    let index = 0
    if (this.productList) {
      for (const product of this.productList) {
        let stock: StockBuffer = {
          index: index,
          ProductID: product.ProductID,
          Productname: product.Productname,
          Description: product.Description,
          inventory: product.inventory,
          sumOfQty: product.inventory,
          isSave: false
        }
        index += 1
        stockList.push(stock)
      }
    }
    return stockList
  }
  public getzaaglist(item: any) {
    this.zaaglistdata = item;
  }
  public getZaagListItem(item: any) {
    this.isCloseBox = false;
    this.selectedData = item;
    let v = this.zaagList?.ordernumber_list?.ordernummers.filter((v: any) => v.isclose == true)
    if (v.length > 0) {
      this.isvisible = true
    }
    // let orders:any=JSON.parse(localStorage.getItem('orders') || '[]')
    // orders.forEach((e:any)=>{
    //   if((e.orderID === this.selectedData.orderID) && e.eventType ==='green'){
    //   console.log('sgh')
    //     this.isCloseBox=true;
    //   }
    //   else{
    //     this.isCloseBox=false;
    //   }
    // })
    // console.log(this.isCloseBox,'isCloseBox')
  }
  geterp(item: any) {
    this.apiservice.geterp().subscribe(
      (res: any) => {
        if (res.response != false) {
          this.orderlist = res.response.ordernumber_list.ordernummers;
          this.zaagList = res.response;
          // this.zaaglistdata=res.response.ordernumber_list.zaaglijsten[0];
          let orders = JSON.parse(localStorage.getItem('orders') || '[]');
          let greenOrder = false
          res.response.ordernumber_list.ordernummers.forEach((v: any) => {
            if (orders.length > 0) {
              orders.forEach((o: any) => {
                if (o.orderID == v.orderID) {
                  if (o.eventType == 'green') {
                    greenOrder = true
                    v.isclose = true;
                    this.isvisible = true;
                    this.selectedData = v;
                  }
                  else if (o.eventType != 'green') {
                    this.selectedData = res.response.ordernumber_list.ordernummers[0];
                  }
                  else {
                    this.isvisible = false;
                  }
                }
                else {
                  if (!greenOrder) {
                    this.selectedData = res.response.ordernumber_list.ordernummers[0];
                  }
                  this.isvisible = false
                  // this.selectedData = res.response.ordernumber_list.ordernummers[0];
                }
              })
            }
            else {
              this.selectedData = res.response.ordernumber_list.ordernummers[0];
            }
          })
          if (this.orderlist?.length > 0) {
            this.HideSelectButton = false;
            if (this.workstationorders?.length > 0) {
              for (let j = 0; j <= this.orderlist.length - 1; j++) {
                this.orderlist[j].isExist = false;
                this.orderlist[j].username = '';
                for (let k = 0; k <= this.workstationorders.length - 1; k++) {
                  if (this.workstationorders[k].orderID == this.orderlist[j].orderID) {
                    for (let m = 0; m <= this.users.length - 1; m++) {
                      if (this.users[m].userID == this.workstationorders[k].userID) {
                        if (this.orderlist[j].username == '' && this.current_user.displayName != this.users[m].displayName) {
                          this.orderlist[j].username = this.users[m].displayName;
                          this.orderlist[j].isExist = true;
                          break;
                        } else if (this.current_user.displayName != this.users[m].displayName) {
                          let name = this.orderlist[j].username;
                          this.orderlist[j].username = name + ',' + this.users[m].displayName;
                          this.orderlist[j].isExist = true;
                          break;
                        } else {
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          else if (this.zaagList?.ordernumber_list?.zaaglijsten?.length > 0) {
            this.HideSelectButton = false;
          }
          else {
            this.HideSelectButton = true;
          }
          const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });
          this.orderlist.sort((a: any, b: any) => {
            return collator.compare(a.sortfield, b.sortfield);
          });
        } else {
          this.toastrService.error('No ERP orders available', 'Error!');
          this.orderlist = [];
        }
      }, (error: any) => {
        this.toastrService.error('Something went wrong!', 'Error!');
      })

  }

  navigate() {
    if (this.role == 'office') {
      if (this.LandingPageComponent.orders.length > 1) {
        let k = this.LandingPageComponent.orders.filter((item: any) => {
          return (item.orderID != 's' && item.orderID != '');
        });
        this.LandingPageComponent.orders = k;
        (this.LandingPageComponent.orders.length > 0) ? this.LandingPageComponent.lastorder_focus() : '';
        this.LandingPageComponent.set_flag('screen', '');
      }
    } else {
      if (this.DashboardComponent.orders.length > 1) {
        let k = this.DashboardComponent.orders.filter((item: any) => {
          return (item.orderID != 's' && item.orderID != '');
        });
        this.DashboardComponent.orders = k;
        (this.DashboardComponent.orders.length > 0) ? this.DashboardComponent.lastOrderFocus() : '';
        this.DashboardComponent.set_flag('screen', '');
      }
    }
  }
  orders() {
    if (this.input_mask == null || this.input_mask == undefined) {
      this.input_item = true;
    }
    if (this.form.valid && this.input_item) {
      this.form.value.order = this.customerID === '3' && this.form.value.projectExtention != '' ? this.form.value.order + '-' + this.form.value.projectExtention : this.form.value.order
      let order = this.form.value.order;
      let payload = {
        projectnumber: order
      }
      if (this.customerID === '3') {
        this.apiservice.validateprojectnumber(payload).subscribe(res => {
          if (res?.response === 'success') {
            (this.role === 'shopfloor') ? this.DashboardComponent.addOrder(order, true, '', '') : this.LandingPageComponent.add_order(order, '')
          }
          if (res?.response === 'error') {
            let errormsg;
            if (this.currentlang === 'Dutch') {
              errormsg = 'Ongeldig projectnummer'
            }
            else if (this.currentlang === 'English') {
              errormsg = 'Invalid project number'
            }
            else if (this.currentlang === 'Spanish') {
              errormsg = 'número de proyecto no válido'
            } else { }

            this.toastrService.error(errormsg, 'Error!');
          }

        },
          (error) => console.log(error)
        )
      } else {
        (this.role === 'shopfloor') ? this.DashboardComponent.addOrder(order, true, '', '') : this.LandingPageComponent.add_order(order, '')
      }
    }
  }
  async NavigateTo(item: string) {
    if (item != '1') {
      await this.DataService.add(this.order_to_pass);
    }
    this.modalService.dismissAll();
    this.DashboardComponent.status_screen = false;
    this.DashboardComponent.OrderIntakeScreen = true;
  }
  sendorder(order: any) {
    this.order_to_pass = order;
  }
  clearSelectorder() {
    this.productForm.reset({});
    this.selectedProduct = undefined;
  }
  openSawListPopup(content: any) {
    if (this.role == 'shopfloor') {
      this.is_blurred = true
      this.blurValue.emit(true)
    }
    this.modalService.open(content, { size: 'mysize', ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.is_blurred = false
      this.blurValue.emit(false);
      this.zaaglistdata = undefined;
      this.DashboardComponent.is_blurred = false
      this.selectedData = undefined;
      if (this.getDismissReason(reason) != 'with: Load list') {
        this.isGreenColor = false;
        this.isloadSubmit = false
        this.isCloseBox = false;
        this.DashboardComponent.is_blurred = false
      }
    });
    // const modal: any = document.querySelector("ngb-modal-window");
    // modal.style.overflow = "hidden";
  }
  close() {
    this.is_blurred = false
    this.blurValue.emit(false);
    this.DashboardComponent.is_blurred = false
  }
  open(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    const modal: any = document.querySelector("ngb-modal-window");
    modal.style.overflow = "hidden";
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  onSubmit() {
    if (this.role === 'shopfloor') {
      this.DashboardComponent.addOrder(this.order_to_pass.orderID, true, '', this.order_to_pass.mapping_field);
      this.DashboardComponent.erpattachment(this.order_to_pass);
    } else {
      this.LandingPageComponent.add_order(this.order_to_pass.orderID, this.order_to_pass.mapping_field);
      this.LandingPageComponent.erpattachment(this.order_to_pass);
    }
  }
  public removeZaaglijst() {
    try {
      if (this.zaaglistdata) {
        let payload = {
          workstationID: +this.workstationid,
          zaaglijstID: +this.zaaglistdata?.id
        }
        this.apiservice.deleteZaaglijst(payload).subscribe(res => {
          // this.toastrService.success('Successfully deleted')
          this.geterp('')
        },
          (error: any) => {
            this.toastrService.error('Something went wrong!');
          })
      }
    }
    catch (error) {
      console.log(error)
    }
  }
  getselectOrder(content: any) {
    this.isShow = true
    this.geterp('');
    // console.log(this.zaagList.ordernumber_list?.ordernummers.length)
    this.openSawListPopup(content);
    this.isShow = false
  }
  public getLoadList(content: any) {
    this.isShow = true;
    this.selectedData = undefined;
    try {
      if (this.zaaglistdata) {
        let payload = {
          workstationID: +this.workstationid,
          zaaglijstID: +this.zaaglistdata.id,
          loadOrders: true
        }
        this.apiservice.loadZaaglijst(payload).subscribe(res => {
          this.zaagList = ''
          this.geterp('');
          this.openSawListPopup(content);
          this.isShow = false;
        },
          (error) => {
            console.log(error)
          }
        )
      }
    }
    catch (error) {
      console.log(error)
    }
  }
  public async deleteLoadList(item: any) {
    this.selectedData = item;
    // let active_order = JSON.parse(localStorage.getItem('active_order')|| '{}');
    let orderid: any;
    let orders = JSON.parse(localStorage.getItem('orders') || '[]');
    orders.forEach((v: any) => {
      if (v.eventType == 'green') {
        orderid = v;
        orderid['isDelete'] = true
      }
    })
    orderid.mapping_field = this.selectedData?.mapping_field
    this.DashboardComponent.stop(orderid, 'green', true);
    this.isCloseBox = false;
    this.isvisible = false
    this.print_sticker = false;
    // this.is_blurred=false
    // this.blurValue.emit(false);
    // this.DashboardComponent.is_blurred=false
    this.zaagList.ordernumber_list?.ordernummers.forEach((v: any) => {
      if (v.isclose) {
        v.isclose = false
      }
    })
    // this.geterp('');
    let index = this.zaagList.ordernumber_list?.ordernummers.findIndex((v: any) => v.orderID == this.selectedData?.orderID)
    this.zaagList.ordernumber_list?.ordernummers.splice(index, 1)
    this.selectedData = this.zaagList.ordernumber_list?.ordernummers[0]
  }
  //     public async deleteLoadList(item:string){
  //       try{
  //         if(item){
  //           let payload={
  //             eventID:this.eventId,
  //             orderkey:'',
  //             deleteFromERP:true,
  //             userID:this.current_user.userID,
  //             workstationID:this.workstationid,
  //         }
  //         let res:any=await this.apiservice.stopevent(payload).toPromise()
  //         if (res) {
  //           if (res.response == 'success') {
  //             this.isCloseBox=false;
  //             this.geterp();
  //           }
  //         }
  //       }
  //   }
  //   catch(error){
  // console.log(error)
  //   }
  // }
  //create dropdown()
  clearGreenData() {
    localStorage.removeItem('green_eventdata');
    this.green_eventdata = undefined;
  }
  async onSubmit_event() {
    if (this.activityForm.value.event == 'close') {
      let active_order: any = JSON.parse(localStorage.getItem('active_order') || '{}');
      await this.DashboardComponent.stop(active_order, 'close', false);
      await this.DashboardComponent.stop(this.green_eventdata, 'green', true);
      this.green_eventdata = undefined;
      this.createOrder('green', '');
      this.modalService.dismissAll();
    }
    else {
      this.setNavigate(this.activityForm.value.event);
    }
    this.activityForm.reset({});
  }
  public async setNavigate(actiontypeFlag: string) {
    this.selectedData['zaaglijstId'] = this.selectedData.orderID
    this.selectedData['orderID'] = this.selectedData.zaaglijstNo;
    let active_order: any = JSON.parse(localStorage.getItem('active_order') || '{}')
    active_order.orderID = this.selectedData?.orderID,
      active_order.zaaglijstID = this.selectedData?.zaaglijstID
    localStorage.setItem(
      'active_order',
      JSON.stringify(active_order)
    );
    let orders: any = JSON.parse(localStorage.getItem('orders') || '[]');
    let green_eventdata;
    orders.forEach((v: any) => {
      if (v.orderID == this.selectedData.orderID && (v.eventType == 'yellow' || v.eventType == 'red')) {
        if (this.DashboardComponent.orders.length == 1) {
          this.DashboardComponent.stop(v, v.eventType, false);
        }
        else {
          let index = this.DashboardComponent.orders.findIndex((v: any) => v.eventType != '' && v.eventType != 'green')
          this.DashboardComponent.orders.splice(index, 1)
          this.DashboardComponent.stop(v, v.eventType, false);
        }
        // green_eventdata = v
      }
      else if (v.orderID == this.selectedData.orderID && v.eventType == 'green') {
        if (this.DashboardComponent.orders.length == 1) {
          this.DashboardComponent.stop(v, v.eventType, false);
        }
        else {
          let index = this.DashboardComponent.orders.findIndex((v: any) => v.eventType != '' && v.eventType != 'green')
          this.DashboardComponent.orders.splice(index, 1)
          this.DashboardComponent.stop(v, v.eventType, false);
          // green_eventdata=v
        }
        // green_eventdata = v
      }
      else if (v.orderID == this.selectedData.orderID && v.eventType != 'green') {
        green_eventdata = v
      }
    })
    if (green_eventdata) {
      // this.toastrService.error('Order already exist', 'error');
      this.selectedData['orderID'] = this.selectedData.zaaglijstId;
      this.selectedData['zaaglijstId'] = this.selectedData.zaaglijstNo
    }
    else {
      if (actiontypeFlag == 'yellow') {
        this.downtime_flag = true;
        this.screen_status = false;
        this.reports_flag = false;
        this.unplanned_flag = false;
        this.workinghours_flag = false;
        this.modalService.dismissAll();
        if (green_eventdata == undefined) {
          await this.DashboardComponent.addOrder(this.selectedData.orderID, false, this.selectedData.zaaglijstId, this.selectedData.mapping_field);
        }
      }
      else if (actiontypeFlag == 'red') {
        this.unplanned_flag = true;
        this.screen_status = false;
        this.reports_flag = false;
        this.downtime_flag = false;
        this.workinghours_flag = false;
        this.modalService.dismissAll();
        if (green_eventdata == undefined) {
          await this.DashboardComponent.addOrder(this.selectedData.orderID, false, this.selectedData.zaaglijstId, this.selectedData.mapping_field);
        }
      }
      else if (actiontypeFlag == 'blue') {
        this.reports_flag = true;
        this.unplanned_flag = false;
        this.screen_status = false;
        this.downtime_flag = false;
        this.workinghours_flag = false;
        this.modalService.dismissAll();
        if (green_eventdata == undefined) {
          await this.DashboardComponent.addOrder(this.selectedData.orderID, false, this.selectedData.zaaglijstId, this.selectedData.mapping_field);
        }
      }
    }
  }
  public createOrder(actiontypeFlag: string, content: any) {
    console.log(this.selectedData, 'sel')
    this.isShowc = true
    let orders: any = JSON.parse(localStorage.getItem('orders') || '[]');
    let activateOrder = false;
    orders.forEach((v: any) => {
      if (v.orderID == this.selectedData?.zaaglijstNo || v.eventType == 'green') {
        let index = this.DashboardComponent.orders.findIndex((v: any) => v.eventType != '')
        this.DashboardComponent.orders.splice(index, 1)
        this.DashboardComponent.stop(v, v.eventType, false);
      }
    })
    if (activateOrder) {
      this.toastrService.error('Order already exist', 'error');
    }
    else {
      // let active_order:any=JSON.parse(localStorage.getItem('active_order')|| '{}')
      // active_order.orderID=this.selectedData.orderID,
      // active_order.zaaglijstID=this.selectedData.zaaglijstID,
      //   localStorage.setItem(
      //     'active_order',
      //     JSON.stringify(active_order)
      //   );
      var unixtimestamp = Math.floor(new Date().getTime() / 1000);
      let payload = {
        orderNr: this.selectedData?.orderID,
        customerRef: this.selectedData.zaaglijstNo,
        orderkey: this.selectedData?.mapping_field,
        userID: this.current_user.userID,
        actionType: actiontypeFlag,
        actionDetail: "",
        workstationID: this.workstationid,
        StartTime: unixtimestamp
      }
      this.apiservice.addorder(payload).subscribe((res: any) => {
        this.print_sticker = true;
        this.isShowc = false
        res['mapping_field'] = this.selectedData.mapping_field
        this.DashboardComponent.getOrders(this.current_user.userID, this.workstationid, this.selectedData, false, res)
        this.eventId = res.eventID;
        this.isCloseBox = true;
        if (actiontypeFlag == 'green') {
          if (this.allow_greensubcat) {
            this.reports_flag = false;
            this.unplanned_flag = false;
            this.screen_status = false;
            this.downtime_flag = false;
            this.workinghours_flag = true;
            this.modalService.dismissAll();
          }
          else {
            this.workinghours_flag = false;
          }
        }
        else {
        }
        this.geterp(this.selectedData);
      },
        (error: any) => {
          console.log(error)
        }
      )
    }
  }
  getCategories(userid: any) {
    this.apiservice.getcategories(userid).subscribe(
      (res: categories) => {
        if (res) {
          this.categories = res.yellow;
          this.redcategories = res.red;
          this.bluecategories = res.blue;
          this.report_blue = res.level2.blue;
          let greenCategories: any = res.green;
          this.green_categories = greenCategories?.filter((item: any) => {
            return (item.hidden != '1')
          });
          localStorage.setItem('categories', JSON.stringify(this.categories));
          localStorage.setItem(
            'redcategories',
            JSON.stringify(this.redcategories)
          );
          localStorage.setItem(
            'bluecategories',
            JSON.stringify(this.bluecategories)
          );
          (this.report_blue?.length > 0 && this.report_blue != undefined) ? localStorage.setItem('reportblue', JSON.stringify(this.report_blue)) : '';
          if (this.green_categories?.length > 0) {
            this.allow_greensubcat = true;
            localStorage.setItem(
              'greencategories',
              JSON.stringify(this.green_categories)
            );
          } else {
            this.allow_greensubcat = false;
          }
        } else {
          localStorage.setItem('categories', JSON.stringify(this.categories));
          localStorage.setItem(
            'redcategories',
            JSON.stringify(this.redcategories)
          );
          localStorage.setItem(
            'bluecategories',
            JSON.stringify(this.bluecategories)
          );
          this.toastrService.error('No categories!', 'Error!');
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }
  suncircleKardex(boxNumber: any, list: any) {
    this.zaaglistdata = list;
    try {
      if (this.zaaglistdata) {
        let payload = {
          zaaglijstNo: this.zaaglistdata.zaaglijstNo,
          workstationID: +this.workstationid,
          kardexLocation: boxNumber
        }
        this.apiservice.suncircleKardex(payload).subscribe((res: any) => {
          this.geterp('')
          //  this.zaagList?.ordernumber_list?.zaaglijsten.forEach((v:any)=>{
          //   if(res.response){
          //     if(v.zaaglijstNo == this.zaaglistdata.zaaglijstNo && res.response.kardexLocation){
          //       v['kardexLocation1']=res.response.kardexLocation
          //     }
          //     else{
          //       v['kardexLocation1']=''
          //     }
          //   }
          //   else{
          //     v['kardexLocation1']=''
          //   }
          //   if(res.response2){
          //     if(v.zaaglijstNo == res.response2.zaaglijstNo && res.response2.kardexLocation){
          //       v['kardexLocation2']=res.response2.kardexLocation
          //     }
          //     else{
          //       v['kardexLocation2']=''
          //     }
          //   }
          //   else{
          //     v['kardexLocation2']=''
          //   }
          // })
        },
          (error: any) => console.log(error)
        )
      }
    }
    catch (error) {
      console.log(error)
    }
  }
}